import React, { useState, FC, useEffect } from "react";
import moment from "moment";
import { DateRange } from "materialui-daterange-picker";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import history from "history/browser";

import { PageType } from "types/PageType";
// import DateRangePicker from "components/Common/DateRange";
import Select from "components/Common/Select";
import { useStyles } from "./styles";
import CustomLoader from "components/CustomLoader";
import SearchIcon from "@material-ui/icons/Search";
import useCollectionSubscriber from "../../hooks/useCollectionSubscriber";
import { firestore } from "../../firebase-tools/firebase";
import { IPost } from "interfaces/IPost";

import { useUser } from "UserContext";
import { IUser } from "interfaces/IUser";

import useDocumentSubscriber from "hooks/useDocumentSubscriber";
import { postsFilter } from "utils/postsFilter";
import { Toast } from "components/styled/Common";
import Filter from "components/BePlanned/Filter";
import { urlDates } from "utils/dateFormat";
import PublicLink from "components/Common/PublicLink";
import CreateEditPostV2 from "components/BePlanned/CreateEditPostV2";
import BePlannedFeedV2 from "components/styled/BePlannedFeedV2";
import { Button, Checkbox, Grid } from "@material-ui/core";
import DateRangePicker from "components/Common/DateRangePicker";
import { ReactComponent as CheckedIcon } from "static/media/checkedicon2.svg";
import { ReactComponent as UncheckedIcon } from "static/media/uncheckicon2.svg";

import { ClientListV2 } from "components/BeMeasured/ClientListV2";
import Pagination from "./Pagination";
import SearchList from "./SearchList";
import { DeletePostList } from "components/BePlanned/DeletePostList";
import { makeStyles } from "@material-ui/core/styles";
import cuid from "cuid";
import SortButton from "./SortButton";
import PrintMode from "./Printer/PrintMode";
import authService from "helpers/user/auth.service";

const channelOptions = [
  "Instagram",
  "Facebook",
  "Twitter",
  "TikTok",
  "YouTube",
  "SnapChat",
  "Threads",
  "Discord",
  "Others",
  "All",
];

const useStyles2 = makeStyles((theme) => ({
  visibleGrid: {
    display: "flex",
  },
  hiddenGrid: {
    display: "none",
  },
}));

export const BePlannedPageV2: FC<PageType> = ({ location }) => {
  const classes = useStyles({});
  const classes2 = useStyles2();
  const [dateRange, setDateRange] = React.useState<DateRange>({
    startDate: moment().startOf("day").toDate(),
    endDate: moment().add(6, "days").startOf("day").toDate(),
  });
  const [authUser] = useUser() as Array<IUser>;
  const [searchTerm, setSearchTerm] = useState("");

  const inputStyle = {
    display: "flex",
    width: "100%",
    padding: "4px 19.5px 4px 21.5px",
    justifyContent: "space-between",
    alignItems: "center",
    borderRadius: "15px",
    border: "1px solid #E8E8E8",
    background: "#FFF",
    outline: "none", // Remove outline on focus
  };

  const [, setClient]: any = useState("");
  const [loading, setLoading] = useState(false);
  const [channels, setChannels]: any = useState([]);
  const [monthlyCategory, setMonthlyCategory] = useState([]);
  const [weeklyCategory, setWeeklyCategory] = useState([]);
  const [paramsChange, setParamsChange]: any = useState("");

  const from = new Date(dateRange.startDate as Date);
  let toDate = new Date(dateRange.endDate as Date);
  const to = moment(toDate).add(1, "days").toDate();

  let userRef = firestore.collection("users").doc(authUser.id);
  let client =
    useDocumentSubscriber({
      ref: userRef,
    }).document?.lastClientSelection || "";

  let postsQueries: any = [];
  if (client !== "") {
    postsQueries = [
      firestore
        .collection("multichannel-posts")
        .where("user_email", "==", client.email)
        .where("date", ">=", from)
        .where("date", "<=", to)
        .orderBy("date", "desc"),
    ];
  } else {
    postsQueries = [
      firestore
        .collection("multichannel-posts")
        .where("date", ">=", from)
        .where("date", "<=", to)
        .orderBy("date", "desc"),
    ];
  }

  let posts;
  let visiblePosts;
  posts = useCollectionSubscriber<IPost>({
    queries: postsQueries,
    deps: [dateRange, client],
  });

  // Page size options and default page size
  const pageSizeOptions = [3, 5, 10, 20];
  const [defaultPageSize, setDefaultPageSize] = useState(5);

  const handleSearchChange = (event) => {
    const newSearchTerm = event.target.value;
    setSearchTerm(newSearchTerm);
  };

  const [currentPage, setCurrentPage] = useState(1);
  let totalPages = Math.ceil(posts.documents.length / defaultPageSize);
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const [sortDirection, setSortDirection] = useState("desc");
  const handleSortToggle = () => {
    setSortDirection((prevDirection) =>
      prevDirection === "desc" ? "asc" : "desc"
    );
    // Now 'visiblePosts' contains the sorted documents array
  };

  const extractedUids: any[] = [];

  const startIndex = (currentPage - 1) * defaultPageSize;
  const endIndex = startIndex + defaultPageSize;
  visiblePosts = {
    ...posts,
    documents: posts.documents.slice(startIndex, endIndex).map((val) => {
      let uuid = cuid();
      extractedUids.push(uuid);
      return {
        ...val,
        uuid,
      };
    }),
  };
  const [postIds, setPostIds] = useState([]);
  const postData = posts.documents.slice(startIndex, endIndex);
  const [formData, setFormData] = useState({
    subscribe: false,
  });
  const [uncheckAll, setUncheckAll] = useState(false);
  const [checkAll, setcheckAll] = useState(false);

  const handleCheck = (e) => {
    const { checked } = e.target;
    let currentIds: any[] = [];

    // Check if the postId is already in postIds array

    // If checked, add postId to the array, otherwise remove it
    if (checked) {
      for (let item of postData) {
        currentIds.push(item.id);
      }
    } else {
      setUncheckAll(true);
    }
    setcheckAll(checked);
    setPostIds(currentIds as any);
    setFormData({
      ...formData,
      subscribe: checked,
    });
  };

  // const [images, setImages] = useState([]);
  if (searchTerm) {
    const filteredDoc = posts.documents.filter(
      (val: {
        title?: string;
      }): val is {
        title: string;
      } => {
        return (
          val && val.title?.toLowerCase().includes(searchTerm?.toLowerCase())
        );
      }
    );

    visiblePosts = {
      ...posts,
      documents: filteredDoc.map((val) => {
        let uuid = cuid();
        extractedUids.push(uuid);
        return {
          ...val,
          uuid,
        };
      }),
    };
  }

  // const settClient = (e) => {
  //   const User = new UserService(authUser.id);

  //   return User.updateUser({
  //     lastClientSelection: e,
  //   })
  //     .then((res) => {
  //       setClient(e);
  //     })
  //     .catch((err) => {});
  // };

  const setClientNew = (e) => {
    // ("setClientNew", e);
    return authService
      .updateUser(authUser.id, {
        lastClientSelection: e,
      })
      .then((res) => {
        setClient(e);
        // setAuthUser((user) => ({ ...user, lastClientSelection: e }));
      })
      .catch((err) => {});
  };

  visiblePosts = postsFilter(
    channels,
    visiblePosts,
    monthlyCategory,
    weeklyCategory,
    sortDirection
  );
  if (channels?.length > 0 && !channels?.includes("all")) {
    totalPages = Math.ceil(visiblePosts?.length / defaultPageSize);
  }

  useEffect(() => {
    if (!!authUser?.lastClientSelection) {
      setClient(authUser?.lastClientSelection);
    } else {
      setClient("");
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (loading) {
      setLoading(false);
    }
    // Fetch and display posts for the selected page
  }, [
    endIndex,
    loading,
    posts,
    startIndex,
    visiblePosts,
    searchTerm,
    extractedUids,
  ]);

  const handleNormalMode = (): void => {
    // remove padding to toPrintContainer
    const toPrintContainer: any = document.getElementById("to-print");
    toPrintContainer.style.padding = "0px";

    // Un hide header
    const header: any = document.getElementById("header");
    header.style.display = "block";

    // Un hide selector
    const selector: any = document.getElementById("selector");
    selector.style.display = "flex";

    // Un hide filter
    const filter: any = document.getElementById("filter");
    filter.style.display = "flex";

    // Un Hide PublicLink
    const PublicLink: any = document.getElementById("public-link");
    PublicLink.style.display = "block";

    // Un hide posts carousel
    const postsCarousel: any = document.querySelectorAll("#carousel");
    postsCarousel.forEach((pst) => (pst.style.display = "block"));

    // Hide posts placeholder
    const placeholder: any = document.querySelectorAll("#image-placeholder");
    placeholder.forEach((pst) => (pst.style.display = "none"));

    // Un hide posts actions
    const postsAction: any = document.querySelectorAll("#actions-post");
    postsAction.forEach((pst) => (pst.style.display = "block"));

    // Add box shadow
    const post: any = document.querySelectorAll("#postCard");
    post.forEach(
      (pst) => (pst.style.boxShadow = "0 5px 10px 0 rgba(0, 0, 0, 0.1)")
    );

    // hide selector
    const icons: any = document.getElementById("only-inprint");
    if (icons) {
      icons.style.display = "none";
    }
    const iconSec: any = document.getElementById("only-inprint-sec");
    if (iconSec) {
      icons.style.display = "none";
    }
  };
  const [externalOpen, setExternalOpen] = useState(false);

  useEffect(() => {
    urlDates(dateRange, history, paramsChange, setDateRange, location);

    if (client !== "") {
      setLoading(true);
    }
  }, [dateRange, client, paramsChange, location]);

  return (
    <BePlannedFeedV2>
      <Toast>
        <ToastContainer progressClassName="toast-progressbar" />
      </Toast>
      <div id="analytics">
        <div
          className={`${classes.pageTitle} be-planned-title`}
          id="page-title"
        >
          <Grid
            xs={6}
            md={6}
            sm={12}
            style={{
              fontFamily: "Avenir",
              fontSize: "30px",
              fontWeight: 900,
              lineHeight: "25px",
              letterSpacing: "0em",
              textAlign: "left",
            }}
          >
            Content Planner
          </Grid>

          <Grid
            xs={6}
            md={6}
            sm={12}
            style={{
              alignContent: "right",
            }}
          >
            {authUser.role !== "viewerAdmin" && (
              <div id="create-post">
                <CreateEditPostV2
                  user={client}
                  role={authUser.role}
                  create={"create"}
                  setExternalOpen={setExternalOpen}
                  externalOpen={externalOpen}
                />
              </div>
            )}
          </Grid>
        </div>
      </div>
      <div
        id="only-inprint"
        style={{
          display: "none",
        }}
      >
        <Button
          disableRipple
          type="button"
          classes={{
            label: classes.archiveLabel,
          }}
          onClick={handleNormalMode}
        >
          Normal View
        </Button>{" "}
      </div>
      <Grid container id="selector">
        <Grid xs={12} md={7} container>
          <span className={classes.beplannedSearchbar}>
            <Checkbox
              checked={formData.subscribe}
              style={{ color: "#000", borderRadius: "1px" }}
              icon={<UncheckedIcon />} // SVG icon for unchecked state
              checkedIcon={<CheckedIcon />} // SVG icon for checked state
              onChange={handleCheck}
            />
            {postIds.length > 0 && (
              <span
                style={{
                  fontSize: "15px",
                  fontFamily: "Avenir",
                  fontWeight: 350,
                }}
              >
                {postIds.length} Post selected
              </span>
            )}
            {/* {postIds.length > 0 && (
              <span
                style={{
                  paddingLeft: "20px",
                }}
              >
                <DownloadMultipleAction
                  generateScreenshots={() => generateScreenshots(postData)}
                />
              </span>
            )} */}
            {postIds.length > 0 && (
              <span
                style={{
                  padding: "20px",
                  marginTop: "500px",
                }}
              >
                <DeletePostList ids={postIds} role={"multichannel"} />
              </span>
            )}
          </span>
          <Grid
            xs={12}
            md={4}
            className={
              postIds.length > 0
                ? classes2.hiddenGrid
                : classes.beplannedSearchbar
            }
          >
            <div style={inputStyle}>
              <input
                type="text"
                placeholder="Search a post"
                value={searchTerm}
                onChange={handleSearchChange}
                style={{
                  border: "none", // Remove border
                  width: "100%", // Full width
                  padding: "8px", // Adjust padding if needed
                  fontSize: "15px", // Adjust font size
                  fontFamily: "Avenir",
                  fontWeight: 350,
                }}
              />
              <SearchIcon
                style={{ height: "24px", width: "24px", color: "#ABADAD" }}
              />
            </div>
          </Grid>
          <Grid
            xs={12}
            md={3}
            container
            style={{
              textAlign: "center",
              marginTop: "40px",
            }}
            className={postIds.length > 0 ? classes2.hiddenGrid : ""}
          >
            <span>
              <span
                style={{
                  fontFamily: "Avenir",
                  fontSize: "15px",
                  fontWeight: 900,
                  lineHeight: "22px",
                  letterSpacing: "0em",
                  textAlign: "left",
                  paddingRight: "10px",
                  paddingLeft: "10px",
                }}
              >
                Filter posts
              </span>
            </span>
            <span>
              <Filter
                setMonthlyCategory={setMonthlyCategory}
                setWeeklyCategory={setWeeklyCategory}
              />
            </span>
            <span>
              <SortButton
                onSortToggle={handleSortToggle}
                sortDirection={sortDirection}
              />
            </span>
          </Grid>

          <Grid
            xs={12}
            md={4}
            style={{
              paddingLeft: "10px",
              paddingRight: "10px",
            }}
            className={postIds.length > 0 ? classes2.hiddenGrid : ""}
          >
            <Select
              value={channels}
              setItems={setChannels}
              options={channelOptions}
              label="Choose Channels"
              multiple={true}
              width="100%"
            />
          </Grid>
        </Grid>
        <Grid
          xs={12}
          md={5}
          container
          className={postIds.length > 0 ? classes2.hiddenGrid : ""}
        >
          <Grid xs={12} md={5} style={{}}>
            <DateRangePicker
              dateRange={dateRange}
              setDateRange={setDateRange}
              setParamsChange={setParamsChange}
              minDate={new Date("2020-06-06")}
              align={"left"}
            />
          </Grid>
          <Grid
            xs={12}
            md={5}
            style={{
              marginTop: 0,
              paddingLeft: "10px",
              paddingRight: "10px",
            }}
          >
            <ClientListV2
              client={client}
              setClient={setClientNew}
              setLoading={setLoading}
              align="left"
            />
          </Grid>
          <Grid
            xs={12}
            md={2}
            style={{
              textAlign: "center",
              paddingTop: 38,
            }}
          >
            <div
              className="icons"
              id="icons"
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <PublicLink location={location} primaryEmail={client.email} />
              <PrintMode to={toDate} from={from} user={client} />
            </div>
          </Grid>
        </Grid>
      </Grid>
      <br />

      <Grid container>
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
          pageSize={defaultPageSize}
          setPageSize={setDefaultPageSize}
          pageSizeOptions={pageSizeOptions}
        />
      </Grid>

      <div id="analytics">
        {!loading ? (
          client !== "" ? (
            <>
              {
                <SearchList
                  visiblePosts={visiblePosts}
                  client={client}
                  channels={channels}
                  monthlyCategory={monthlyCategory}
                  weeklyCategory={weeklyCategory}
                  postIds={postIds}
                  setPostIds={setPostIds}
                  checkAll={checkAll}
                  uncheckAll={uncheckAll}
                  sortDirection={sortDirection}
                />
              }
            </>
          ) : (
            <div className={classes.noClient} id="empty-analytics">
              To view the Be-Planned Feed of a client, please choose a client
              above.
            </div>
          )
        ) : (
          <CustomLoader />
        )}
      </div>

      <br />

      <Grid container>
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
          pageSize={defaultPageSize}
          setPageSize={setDefaultPageSize}
          pageSizeOptions={pageSizeOptions}
        />
      </Grid>
    </BePlannedFeedV2>
  );
};
