/**
 * @prettier
 */
import React, { FC, Fragment } from "react";
import { PageType } from "types/PageType";
import { useAuthHook, AuthHookType } from "hooks/AuthHook";
import { Container, CardContent, Typography, Card } from "@material-ui/core";

const App: FC<PageType> = ({ location, children }: PageType) => {
  // const isAllowRender = useAuthHook({ location } as AuthHookType);

  return (
    <>
      <Container
        maxWidth="sm"
        style={{
          minHeight: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Card
          style={{
            textAlign: "center",
            padding: 24,
            backgroundColor: "black",
            boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.2)",
            borderRadius: "16px",
          }}
        >
          <CardContent>
            <Typography variant="h3" style={{ color: "red" }}>
              Website Offline
            </Typography>
            <Typography variant="body1" style={{ marginTop: 16, color: "yellow" }}>
              This website is temporarily unavailable due to unpaid invoices.
            </Typography>
            <Typography variant="body2" style={{ marginTop: 8, color: "gray" }}>
              Please contact support to resolve this issue.
            </Typography>
          </CardContent>
        </Card>
      </Container>
    </>
  );
};

export default App;
