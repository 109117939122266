/**
 * @prettier
 */

import React, { FunctionComponent, useState } from "react";
import { CircularProgress, Button } from "@material-ui/core";
import { Formik } from "formik";
import * as Yup from "yup";

import Email from "components/Common/Form/Email";
import Password from "components/Common/Form/Password";
import { useUser } from "UserContext";
import { auth } from "firebase-tools/firebase";
import { AppConfig } from "AppConfig";
import { IUser } from "interfaces/IUser";
import { UserService } from "services/UserService";
import { useStyles } from "./styles";

export const Login: FunctionComponent = () => {
  const classes = useStyles({});
  const [error, setError] = useState("");
  const [, setIsLogin] = useState(false);
  const [, setUser] = useUser() as any;
  const [isLoading, setIsLoading] = useState(false);

  const login = async (email: string, password: string) => {
    setIsLoading(true);
    const User = new UserService();

    const users = (await User.getUserByField("email", email)) as Array<IUser>;
    if (users && users.length && !AppConfig.role.includes(users[0].role)) {
      setIsLoading(false);
      return setError("An error occurred!");
    }

    auth
      .signInWithEmailAndPassword(email, password)
      .then(() => {
        setIsLogin(true);
        auth.onAuthStateChanged(async (userAuth) => {
          setUser(userAuth);
        });
      })
      .catch((error) => {
        if (
          (error.code =
            "auth/user-not-found" || error.code === "auth/wrong-password")
        ) {
          setError("Invalid credentials!");
        } else {
          setError("An error occurred!");
        }
      });
    setIsLoading(false);
  };

  return (
    <Formik
      initialValues={{ email: "", password: "" }}
      onSubmit={(values) => {
        login(values.email, values.password);
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string().email("Invalid email!").required("Required!"),
        password: Yup.string().required("Required!"),
      })}
    >
      {(props) => {
        const { values, touched, errors, handleChange, handleSubmit } = props;
        return (
          <form className={classes.form} noValidate onSubmit={handleSubmit}>
            <Email
              autoFocus
              onChangeHandler={(e) => {
                handleChange(e);
                setError("");
              }}
              value={values.email}
              helperText={errors.email && touched.email && errors.email}
              error={errors.email && touched.email}
              color={errors.email ? "#f44336" : "#fff"}
              marginTop="24px"
            />
            <Password
              onChangeHandler={(e) => {
                handleChange(e);
                setError("");
              }}
              value={values.password}
              helperText={
                errors.password && touched.password && errors.password
              }
              error={errors.password && touched.password}
              color={errors.password ? "#f44336" : "#fff"}
              marginTop="24px"
            />
            <span className={classes.error}>{error}</span>
            <Button
              disableRipple
              type="submit"
              fullWidth
              variant="contained"
              color={"primary"}
              className={classes.submit}
              classes={{ disabled: classes.disabledButton }}
              disabled={isLoading}
            >
              {!isLoading && "Sign In"}
              {isLoading && <CircularProgress size={30} color="inherit" />}
            </Button>
          </form>
        );
      }}
    </Formik>
  );
};
