import React, { useEffect, useCallback, useState } from "react";
import { Autocomplete } from "@material-ui/lab";
import { Grid, Box, TextField } from "@material-ui/core";
import { useStylesV2 } from "./stylesV2";
import { IUser } from "interfaces/IUser";
import authService from "helpers/user/auth.service";

interface ClientListProps {
  client: IUser | null;
  setClient: (client: IUser | null) => void;
  setLoading?: (loading: boolean) => void;
  styleProps?: Record<string, any>;
  error?: string;
  setErrors?: any;
}

export const ClientList: React.FunctionComponent<ClientListProps> = ({
  client,
  setClient,
  setLoading,
  styleProps,
  error,
  setErrors,
}) => {
  const [users, setUsers] = useState<IUser[]>([]);
  const [loading, setLocalLoading] = useState(false);
  const fetchAllCustomers = async () => {
    setLocalLoading(true);
    setLoading && setLoading(true);
    try {
      const users = await authService.fetchAllCustomers();
      setUsers(users);
    } catch (error) {
      console.error("Error fetching customers:", error);
      setErrors &&
        setErrors((prevErrors) => ({
          ...prevErrors,
          fetch: "Failed to load users",
        }));
    } finally {
      setLocalLoading(false);
      setLoading && setLoading(false);
    }
  };
  useEffect(() => {
    fetchAllCustomers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // let users = useCollectionSubscriber<IUser>({ queries }).documents;
  // users = users.filter((user) => !user.isArchived && !user.isExternal);

  const classes = useStylesV2({
    listLength: users.length > 11 ? "400px" : "auto",
    ...styleProps,
  });

  const getOptionLabel = useCallback(
    (option: IUser) => `${option?.firstName || ""} ${option?.lastName || ""}`,
    []
  );

  return (
    <Grid id="client-list" className={classes.clientList}>
      <Box component="span" className={classes.dateLabel}>
        Choose Client
      </Box>
      <Box>
        <Autocomplete
          id="client-autocomplete"
          value={client || null}
          options={users}
          getOptionLabel={getOptionLabel}
          getOptionSelected={(option, value) => option.id === value.id}
          loading={loading}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              error={!!error}
              helperText={error || (loading && "Loading clients...")}
              className={classes.autoCompleteField}
            />
          )}
          onChange={(_, value) => {
            setClient(value);
            if (value) {
              setErrors &&
                setErrors((prevErrors) => ({
                  ...prevErrors,
                  client: "",
                }));
            }
          }}
          autoHighlight={true}
          className={classes.autoComplete}
        />
      </Box>
    </Grid>
  );
};
