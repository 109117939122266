import React, { FC, useState, useEffect, useCallback } from "react";
import { PageType } from "types/PageType";
import moment from "moment";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Select from "components/Common/Select";
import { useUser } from "UserContext";
import { IUser } from "interfaces/IUser";
import { IPost } from "interfaces/IPost";
import { UserService } from "services/UserService";
import useCollectionSubscriber from "../../hooks/useCollectionSubscriber";
import useDocumentSubscriber from "hooks/useDocumentSubscriber";
import { firestore } from "../../firebase-tools/firebase";

import { ClientList } from "components/BeMeasured/ClientList";
import BePlannedMonthlyPage from "components/BePlannedMonthly";
import BePlannedMonthlyStyled, {
  Clients,
} from "components/styled/BePlannedMonthly";
// import CreateEditPost from "components/BePlanned/CreateEditPost";
import CreateEditPostV2 from "components/BePlanned/CreateEditPostV2";
import { postsFilter } from "utils/postsFilter";
import CreateEditEvent from "components/BePlannedMonthly/CreateEditEvent";
import Filter from "components/BePlanned/Filter";
import { Toast } from "components/styled/Common";
import PrintMode from "components/BePlannedMonthly/PrintMode";
import RetainerTemplate from "components/RetainerTemplate";
import PublicLink from "components/Common/PublicLink";

const channelOptions = [
  "Instagram",
  "Facebook",
  "Twitter",
  "TikTok",
  "YouTube",
  "Others",
  "All",
];

export const BePlannedMonthly: FC<PageType> = ({ location }) => {
  const [selectedDateTo, handleDateToChange]: any = useState(
    moment().endOf("month").endOf("day").toDate()
  );
  const [selectedDateFrom, handleDateFromChange]: any = useState(
    moment().startOf("month").startOf("day").toDate()
  );
  const [authUser] = useUser() as Array<IUser>;

  const [, setClient]: any = useState("");
  const [loading, setLoading] = useState(false);
  // const [locationValue, setLocationValue]: any = useState("");
  const [channels, setChannels]: any = useState([]);
  const [monthlyCategory, setMonthlyCategory] = useState([]);
  const [weeklyCategory, setWeeklyCategory] = useState([]);
  const [paramsChange, setParamsChange]: any = useState("");
  const [selectedPosts, setSelectedPosts]: any = useState([]);

  const from = new Date(selectedDateFrom);
  let to = new Date(selectedDateTo);
  to = moment(to).add(1, "days").toDate();

  let userRef = firestore.collection("users").doc(authUser.id);
  let client =
    useDocumentSubscriber({ ref: userRef }).document?.lastClientSelection || "";

  const handleDateChange = useCallback(
    (date) => {
      const toDt = moment(date.end);
      const froDt = moment(date.start);

      paramsChange !== "date-change" && setParamsChange("date-change");

      handleDateToChange(toDt);
      handleDateFromChange(froDt);
    },
    [paramsChange]
  );

  let postsQueries: any = [];
  let eventsQueries: any = [];
  let pastEventsQueries: any = [];
  if (client !== "") {
    postsQueries = [
      firestore
        .collection("multichannel-posts")
        .where("user_email", "==", client.email)
        .where("date", ">=", from)
        .where("date", "<=", to)
        .orderBy("date", "desc"),
    ];
    eventsQueries = [
      firestore
        .collection("events")
        .where("user_email", "==", client.email)
        .where("start_date", ">=", from)
        .where("start_date", "<=", to)
        .orderBy("start_date", "desc"),
    ];

    pastEventsQueries = [
      firestore
        .collection("events")
        .where("user_email", "==", client.email)
        .where("end_date", ">=", from)
        .orderBy("end_date", "desc"),
    ];
  }

  let posts;
  let events;
  let pastEvents;
  posts = useCollectionSubscriber<IPost>({
    queries: postsQueries,
    deps: [selectedDateFrom, client],
  });
  events = useCollectionSubscriber({
    queries: eventsQueries,
    deps: [selectedDateFrom, selectedDateTo, client],
  });
  pastEvents = useCollectionSubscriber({
    queries: pastEventsQueries,
    deps: [selectedDateFrom, selectedDateTo, client],
  });
  const settClient = (e) => {
    const User = new UserService(authUser.id);

    return User.updateUser({
      lastClientSelection: e,
    })
      .then((res) => {
        setClient(e);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    if (!!authUser?.lastClientSelection) {
      setClient(authUser?.lastClientSelection);
    } else {
      setClient("");
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (loading) {
      setLoading(false);
    }
    // eslint-disable-next-line
  }, [posts.documents]);

  useEffect(() => {
    if (!posts && client !== "") setLoading(true);
    if (!posts.documents.length && client !== "") setLoading(true);
    // eslint-disable-next-line
  }, [selectedDateFrom, selectedDateTo, client]);

  // useEffect(() => {
  //   setLocationValue(location?.search);
  //   // eslint-disable-next-line
  // }, [location]);

  const handleSelectedPosts = (post: any, clear?: boolean) => {
    if (clear) return setSelectedPosts([]);
    const postsSelected = [...selectedPosts];

    const index = postsSelected.indexOf(post);
    if (index > -1) {
      postsSelected.splice(index, 1);
      setSelectedPosts(postsSelected);
      return;
    }

    postsSelected.push(post);
    setSelectedPosts(postsSelected);
  };
  const monthFallBack = moment().format("MMMM");

  let calendarDate = document.getElementsByClassName("fc-toolbar-title");
  let [month]: any = calendarDate[0]?.innerHTML.split(" ") || [
    `${monthFallBack}`,
  ];
  const [externalOpen, setExternalOpen] = useState(false);

  return (
    <BePlannedMonthlyStyled>
      <Toast>
        <ToastContainer progressClassName="toast-progressbar" />
      </Toast>
      <div className="be-planned-monthly" id="page-title">
        <span style={{ marginTop: 32 }}>Monthly Planner</span>
        <div className="selector-container">
          <div className="icons">
            <div style={{ paddingBottom: 16 }}>
              <PublicLink location={location} primaryEmail={client.email} />
            </div>
            <PrintMode user={client} date={month} />
            <RetainerTemplate user={client} />
            <Filter
              setMonthlyCategory={setMonthlyCategory}
              setWeeklyCategory={setWeeklyCategory}
            />
          </div>
          <div className="selector" id="selector-calendar">
            <div id="selector-buttons">
              {authUser.role !== "viewerAdmin" && (
                <CreateEditPostV2
                  create="create"
                  role={authUser.role}
                  user={client}
                  setExternalOpen={setExternalOpen}
                  externalOpen={externalOpen}
                />
              )}
              {authUser.role !== "viewerAdmin" && (
                <CreateEditEvent create="create" user={client} />
              )}
            </div>
            <Select
              value={channels}
              setItems={setChannels}
              options={channelOptions}
              label="Choose Channels"
              multiple={true}
            />
            <Clients>
              <ClientList
                client={client}
                setClient={settClient}
                setLoading={setLoading}
              />
            </Clients>
          </div>
        </div>
      </div>

      <BePlannedMonthlyPage
        posts={() =>
          postsFilter(channels, posts, monthlyCategory, weeklyCategory)
        }
        events={[...pastEvents.documents, ...events.documents]}
        user={client}
        handleDateChange={handleDateChange}
        handleSelectedPosts={handleSelectedPosts}
        selectedPosts={selectedPosts}
      />

      {/* <BePlannedWeeklyPage
          posts={() =>
            postsFilter(channels, posts, monthlyCategory, weeklyCategory)
          }
          events={events}
          user={client}
          handleDateChange={handleDateChange}
        /> */}
    </BePlannedMonthlyStyled>
  );
};
