import { Grid } from "@material-ui/core";
import BePlannedFeedV2 from "components/styled/BePlannedFeedV2";
import React, { FC, useEffect, useState } from "react";
import history from "history/browser";
import { IAiReport, SeriesData } from "../types";
import { IUser } from "interfaces/IUser";
import { useStyles } from "../styles";
import { ClientListV2 } from "components/BeMeasured/ClientListV2";
import { extractPlatforms } from "utils/extractPlatforms";
import moment from "moment";
import { DateRange } from "materialui-daterange-picker";
import { failure, warning } from "utils/notification";
import Axios from "axios";
import { useUser } from "UserContext";
import DateRangePicker from "components/Common/DateRangePicker";
import Select from "components/Common/Select";
import MetricCard, { MetricType } from "./MetricCard";
import ColumnBarChart from "../charts/ColumnBarChart";
import { getFbData, getIgData } from "utils/metrics";
import { formatDateNew, urlDates } from "utils/dateFormat";
import { generaterReport } from "../chatgpt.util";
import ReportComponent from "./ReportComponent";
import AreaChart from "../charts/AreaChart";
import { calculatePercentageGrowth } from "../report.util";
import { PageType } from "types/PageType";
import authService from "helpers/user/auth.service";

interface IProps {
  client: IUser;
  location: any;
}

const ReportV2: FC<PageType> = ({ location }) => {
  const [, setCustomer] = useState({} as IUser);

  useEffect(() => {
    if (!!authUser?.lastClientSelection) {
      setClient(authUser?.lastClientSelection);
      setCustomer(authUser?.lastClientSelection);
    } else {
      setClient("");
    }
    // eslint-disable-next-line
  }, []);

  const labelStyle = {
    color: "#000000",
    fontWeight: 900,
    fontSize: "15px",
    fontFamily: "Avenir",
    paddingBottom: "10px",
  };
  const classes = useStyles({});
  const [client, setClient]: any = useState("");
  const [channel, setChannel]: any = useState("Facebook");
  const [loading, setLoading] = useState(true);
  const [authUser] = useUser() as Array<IUser>;
  const [report, setReport] = useState({} as IAiReport);
  const [, setBtnDisabled] = useState(true);
  const [paramsChange, setParamsChange]: any = useState("");
  const {
    content,
    engagement,
    audienceGrowth,
    engagementRate,
    reach,
    metrics,
  } = report;
  const [engagementInsights, setEngagementInsights] = useState("");
  const [reachInsights, setReachInsights] = useState("");
  const [engagementRateInsights, setEngagementRateInsights] = useState("");
  const [contentInsights, setContentInsights] = useState("");

  const [dateRange, setDateRange] = React.useState<DateRange>({
    startDate: moment().subtract(7, "days").startOf("day").toDate(),
    endDate: moment().subtract(1, "days").startOf("day").toDate(),
  });

  // let userRef = firestore.collection("users").doc(authUser.id);
  // let client =
  //   useDocumentSubscriber({
  //     ref: userRef,
  //   }).document?.lastClientSelection || "";
  const channelOptions = extractPlatforms(client);

  let fetchData: any =
    channel === "Facebook" && metrics ? getFbData(metrics) : null;
  let fetchIgData: any =
    channel === "Instagram" && metrics ? getIgData(metrics, "timeline") : null;

  let selectedMetrics: any = fetchData || fetchIgData;

  const dummyData: {
    categories: string[];
    series: SeriesData[];
  } = {
    categories: selectedMetrics ? formatDateNew(selectedMetrics.dates) : [],
    series: [
      ...(channel === "Facebook"
        ? [
            {
              name: "Likes",
              data: selectedMetrics ? selectedMetrics.likes : [],
              color: "#5187E0",
            },
            {
              name: "Comments",
              data: selectedMetrics ? selectedMetrics.comments : [],
              color: "#E0516B",
            },
            {
              name: "Engagement",
              data: selectedMetrics ? selectedMetrics.engagements : [],
              color: "#FFAB2D",
            },
          ]
        : [
            {
              name: "Profile Views",
              data: selectedMetrics ? selectedMetrics?.profileViews : [],
              color: "#5187E0",
            },
            {
              name: "Engagement",
              data: selectedMetrics ? selectedMetrics?.engagements : [],
              color: "#FFAB2D",
            },
          ]),
    ],
  };

  const reachGraphData = {
    categories: selectedMetrics ? selectedMetrics.dates : [],
    series: [
      {
        name: "Total Reach",
        data: selectedMetrics ? selectedMetrics.reach : [],
      },
    ],
  };

  const audienceGrowthData = {
    categories: selectedMetrics ? selectedMetrics.dates : [],
    series: [
      {
        name: "Audience Growth",
        data: selectedMetrics ? selectedMetrics.followers : [],
      },
    ],
  };

  useEffect(() => {
    if (selectedMetrics) {
      let engagementData = {
        categories: selectedMetrics ? formatDateNew(selectedMetrics.dates) : [],
        engagement: selectedMetrics ? selectedMetrics.engagements : [],
        likes: selectedMetrics ? selectedMetrics.likes : [],
        comments: selectedMetrics ? selectedMetrics.comments : [],
      };
      audienceGrowth.audienceGrowth =
        calculatePercentageGrowth(selectedMetrics?.followers).toFixed(4) + "% ";

      generaterReport(
        JSON.stringify(engagementData),
        "short",
        "engagement"
      ).then((res: any) => {
        engagement.insight = res;
        setEngagementInsights(engagement.insight);
      });

      generaterReport(JSON.stringify(reachGraphData), "short", "reach").then(
        (res: any) => {
          reach.insight = res;
          setReachInsights(reach.insight);
        }
      );

      let engageRateData = {
        totalEngagementRate: engagementRate.engagementRate,
        startDate: dateRange.startDate,
        endDate: dateRange.endDate,
      };
      generaterReport(
        JSON.stringify(engageRateData),
        "short",
        "engagementRate"
      ).then((res: any) => {
        setEngagementRateInsights(res);
      });

      let contentData = {
        topPerformingPost: content.topPerformingPost,
        likes: content.Likes,
        comments: content.comments,
        share: content.share,
        startDate: dateRange.startDate,
        endDate: dateRange.endDate,
      };

      generaterReport(JSON.stringify(contentData), "short", "content").then(
        (res: any) => {
          content.insight = res;
          setContentInsights(content.insight);
        }
      );
    }
    // eslint-disable-next-line
  }, [engagement?.totalEngagements]);

  const clearData = () => {
    setEngagementInsights("");
    setReachInsights("");
    setEngagementRateInsights("");
    setContentInsights("");

    setReport({} as IAiReport);
    selectedMetrics = null;
    setParamsChange("");
  };

  const fetchReport = async () => {
    const { startDate, endDate } = dateRange;
    const start = moment(startDate).format("YYYY-MM-DD");
    const end = moment(endDate).format("YYYY-MM-DD");

    // TODO check if selected date range is more than 30 days ans show warning
    if (moment(endDate).diff(moment(startDate), "days") > 30) {
      warning("Please select date range less than 30 days", 10000);
      return;
    }
    setLoading(true);

    try {
      if (channel === "Facebook") {
        clearData();
        let res = await Axios.post(
          process.env.REACT_APP_FUNCTIONS_BASE_URL + "chatgpt-generateReport",
          {
            username: client.facebook.username,
            platform: "facebook",
            startDate: start,
            endDate: end,
            wordLength: 50,
            insight: false,
          }
        );
        if (res.data) {
          setReport(res.data);
        }

        setLoading(false);
      } else if (channel === "Instagram") {
        clearData();
        let res = await Axios.post(
          process.env.REACT_APP_FUNCTIONS_BASE_URL + "chatgpt-generateReport",
          {
            username: client.instagram.username,
            platform: "instagram",
            startDate: start,
            endDate: end,
            wordLength: 50,
            insight: false,
          }
        );
        if (res.data) {
          setReport(res.data);
        }

        setLoading(false);
      } else {
        warning(`${channel} is not supported at the moment`);
      }
    } catch (error) {
      console.log(error);

      failure("Unable to generate report at the moment, please retry!");
    }

    // setLoading(false);
  };

  // const settClient = (e) => {
  //   const User = new UserService(authUser.id);

  //   return User.updateUser({
  //     lastClientSelection: e,
  //   })
  //     .then((res) => {
  //       setClient(e);
  //     })
  //     .catch((err) => {});
  // };

  const setClientNew = (e) => {
    return authService
      .updateUser(authUser.id, {
        lastClientSelection: e,
      })
      .then((res) => {
        setClient(e);
        // setAuthUser((user) => ({ ...user, lastClientSelection: e }));
      })
      .catch((err) => {});
  };

  useEffect(() => {
    if (client) {
      fetchReport().catch((err) => {
        console.log(err);
      });
    }
    // eslint-disable-next-line
  }, [paramsChange, client, channel]);

  useEffect(() => {
    urlDates(dateRange, history, paramsChange, setDateRange, location);

    // if (client !== "") {
    //   setLoading(true);
    // }
  }, [dateRange, client, paramsChange, location]);

  useEffect(() => {
    urlDates(dateRange, history, paramsChange, setDateRange, location);
  }, [dateRange, client, paramsChange, location]);

  return (
    <BePlannedFeedV2>
      <Grid
        container
        style={{ marginBottom: 20, marginTop: 20 }}
        className={classes.font}
      >
        <Grid item xs={12} md={5} className={classes.pageTitle}>
          <p className={classes.reportTitle}>Insights</p>
        </Grid>
        <Grid item xs={12} md={7} container>
          <Grid item xs={12} md={5} className={classes.reportFilters}>
            <DateRangePicker
              dateRange={dateRange}
              setDateRange={setDateRange}
              setParamsChange={setParamsChange}
              minDate={new Date("2020-06-06")}
              disableFuture={true}
              align={"left"}
              label={"Choose Period"}
            />
          </Grid>
          <Grid item xs={12} md={7} container>
            <Grid item xs={12} md={6} className={classes.reportFilters}>
              <ClientListV2
                client={client}
                setClient={setClientNew}
                setLoading={setLoading}
                align="left"
              />
            </Grid>
            <Grid item xs={12} md={6} className={classes.reportFilters}>
              <Select
                value={channel}
                setItems={setChannel}
                options={channelOptions}
                label="Choose Platform"
                multiple={false}
                width="100%"
                labelStyle={labelStyle}
                onChangeHandler={(e) => {
                  e.preventDefault();
                  setBtnDisabled(false);
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid container className={classes.cardStyle}>
        <Grid container>
          <h2 className={`${classes.subTitle} ${classes.fontWeight}`}>
            Engagement
          </h2>
          <Grid container>
            <Grid
              item
              xs={12}
              md={3}
              className={`${classes.metricCardSpacing}`}
            >
              <MetricCard
                loading={loading}
                setLoading={setLoading}
                cardTitle={"Total Engagements"}
                cardNumber={engagement?.totalEngagements}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={3}
              className={`${classes.metricCardSpacing}`}
            >
              <MetricCard
                loading={loading}
                setLoading={setLoading}
                cardTitle={"Likes"}
                cardNumber={engagement?.likes}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={3}
              className={`${classes.metricCardSpacing}`}
            >
              <MetricCard
                loading={loading}
                setLoading={setLoading}
                cardTitle={"Comments"}
                cardNumber={engagement?.comments}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={3}
              className={`${classes.metricCardSpacing}`}
            >
              <MetricCard
                loading={loading}
                setLoading={setLoading}
                cardTitle={"Shares"}
                cardNumber={engagement?.shares}
              />
            </Grid>
          </Grid>
          <Grid container>
            <ColumnBarChart
              series={dummyData.series}
              categories={dummyData.categories}
              title="Daily Engagement"
            />
          </Grid>
          <Grid container>
            <ReportComponent htmlContent={engagementInsights} />
          </Grid>
        </Grid>

        <Grid container>
          <h2 className={`${classes.subTitle} ${classes.fontWeight}`}>
            Reach and Impressions
          </h2>
          <Grid container>
            <Grid
              item
              xs={12}
              md={6}
              className={`${classes.metricCardSpacing}`}
            >
              <MetricCard
                loading={loading}
                setLoading={setLoading}
                cardTitle={"Reach"}
                cardNumber={reach?.reach}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              className={`${classes.metricCardSpacing}`}
            >
              <MetricCard
                loading={loading}
                setLoading={setLoading}
                cardTitle={"Impressions"}
                cardNumber={reach?.impressions}
              />
            </Grid>
          </Grid>

          <Grid container>
            <AreaChart
              series={reachGraphData.series}
              categories={reachGraphData.categories}
              title="Overview"
            />
          </Grid>

          <Grid container>
            <ReportComponent htmlContent={reachInsights} />
          </Grid>
        </Grid>

        <Grid container>
          <h2 className={`${classes.subTitle} ${classes.fontWeight}`}>
            Content Performance
          </h2>
          <Grid container>
            <Grid
              item
              xs={12}
              md={3}
              className={`${classes.metricCardSpacing}`}
            >
              <MetricCard
                loading={loading}
                setLoading={setLoading}
                cardTitle={"TopPerformingPost"}
                cardNumber={content?.topPerformingPost}
                type={MetricType.STRING}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={3}
              className={`${classes.metricCardSpacing}`}
            >
              <MetricCard
                loading={loading}
                setLoading={setLoading}
                cardTitle={"Likes"}
                cardNumber={content?.Likes}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={3}
              className={`${classes.metricCardSpacing}`}
            >
              <MetricCard
                loading={loading}
                setLoading={setLoading}
                cardTitle={"Comments"}
                cardNumber={content?.comments}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={3}
              className={`${classes.metricCardSpacing}`}
            >
              <MetricCard
                loading={loading}
                setLoading={setLoading}
                cardTitle={"Shares"}
                cardNumber={content?.share}
              />
            </Grid>
          </Grid>

          {/* <Grid container>
            <AreaChart
              series={reachGraphData.series}
              categories={reachGraphData.categories}
              title="Overview"
            />
          </Grid> */}

          <Grid container>
            <ReportComponent htmlContent={contentInsights} />
          </Grid>
        </Grid>

        <Grid container>
          <h2 className={`${classes.subTitle} ${classes.fontWeight}`}>
            Engagement Rate
          </h2>
          <Grid container>
            <Grid
              item
              xs={12}
              md={12}
              className={`${classes.metricCardSpacing}`}
            >
              <MetricCard
                loading={loading}
                setLoading={setLoading}
                cardTitle={"Engagement Rate"}
                cardNumber={engagementRate?.engagementRate}
                type={MetricType.STRING}
              />
            </Grid>
          </Grid>

          <Grid container>
            <AreaChart
              series={reachGraphData.series}
              categories={reachGraphData.categories}
              title="Overview"
            />
          </Grid>
          <Grid container>
            <ReportComponent htmlContent={engagementRateInsights} />
          </Grid>
        </Grid>

        <Grid container>
          <h2 className={`${classes.subTitle} ${classes.fontWeight}`}>
            Audience Growth
          </h2>
          <Grid container>
            <Grid
              item
              xs={12}
              md={12}
              className={`${classes.metricCardSpacing}`}
            >
              <MetricCard
                loading={loading}
                setLoading={setLoading}
                cardTitle={"AudienceGrowth"}
                cardNumber={audienceGrowth?.audienceGrowth}
                type={MetricType.STRING}
              />
            </Grid>
          </Grid>
          <Grid container>
            <AreaChart
              series={audienceGrowthData.series}
              categories={audienceGrowthData.categories}
              title="Overview"
            />
          </Grid>
          <Grid container>
            <ReportComponent htmlContent={engagementRateInsights} />
          </Grid>
        </Grid>
      </Grid>
    </BePlannedFeedV2>
  );
};

export default ReportV2;
