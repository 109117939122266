import { Grid } from "@material-ui/core";
import React from "react";
// import { ReactComponent as Avatartype } from "static/media/avatarnewtype.svg";
import { ReactComponent as Ellipsis } from "static/media/ellipsis.svg";
import { ReactComponent as Dot } from "static/media/StatusDot.svg";
import { ReactComponent as Rectangle } from "static/media/rectanglecomment.svg";

import { useStylesNot } from "./styles";
import NotificationAction from "./NotificationAction";
import { INotification, NotificationStatus } from "interfaces/INotification";
import { navigate } from "@reach/router";
import { useUser } from "UserContext";
import { IUser } from "interfaces/IUser";
import { updateActiveUser } from "helpers/user/updateActiveUser";
import AvatarIntitials from "./AvatarInitials";
import { updateNotificationStatus } from "helpers/notification/updateNotificationStatus";

interface IProp {
  created_at: any;
  title: string;
  key: any;
  adminName: string;
  clientName: string;
  action: string;
  status: string;
  id: string;
}

const NotificationItemForComment = ({
  prop,
  menu = false,
}: {
  prop: INotification;
  menu: boolean;
}) => {
  const classes = useStylesNot({});
  const [authUser] = useUser() as Array<IUser>;

  const [isOpenNot, setIsOpenNot] = React.useState(false);
  const anchorRef: any = React.useRef(null);
  let col1: any = menu ? 2 : 1;
  let col2: any = menu ? 8 : 9;

  const handleIsOpenNot = () => {
    setIsOpenNot(!isOpenNot);
  };

  const handleClickAwayNot = () => {
    setIsOpenNot(false);
  };

  const handleListKeyDown = (event) => {
    if (event.key === "tab") {
      event.preventDefault();
      setIsOpenNot(false);
    }
  };
  const {
    notificationItemRight,
    notificationItemLeft,
    notificationItemContainer,
  } = classes;

  const formatDate = (date) => {
    // Convert Firestore Timestamp to JavaScript Date
    const jsDate: any = new Date(
      date.seconds * 1000 + date.nanoseconds / 1000000
    );

    const now: any = new Date();
    const diffMs = now - jsDate;

    // Convert milliseconds to seconds, minutes, hours, and days
    const diffSeconds = Math.floor(diffMs / 1000);
    const diffMinutes = Math.floor(diffSeconds / 60);
    const diffHours = Math.floor(diffMinutes / 60);
    const diffDays = Math.floor(diffHours / 24);

    if (diffSeconds < 60) {
      return `${diffSeconds}s`;
    } else if (diffMinutes < 60) {
      return `${diffMinutes}m`;
    } else if (diffHours < 24) {
      return `${diffHours}h`;
    } else {
      return `${diffDays}d`;
    }
  };

  return (
    <Grid
      container
      item
      className={`${notificationItemContainer} ${classes.universalSpaceleftRight}`}
      style={{
        backgroundColor: "#FFFFFF",
      }}
      onClick={(e) => {
        e.preventDefault();
        if (prop?.client) {
          updateActiveUser(prop.client, authUser);
        }
        if (prop.status === NotificationStatus.unread) {
          updateNotificationStatus(NotificationStatus.read, prop);
        }
        navigate(
          `/content-plan-feed/${prop.postId}/${prop.client.email}/posts`
        );
      }}
    >
      <Grid item xs={col1} md={col1} className={notificationItemLeft}>
        {/* {prop.status === "unread" && <Dot />} <Avatartype /> */}
        {prop.status === "unread" && <Dot />}{" "}
        <AvatarIntitials
          name={`${prop.authUser.firstName} ${prop.authUser.lastName}`}
        />
      </Grid>
      <Grid item xs={col2} md={col2}>
        <p
          style={{
            fontSize: 14,
          }}
        >
          <span
            style={{
              fontWeight: 800,
            }}
          >
            {prop.initiatorName}
          </span>
          <span> {prop.action} on</span>
          <span
            style={{
              fontWeight: 800,
            }}
          >
            {" "}
            {prop.postTitle}
          </span>
        </p>
        {/* <Button
          className={notificationItemButton}
          onClick={(e) => {
            e.preventDefault();
            if (prop?.client) {
              updateActiveUser(prop.client, authUser);
            }
            if (prop.status === NotificationStatus.unread) {
              updateNotificationStatus(NotificationStatus.read, prop);
            }
            navigate(
              `/content-plan-feed/${prop.postId}/${prop.client.email}/posts`
            );
          }}
        >
          {" "}
          View{" "}
        </Button> */}
        <span>
          <Rectangle />
        </span>{" "}
        <q
          style={{
            fontSize: 14,
            fontFamily: "Avenir",
          }}
        >
          {prop.comment}
        </q>
      </Grid>
      <Grid xs={2} md={2} container className={notificationItemRight}>
        <div
          style={{
            width: "100%",
            fontSize: 12,
          }}
        >
          {formatDate(prop.created_at)}
        </div>
        <div
          style={{
            width: "100%",
          }}
        >
          <Ellipsis onClick={handleIsOpenNot} ref={anchorRef} />
          <NotificationAction
            anchorRef={anchorRef}
            handleClickAway={handleClickAwayNot}
            handleListKeyDown={handleListKeyDown}
            isOpenNot={isOpenNot}
            notification={prop}
          />
        </div>
      </Grid>
    </Grid>
  );
};

export default NotificationItemForComment;
