/**
 * @prettier
 */
import React, { FC, useEffect, useState } from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { navigate } from "@reach/router";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { fromEvent, Observable } from "rxjs";
import HamburgerMenu from "react-hamburger-menu";

import { ReactComponent as Logo } from "static/logo.svg";
import { ReactComponent as Notification } from "static/notifications.svg";
import { ReactComponent as Bellbefore } from "static/bellbefore.svg";
import { PageType } from "types/PageType";
import { useUser } from "UserContext";
import { IUser } from "interfaces/IUser";
import { signOut } from "hooks/AuthHook";
import Progress from "components/styled/Progress";

import { useStyles } from "./styles";
import {
  Avatar,
  Box,
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
  MenuItem,
  MenuList,
} from "@material-ui/core";

import { NavLink, DropdownLink, BlockingLink } from "./NavLink";
import { CalenderLink } from "./CalenderLink";
import NotificationMenu from "./Notification/NotificationMenu";
import { firestore } from "firebase-tools/firebase";
import { INotification } from "interfaces/INotification";
import useCollectionSubscriber from "hooks/useCollectionSubscriber";

export const Navbar: FC<PageType> = () => {
  const classes = useStyles({});
  const [user] = useUser() as Array<IUser>;
  const [open, setOpen] = useState(false);
  const [bePlannedOpen, setBePlannedOpen] = useState(false);
  const [, setBeAmplifiedOpen] = useState(false);
  const [healthOpen, setHealthOpen] = useState(false);
  const [navOpen, setNavOpen] = React.useState(false);

  const [isOpen, setIsOpen] = React.useState(false);
  const [isOpenNot, setIsOpenNot] = React.useState(false);
  const anchorRef: any = React.useRef(null);
  const [mobileMenu, switchMobileMenu] = useState(
    !(document.documentElement.offsetWidth > 1000)
  );
  const [mobileMenuStatus, toggleMenuStatus] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);

  const inBePlannedRoute =
    window.location.href.includes("content-plan-feed") ||
    window.location.href.includes("monthly-conversation-planner") ||
    window.location.href.includes("be-planned-grid");

  const inBeAmplifiedRoute = window.location.href.includes(
    "/campaigns/organic"
  );

  const resize$: Observable<Event> = fromEvent(window, "resize");

  resize$.subscribe((res: any) => {
    if (res && res.currentTarget) {
      switchMobileMenu(!(res.currentTarget.innerWidth > 1000));
    }
  });
  const handleClose = (event: any) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
    setBePlannedOpen(false);
    setBeAmplifiedOpen(false);
    setHealthOpen(false);
  };
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleIsOpen = () => {
    setIsOpen(!isOpen);
  };
  const handleIsOpenNot = () => {
    setIsOpenNot(!isOpenNot);
  };

  const handleClickAway = () => {
    setIsOpen(false);
    setNavOpen(false);
  };
  const handleClickAwayNot = () => {
    setIsOpenNot(false);
  };

  function handleListKeyDown(event: any) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  const getDocHeight = () => {
    return Math.max(
      document.body.scrollHeight,
      document.documentElement.scrollHeight,
      document.body.offsetHeight,
      document.documentElement.offsetHeight,
      document.body.clientHeight,
      document.documentElement.clientHeight
    );
  };

  useEffect(() => {
    const calculateScrollDistance = () => {
      const scrollTop = window.pageYOffset; // how much the user has scrolled by
      const winHeight = window.innerHeight;
      const docHeight = getDocHeight();

      const totalDocScrollLength = docHeight - winHeight;
      const scrollPosition = Math.floor(
        (scrollTop / totalDocScrollLength) * 100
      );

      setScrollPosition(scrollPosition);
    };

    const listenToScrollEvent = () => {
      document.addEventListener("scroll", () => {
        requestAnimationFrame(() => {
          calculateScrollDistance();
        });
      });
    };

    listenToScrollEvent();
  }, []);

  const prevOpen = React.useRef(open);

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const queries = [
    firestore
      .collection("notifications")
      .where("status", "==", "unread")
      .orderBy("created_at", "desc"),
  ];
  let notificationList = useCollectionSubscriber<INotification>({ queries })
    .documents;

  // Counting the number of unread notifications
  const unreadCount = notificationList.length;

  return mobileMenu ? (
    <>
      <div style={{ height: "65px !important" }}>
        <div
          className={`${classes.menuRow} ${classes.navBar}`}
          style={{ justifyContent: "space-between", zIndex: 100 }}
        >
          <Logo
            className={classes.logo}
            style={{ marginLeft: "17px" }}
            onClick={() => navigate("/admin-panel")}
          />
          <HamburgerMenu
            isOpen={mobileMenuStatus}
            menuClicked={(e) => {
              e.stopPropagation();
              e.nativeEvent.stopImmediatePropagation();
              e.preventDefault();
              toggleMenuStatus(!mobileMenuStatus);
            }}
            width={25}
            height={12}
            strokeWidth={2}
            rotate={0}
            color="white"
            borderRadius={0}
            animationDuration={0.5}
            className={classes.burger}
          />
          <Progress scroll={scrollPosition + "%"} mgTop="74px" />
        </div>
      </div>
      {
        <div
          className={`${classes.mobileSideBarContainer} ${
            mobileMenuStatus ? "" : classes.mobileSideBarContainerClosed
          }`}
        >
          <div
            className={classes.menuRow}
            style={{
              marginTop: "-7px",
              alignItems: "flex-end",
              justifyContent: "flex-end",
            }}
          >
            <HamburgerMenu
              isOpen={mobileMenuStatus}
              menuClicked={(e) => {
                e.stopPropagation();
                e.nativeEvent.stopImmediatePropagation();
                e.preventDefault();
                toggleMenuStatus(!mobileMenuStatus);
              }}
              width={25}
              height={12}
              strokeWidth={1.5}
              rotate={0}
              color="white"
              borderRadius={0}
              animationDuration={0.5}
              className={classes.closeButton}
            />
          </div>

          <div
            className={classes.menuRow}
            style={{ justifyContent: "space-between", marginBottom: 20 }}
          >
            <Avatar
              alt={user.firstName + " " + user.lastName}
              src={user.avatarUrl}
              className={classes.avatar}
              onClick={handleToggle}
            >
              <p className={classes.avatarText}>
                {user?.firstName &&
                  user?.lastName &&
                  user.firstName[0] + user.lastName[0]}
              </p>
            </Avatar>
            <Typography
              ref={anchorRef}
              onClick={handleToggle}
              style={{ fontFamily: '"Avenir Book", sans-serif' }}
            >
              {user.firstName} {user.lastName}
            </Typography>
          </div>
          <div className={classes.menuRow} style={{ flexDirection: "column" }}>
            <span onClick={() => toggleMenuStatus(false)}>
              <NavLink path="admin-panel">Admin Panel</NavLink>
            </span>

            <span onClick={() => toggleMenuStatus(false)}>
              <NavLink path="health-check">Health Check</NavLink>
            </span>

            <span onClick={() => toggleMenuStatus(false)}>
              <NavLink path="be-measured">Be-Measured</NavLink>
            </span>

            <span onClick={() => toggleMenuStatus(false)}>
              <NavLink path="content-plan-feed">Content Planner</NavLink>
            </span>

            <span onClick={() => toggleMenuStatus(false)}>
              <NavLink path="campaigns/organic">Be-Amplified</NavLink>
            </span>
            <CalenderLink
              duration="monthly"
              toggleMenuStatus={toggleMenuStatus}
            />
            {/* Commented for future use */}
            {/* <CalenderLink
              duration="weekly"
              toggleMenuStatus={toggleMenuStatus}
            /> */}
            <span onClick={() => toggleMenuStatus(false)}>
              <NavLink path="be-planned-grid">Be-Planned Grid</NavLink>
            </span>
            {user.role === "superAdmin" && (
              <>
                <span onClick={() => toggleMenuStatus(false)}>
                  <NavLink path="admin-settings">Admin Settings</NavLink>
                </span>
              </>
            )}
            <Typography variant="h6" className={classes.navTitle}>
              <MenuItem
                onClick={() => {
                  signOut();
                  toggleMenuStatus(false);
                }}
                style={{
                  fontWeight: 600,
                }}
              >
                Sign Out
              </MenuItem>
            </Typography>
          </div>
        </div>
      }
    </>
  ) : (
    <>
      <div className={classes.root}>
        <AppBar position="static" className={classes.navBar} id="navbar">
          <Toolbar className={classes.toolbar}>
            <div className={classes.nav}>
              <Logo
                className={classes.logo}
                onClick={() => navigate("/admin-panel")}
              />
              <Box className={classes.menu}>
                <div className={classes.links}>
                  <NavLink path="admin-panel">Admin Panel</NavLink>
                  <div style={{ position: "relative", marginRight: "58px" }}>
                    <div style={{ display: "flex" }}>
                      <div
                        className={
                          window.location.href.includes("health-check") ||
                          window.location.href.includes("activity")
                            ? `${classes.activeLink}`
                            : ""
                        }
                        style={{
                          display: "flex",
                          alignItems: "center",
                          top: "0",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setHealthOpen(true);
                        }}
                      >
                        <div
                          className={`${classes.healthCheckLink}
                                ${
                                  window.location.href.includes(
                                    "health-check"
                                  ) || window.location.href.includes("activity")
                                    ? classes.activeLink
                                    : classes.inActive
                                }`}
                          onClick={() => setHealthOpen(true)}
                        >
                          Healthcheck
                        </div>

                        <ArrowDropDownIcon
                          className={
                            window.location.href.includes("health-check") ||
                            window.location.href.includes("activity")
                              ? `${classes.dropdown}`
                              : `${classes.dropdown} ${classes.inActiveLink}`
                          }
                          fontSize="small"
                          style={{
                            fontSize: "2rem",
                            cursor: "pointer",
                          }}
                        />
                      </div>
                    </div>
                    {healthOpen && (
                      <div className="be-planned-dropdown">
                        <ClickAwayListener onClickAway={handleClose}>
                          <div className={classes.bePlanned}>
                            <DropdownLink
                              path="health-check"
                              setOpen={setHealthOpen}
                            >
                              Healthcheck
                            </DropdownLink>
                            <DropdownLink
                              path="kit-activity"
                              setOpen={setHealthOpen}
                            >
                              User Activity
                            </DropdownLink>
                            {user.role === "superAdmin" && (
                              <DropdownLink
                                path="admin-activity"
                                setOpen={setHealthOpen}
                              >
                                Admin Activity
                              </DropdownLink>
                            )}
                          </div>
                        </ClickAwayListener>
                      </div>
                    )}
                  </div>
                  <NavLink path="be-measured">Be-Measured</NavLink>
                  <div style={{ position: "relative", marginRight: "58px" }}>
                    <div>
                      <div
                        className={
                          inBePlannedRoute ? `${classes.activeLink}` : ""
                        }
                        style={{
                          display: "flex",
                          alignItems: "center",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setBePlannedOpen(true);
                        }}
                      >
                        <div
                          className={`
                          ${classes.link}
                          ${
                            inBePlannedRoute
                              ? classes.activeLink
                              : classes.inActive
                          }`}
                        >
                          Be-Planned
                        </div>

                        <ArrowDropDownIcon
                          className={
                            inBePlannedRoute
                              ? `${classes.dropdown}`
                              : `${classes.dropdown} ${classes.inActiveLink}`
                          }
                          fontSize="small"
                          style={{ fontSize: "2rem", cursor: "pointer" }}
                        />
                      </div>
                    </div>
                    {bePlannedOpen && (
                      <div className="be-planned-dropdown">
                        <ClickAwayListener onClickAway={handleClose}>
                          <div className={classes.bePlanned}>
                            {/* <DropdownLink
                              path="content-plan-feedv2"
                              setOpen={setBePlannedOpen}
                            >
                              Content Plannerv2
                            </DropdownLink> */}
                            <DropdownLink
                              path="content-plan-feed"
                              setOpen={setBePlannedOpen}
                            >
                              Content Planner
                            </DropdownLink>
                            {/* <DropdownLink
                              path="content-plan-feed-combined"
                              setOpen={setBePlannedOpen}
                            >
                              Content Planner Combined
                            </DropdownLink> */}
                            {/* <DropdownLink
                              path="monthly-conversation-planner"
                              setOpen={setBePlannedOpen}
                            >
                              Monthly Planner
                            </DropdownLink> */}
                            {/* <DropdownLink
                              path="monthly-conversation-planner?weekly=true"
                              setOpen={setBePlannedOpen}
                            >
                              Be-Planned Weekly
                            </DropdownLink> */}
                            <DropdownLink
                              path="be-planned-grid"
                              setOpen={setBePlannedOpen}
                            >
                              Be-Planned Grid Layout
                            </DropdownLink>
                            <DropdownLink
                              path="post-schedule"
                              setOpen={setBePlannedOpen}
                            >
                              Post Scheduler
                            </DropdownLink>
                          </div>
                        </ClickAwayListener>
                      </div>
                    )}
                  </div>
                  {/*  */}
                  {/* <NavLink path="campaigns/organic">Be-Amplified </NavLink> */}
                  {/*  */}

                  {/* ---------------- */}
                  <div style={{ position: "relative", marginRight: "58px" }}>
                    <div>
                      <div
                        className={
                          inBeAmplifiedRoute ? `${classes.activeLink}` : ""
                        }
                        style={{
                          display: "flex",
                          alignItems: "center",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          // setBeAmplifiedOpen(true);
                          navigate(`/campaigns/organic?flow=automated`);
                        }}
                      >
                        <div
                          className={`
                          ${classes.link}
                          ${
                            inBeAmplifiedRoute
                              ? classes.activeLink
                              : classes.inActive
                          }`}
                        >
                          Be-Amplified
                        </div>

                        {/* <ArrowDropDownIcon
                          className={
                            inBeAmplifiedRoute
                              ? `${classes.dropdown}`
                              : `${classes.dropdown} ${classes.inActiveLink}`
                          }
                          fontSize="small"
                          style={{ fontSize: "2rem", cursor: "pointer" }}
                        /> */}
                      </div>
                    </div>
                    {/* {beAmplifiedOpen && (
                      <div className="be-planned-dropdown">
                        <ClickAwayListener onClickAway={handleClose}>
                          <div className={classes.bePlanned}>
                            <DropdownLink
                              path="campaigns/organic?flow=automated"
                              setOpen={setBeAmplifiedOpen}
                            >
                              Campaigns
                            </DropdownLink>
                            <DropdownLink
                              path="campaigns/organic?flow=scrappers"
                              setOpen={setBeAmplifiedOpen}
                            >
                              Scrappers
                            </DropdownLink>
                          </div>
                        </ClickAwayListener>
                      </div>
                    )} */}
                  </div>
                  {/* ---------------- */}
                </div>

                <div
                  style={{
                    marginTop: "20.8px",
                  }}
                >
                  {unreadCount > 0 && (
                    <Notification
                      onClick={handleIsOpenNot}
                      style={{
                        cursor: "pointer",
                      }}
                    />
                  )}
                  {unreadCount < 1 && (
                    <Bellbefore
                      onClick={handleIsOpenNot}
                      style={{
                        cursor: "pointer",
                      }}
                    />
                  )}
                  <NotificationMenu
                    anchorRef={anchorRef}
                    handleClickAway={handleClickAwayNot}
                    isOpenNot={isOpenNot}
                  />
                </div>
                <div className={classes.logout}>
                  <Avatar
                    alt={user.firstName + " " + user.lastName}
                    src={user.avatarUrl}
                    className={classes.avatar}
                    onClick={handleIsOpen}
                  >
                    <p className={classes.avatarText}>
                      {user?.firstName &&
                        user?.lastName &&
                        user.firstName[0] + user.lastName[0]}
                    </p>
                  </Avatar>
                  <Typography
                    ref={anchorRef}
                    onClick={handleIsOpen}
                    style={{ fontFamily: "Avenir Black, sans-serif" }}
                  >
                    {user.firstName} {user.lastName}
                  </Typography>
                  <ArrowDropDownIcon
                    className={classes.dropdown}
                    fontSize="small"
                    onClick={handleIsOpen}
                  />
                  <Popper
                    open={isOpen || navOpen}
                    anchorEl={anchorRef.current}
                    role={undefined}
                    transition
                    disablePortal
                    style={{ padding: "15px" }}
                  >
                    {({ TransitionProps, placement }) => (
                      <Grow
                        {...TransitionProps}
                        style={{
                          transformOrigin:
                            placement === "bottom"
                              ? "center top"
                              : "center bottom",
                        }}
                      >
                        <Paper style={{ borderRadius: 2, width: 150 }}>
                          <ClickAwayListener onClickAway={handleClickAway}>
                            <MenuList
                              autoFocusItem={isOpen}
                              id="menu-list-grow"
                              onKeyDown={handleListKeyDown}
                            >
                              {user.role === "superAdmin" && (
                                <>
                                  <MenuItem
                                    style={{
                                      fontFamily: "Avenir Black, sans-serif",
                                      fontWeight: 600,
                                      fontSize: 14,
                                    }}
                                  >
                                    <BlockingLink
                                      to={"/admin-settings"}
                                      style={{
                                        textDecoration: "none",
                                        color: "unset",
                                      }}
                                    >
                                      Admin Settings
                                    </BlockingLink>
                                  </MenuItem>
                                  <MenuItem
                                    style={{
                                      fontFamily: "Avenir Black, sans-serif",
                                      fontWeight: 600,
                                      fontSize: 14,
                                    }}
                                  >
                                    <BlockingLink
                                      to={"/mass-emailer"}
                                      style={{
                                        textDecoration: "none",
                                        color: "unset",
                                      }}
                                    >
                                      Mass Emailer
                                    </BlockingLink>
                                  </MenuItem>
                                </>
                              )}
                              <MenuItem
                                onClick={signOut}
                                style={{
                                  fontFamily: "Avenir Black, sans-serif",
                                  fontWeight: 600,
                                  color: "#FF5269",
                                  fontSize: 14,
                                }}
                              >
                                Sign Out
                              </MenuItem>
                            </MenuList>
                          </ClickAwayListener>
                        </Paper>
                      </Grow>
                    )}
                  </Popper>
                </div>
              </Box>
            </div>
          </Toolbar>
        </AppBar>
      </div>
      <Progress scroll={scrollPosition + "%"} />
    </>
  );
};
