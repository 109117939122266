import React, { FunctionComponent, useState, useEffect } from "react";
import {
  Grid,
  Box,
  Button,
  CircularProgress,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";
import { Formik, FieldArray } from "formik";
import * as Yup from "yup";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { fromEvent, Observable } from "rxjs";

import FacebookIcon from "static/media/fb-vector.png";
import TwitterIcon from "static/media/twitter-vector.png";
import InstagramIcon from "static/media/instagram-vector.png";
import TikTokIcon from "static/media/tiktok.png";
import YoutubeIcon from "static/media/youtube.png";
import SpotifyIcon from "static/media/spotify-logo.png";

import Name from "components/Common/Form/Name";
import Email from "components/Common/Form/Email";
import UploadAvatar from "components/Common/UploadAvatar/UploadAvatar";
import UploadImage from "components/Common/UploadImage/UploadImage";
import FileUpload from "components/Common/FileUpload";

import { useStyles } from "./styles";
import { IUser } from "interfaces/IUser";
import { UserService } from "services/UserService";
import { navigate } from "@reach/router";

import ToggleActivate from "components/ToggleActivate";
import { deleteEmail } from "./helpers/secondaryDelete";
import { objectInArray } from "../../utils/arrayCheck";
import { sliceToBackslash } from "../../utils/stringFormat";
import { useUser } from "UserContext";
import { useUnsaved } from "LinkContext";
import { ReactComponent as MediaLink } from "../../static/media/mediaLink.svg";
import { firebase } from "../../firebase-tools/firebase";
import FileLink from "./FileLink";
import { SendButton, CancelButton } from "components/styled/Common";
import ClientInfoStyled from "components/styled/ClientInfo";
import { InviteLink } from "./InviteLink";
import { warning } from "utils/notification";
import { Toast } from "components/styled/Common";
import { cancelReloadTabClose } from "utils/navigate";
import { checkChange } from "./helpers/checkChange";
import { handleCreateEdit } from "./helpers/createEdit";
import SocialMediaField from "./SocialMediaField";
import Deduplicate from "./Deduplicate";
import HistoricalData from "./HistoricalData";

type ClientInfoType = {
  userInfo: IUser;
  create?: boolean;
};

const updateLastSelection = (user: any, authId: string) => {
  const User = new UserService(authId);
  User.updateUser({
    lastClientSelection: user,
  }).catch((err) => console.log("lastSelection Error", err));
};

export const ClientInfoCreate: FunctionComponent<ClientInfoType> = ({
  userInfo,
  create,
}) => {
  const [error, setError] = useState("");
  const [isSaving, setIsSaving] = useState(false);
  const [isInviting, setIsInviting] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const classes = useStyles({});
  const [secEmails, setEmails] = useState(userInfo?.secondaryEmails || []);
  const [toNotify, setToNotify] = useState(true);
  const [secondaryUserExist, setSecondaryUserExist] = useState("");
  const [avatar, setAvatar]: any = useState("");
  const [requestType, setRequest] = useState("save");
  const [dupError, setDuplicate] = useState("");
  const [authUser] = useUser() as Array<IUser>;
  const [dataErrors, setDataErrors]: any = useState({
    igError: userInfo?.instagram?.error || "",
    fbError: userInfo?.facebook?.error || "",
    twError: userInfo?.twitter?.error || "",
  });
  const [retry, setRetry] = useState(false);
  const [imageProgress, setImageProgress] = useState(0);
  const [eventsUploadError, setEventsError] = useState("");
  const [notesSaving, setNotesSaving] = useState(false);
  const [lastValue, setLastValue] = useState(userInfo?.notes || "");
  const [toSave, setToSave] = useState(false);
  const [, setUnsaved] = useUnsaved() as any;

  const [mobileMenu, switchMobileMenu] = useState(
    !(document.documentElement.offsetWidth > 500)
  );

  const resize$: Observable<Event> = fromEvent(window, "resize");

  resize$.subscribe((res: any) => {
    if (res && res.currentTarget) {
      switchMobileMenu(!(res.currentTarget.innerWidth > 500));
    }
  });

  const User = new UserService(userInfo.id);

  const arrayObjectCount = (array, object: any, field: string) => {
    return array.filter((item) => item[field] === object[field]).length;
  };

  const cancel = async () => {
    navigate("/admin-panel");
    if (create) {
      if (avatar.includes("firebasestorage")) {
        const avatarRef = await firebase.storage().refFromURL(avatar);
        await avatarRef.delete();
      }
    }
    setUnsaved(false);
  };

  useEffect(() => {
    setDataErrors({
      igError: "",
      fbError: "",
      twError: "",
    });
    // setDataErrors({
    //   igError: userInfo?.instagram?.error,
    //   fbError: userInfo?.facebook?.error,
    //   twError: userInfo?.twitter?.error,
    // });
    if (userInfo.secondaryEmails) {
      if (secEmails.length !== userInfo.secondaryEmails.length) {
        toNotify && warning("Another user just saved changes to this page");
      }
    }
    setEmails(userInfo?.secondaryEmails || []);
    // eslint-disable-next-line
  }, [userInfo]);

  useEffect(() => {
    cancelReloadTabClose(toSave);
    setUnsaved(toSave);
    // eslint-disable-next-line
  }, [toSave]);

  return (
    <ClientInfoStyled>
      {toNotify && (
        <Toast>
          <ToastContainer progressClassName="toast-progressbar" />
        </Toast>
      )}
      <Formik
        enableReinitialize={true}
        initialValues={{
          clientName: !userInfo.firstName
            ? ""
            : `${userInfo?.firstName} ${userInfo?.lastName}`,
          email: userInfo ? userInfo?.email : "",
          facebook_username: create ? "" : userInfo?.facebook?.username,
          instagram_username: create ? "" : userInfo?.instagram?.username,
          twitter_username: create ? "" : userInfo?.twitter?.username,
          tiktok_username: create ? "" : userInfo?.tiktok?.username || "",
          channel_id: create ? "" : userInfo?.youtube?.username || "",
          spotifyId: create ? "" : userInfo?.spotify?.username || "",
          appleMusicId: create ? "" : userInfo?.appleMusic?.username || "",
          secondaryEmails: secEmails,
          notes: userInfo?.notes || "",
          isExternal: userInfo?.isExternal || false,
        }}
        onSubmit={(values) => {
          const facebook: any = sliceToBackslash(values.facebook_username);
          values.facebook_username = facebook;
          const instagram: any = sliceToBackslash(values.instagram_username);
          values.instagram_username = instagram;
          const twitter: any = sliceToBackslash(values.twitter_username);
          values.twitter_username = twitter;
          const tiktok: any = sliceToBackslash(values.tiktok_username);
          values.tiktok_username = tiktok;
          const youtube: any = sliceToBackslash(values.channel_id, "youtube");
          values.channel_id = youtube;
          const spotify: any = sliceToBackslash(values.spotifyId, "spotify");
          values.spotifyId = spotify;
          const appleMusic: any = sliceToBackslash(values.appleMusicId);
          values.appleMusicId = appleMusic;
          handleCreateEdit(
            values,
            userInfo,
            create,
            dupError,
            requestType,
            avatar,
            error,
            setSecondaryUserExist,
            setIsInviting,
            setIsSaving,
            setError,
            retry,
            dataErrors,
            setDataErrors,
            setToSave,
            setToNotify
          );
        }}
        validationSchema={Yup.object().shape({
          clientName: Yup.string().required("Required!"),
          email: Yup.string().email("Invalid email!").required("Required!"),
          secondaryEmails: Yup.array().of(
            Yup.object().shape({
              email: Yup.string().email("Invalid email!").required("Required!"),
            })
          ),
        })}
      >
        {(props) => {
          const {
            values,
            touched,
            errors,
            handleChange,
            handleSubmit,
            setFieldValue,
            handleBlur,
          } = props;
          return (
            <form noValidate onSubmit={handleSubmit} className={classes.form}>
              <Grid item xs={12} className={classes.bar} container>
                <Box className={classes.title} component="p">
                  {create ? "Create " : "Edit "}
                  Client
                </Box>
                <Box className={classes.actions}>
                  <CancelButton
                    disableRipple
                    type="button"
                    style={{ marginRight: "30px" }}
                    classes={{
                      root: classes.button,
                    }}
                    onClick={cancel}
                  >
                    Cancel
                  </CancelButton>

                  {authUser.role !== "viewerAdmin" && (
                    <>
                      <SendButton
                        type="submit"
                        variant="contained"
                        disabled={isInviting}
                        classes={{
                          root: classes.button,
                        }}
                        style={{ marginRight: "30px" }}
                        onClick={() => setRequest("invite")}
                        disableRipple
                      >
                        {!isInviting && "Send Invites"}
                        {isInviting && (
                          <CircularProgress size={30} color="inherit" />
                        )}
                      </SendButton>
                      <SendButton
                        type="submit"
                        variant="contained"
                        disabled={isSaving}
                        classes={{
                          root: classes.button,
                        }}
                        onClick={() => setRequest("save")}
                        disableRipple
                      >
                        {!isSaving && "Save Changes"}
                        {isSaving && (
                          <CircularProgress size={30} color="inherit" />
                        )}
                      </SendButton>
                    </>
                  )}
                </Box>
              </Grid>
              <Grid item container xs={12} style={{ height: "100%" }}>
                <Grid
                  item
                  container
                  lg={5}
                  md={12}
                  xs={12}
                  justify="flex-start"
                  alignItems="flex-start"
                  className={classes.client}
                  style={{ height: "fit-content" }}
                >
                  <Box component="p" width={1} className={classes.subTitle}>
                    Client's Info
                  </Box>
                  <Grid item xs={12} className={classes.avatarBox}>
                    <Box width={1}>
                      {!create ? (
                        <UploadAvatar
                          uid={userInfo.id}
                          avatarUrl={userInfo.avatarUrl}
                          content="Avatar"
                        />
                      ) : isUploading ? (
                        <CircularProgress
                          size={30}
                          color="inherit"
                          variant="determinate"
                          value={imageProgress}
                        />
                      ) : (
                        // user images should not be uploaded to post_images/. we need to find a way to fix this
                        <UploadImage
                          image={avatar}
                          setImage={setAvatar}
                          avatar={create}
                          isUploading={isUploading}
                          setIsUploading={setIsUploading}
                          setProgress={setImageProgress}
                          content="Avatar"
                        />
                      )}
                    </Box>
                  </Grid>
                  <Grid item xs={12} style={{ height: "92px" }}>
                    <p
                      className={classes.inputLabel}
                      style={{ color: "#000000" }}
                    >
                      Client Name
                    </p>
                    <Name
                      name="clientName"
                      color="#000000"
                      label=""
                      variant="outlined"
                      width="100%"
                      value={values.clientName}
                      placeholder="Enter Client Name"
                      error={
                        errors.clientName &&
                        touched.clientName &&
                        !values.clientName
                      }
                      iconWidth="24px"
                      onChangeHandler={(e) => {
                        checkChange(e, "name", userInfo, setToSave);
                        handleChange(e);
                      }}
                      borderColor={
                        errors.clientName && touched.clientName
                          ? "#f44336"
                          : "#000"
                      }
                      helperText={
                        errors.clientName &&
                        touched.clientName &&
                        errors.clientName
                      }
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    style={{
                      marginBottom: 30,
                      marginTop: errors.clientName ? "2px" : "-12px",
                    }}
                  >
                    <FormControlLabel
                      name="isExternal"
                      control={
                        <Checkbox
                          checked={values.isExternal}
                          disableRipple
                          style={{
                            color: "#000",
                          }}
                          onChange={handleChange}
                          inputProps={{
                            "aria-label": "External client",
                          }}
                        />
                      }
                      label={
                        <span className={classes.checkboxLabel}>
                          External client (not displayed in Admin side)
                        </span>
                      }
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    style={{
                      marginTop: "unset",
                      height: "114px",
                      marginBottom: 10,
                    }}
                  >
                    <p className={classes.inputLabel}>Primary Email</p>

                    <Email
                      color="#000000"
                      marginLeft="unset"
                      value={values.email}
                      label=""
                      width="100%"
                      variant="outlined"
                      error={errors.email && touched.email}
                      placeholder="client@email.com"
                      onChangeHandler={handleChange}
                      iconWidth="24px"
                      borderColor={
                        errors.email && touched.email ? "#f44336" : "#000"
                      }
                      helperText={errors.email && touched.email && errors.email}
                      disabled={create ? false : true}
                      backgroundColor={create ? "#fff" : "#f2f2f2"}
                    />

                    {userInfo.email && (
                      <InviteLink
                        classes={classes}
                        primaryEmail={values.email}
                        isSignedUp={userInfo.isSignedUp}
                        type="primary"
                      />
                    )}
                    <p
                      className={classes.error}
                      style={{
                        marginTop: 2,
                      }}
                    >
                      {error}
                    </p>
                  </Grid>
                  <Grid item xs={12} style={{ marginTop: "unset" }}>
                    <p className={classes.inputLabel}>
                      Other Emails (that have access)
                    </p>
                    <FieldArray
                      name="secondaryEmails"
                      render={(arrayHelpers) => (
                        <>
                          {values.secondaryEmails?.map(
                            (emailObject: any, index: number) => (
                              <>
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "flex-start",
                                    maxWidth: "450px",
                                  }}
                                  key={"secondary" + index}
                                >
                                  <Email
                                    name={`secondaryEmails.${index}.email`}
                                    color="#000000"
                                    marginLeft="unset"
                                    value={emailObject.email}
                                    label=""
                                    width="100%"
                                    variant="outlined"
                                    handleBlur={handleBlur}
                                    disabled={
                                      emailObject.isInvited ? true : false
                                    }
                                    backgroundColor={
                                      emailObject.isInvited ? "#f2f2f2" : "#fff"
                                    }
                                    error={
                                      ((errors.secondaryEmails
                                        ? errors.secondaryEmails[index] &&
                                          errors.secondaryEmails[index].email
                                        : false) &&
                                        (touched.secondaryEmails
                                          ? touched.secondaryEmails[index] &&
                                            (touched.secondaryEmails[index]
                                              .email ||
                                              touched.secondaryEmails[index])
                                          : false)) ||
                                      (dupError !== "" &&
                                        emailObject.email === dupError &&
                                        !emailObject.isInvited)
                                    }
                                    placeholder="client@email.com"
                                    onChangeHandler={(e: any) => {
                                      setFieldValue(
                                        `secondaryEmails.${index}`,
                                        {
                                          email: e.target.value,
                                          isActivated: true,
                                          isInvited: false,
                                          isSignedUp: false,
                                        }
                                      );
                                      if (
                                        arrayObjectCount(
                                          values.secondaryEmails,
                                          { email: e.target.value },
                                          "email"
                                        ) > 0 ||
                                        e.target.value.toLowerCase() ===
                                          values.email
                                      ) {
                                        setDuplicate(e.target.value);
                                      } else {
                                        if (
                                          touched.secondaryEmails &&
                                          touched.secondaryEmails[index] &&
                                          touched.secondaryEmails[index].email
                                        ) {
                                          setDuplicate("");
                                        }
                                      }
                                      e.target.value.length > 0 &&
                                        setToSave(true);
                                    }}
                                    borderColor={
                                      ((errors.secondaryEmails
                                        ? errors.secondaryEmails[index] &&
                                          errors.secondaryEmails[index].email
                                        : false) &&
                                        (touched.secondaryEmails
                                          ? touched.secondaryEmails[index] &&
                                            (touched.secondaryEmails[index]
                                              .email ||
                                              touched.secondaryEmails[index])
                                          : false)) ||
                                      (dupError !== "" &&
                                        emailObject.email === dupError &&
                                        !emailObject.isInvited)
                                        ? "#f44336"
                                        : "#000"
                                    }
                                    helperText={
                                      ((errors.secondaryEmails
                                        ? errors.secondaryEmails[index] &&
                                          errors.secondaryEmails[index].email
                                        : false) &&
                                        (touched.secondaryEmails
                                          ? touched.secondaryEmails[index] &&
                                            (touched.secondaryEmails[index]
                                              .email ||
                                              touched.secondaryEmails[index])
                                          : false) &&
                                        (errors.secondaryEmails
                                          ? errors.secondaryEmails[index] &&
                                            errors.secondaryEmails[index].email
                                          : false)) ||
                                      (emailObject.email === dupError &&
                                      !emailObject.isInvited
                                        ? "Duplicated email"
                                        : "")
                                    }
                                  />
                                  <span
                                    style={{
                                      marginTop: "20px",
                                    }}
                                  >
                                    {emailObject.isInvited ? (
                                      <span style={{ marginLeft: "15px" }}>
                                        <ToggleActivate
                                          user={userInfo}
                                          isActivated={emailObject.isActivated}
                                          type="email"
                                          email={emailObject.email}
                                          setEmails={setEmails}
                                        />
                                      </span>
                                    ) : (
                                      <Button
                                        disableRipple
                                        style={{
                                          color: "#FF5269",
                                          minWidth: "20px",
                                          marginLeft: "12px",
                                        }}
                                        onClick={() => {
                                          arrayHelpers.remove(index);
                                          setToNotify(false);
                                          setSecondaryUserExist("");
                                          if (
                                            !create &&
                                            objectInArray(
                                              userInfo.secondaryEmails,
                                              emailObject,
                                              "email"
                                            ) &&
                                            dupError !== emailObject.email
                                          ) {
                                            deleteEmail(
                                              User,
                                              userInfo,
                                              emailObject.email
                                            );
                                          }
                                          if (
                                            touched.secondaryEmails &&
                                            touched.secondaryEmails[index] &&
                                            touched.secondaryEmails[index].email
                                          ) {
                                            setDuplicate("");
                                          }
                                          setToSave(false);
                                        }}
                                      >
                                        <CancelIcon />
                                      </Button>
                                    )}
                                  </span>
                                </div>

                                {(emailObject.isInvited ||
                                  emailObject.isSignedUp) && (
                                  <InviteLink
                                    classes={classes}
                                    primaryEmail={values.email}
                                    secEmail={emailObject.email}
                                    isSignedUp={emailObject.isSignedUp}
                                  />
                                )}
                              </>
                            )
                          )}
                          {secondaryUserExist && (
                            <p style={{ color: "#f44345" }}>
                              {secondaryUserExist}
                            </p>
                          )}
                          {authUser.role !== "viewerAdmin" && (
                            <SendButton
                              disableRipple
                              variant="contained"
                              classes={{
                                root: classes.addButton,
                              }}
                              onClick={() => {
                                arrayHelpers.push("");
                              }}
                              style={{
                                margin: "22px 0px 12px 0px",
                              }}
                              disabled={dupError !== ""}
                            >
                              Add Email
                            </SendButton>
                          )}
                        </>
                      )}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12} lg={2}></Grid>
                <Grid
                  item
                  container
                  lg={5}
                  md={12}
                  xs={12}
                  className="socialMedia"
                >
                  <Grid item container xs={12} style={{ maxWidth: 450 }}>
                    <Box component="p" width={1} className={classes.subTitle}>
                      Social Media Accounts
                    </Box>

                    <SocialMediaField
                      value={{
                        instagram: values.instagram_username,
                        facebook: values.facebook_username,
                      }}
                      touched={touched}
                      errors={errors}
                      userInfo={userInfo}
                      mediaName="instagram"
                      link="https://instagram.com/"
                      name="instagram_username"
                      placeholder="instagram-username"
                      dataError={dataErrors.igError}
                      MediaIcon={InstagramIcon}
                      checkChange={checkChange}
                      handleChange={handleChange}
                      setRetry={setRetry}
                      setToSave={setToSave}
                      mobileMenu={mobileMenu}
                    />
                    <SocialMediaField
                      value={values.facebook_username}
                      touched={touched}
                      errors={errors}
                      userInfo={userInfo}
                      mediaName="facebook"
                      link="https://facebook.com/"
                      name="facebook_username"
                      placeholder="facebook-username"
                      dataError={dataErrors.fbError}
                      MediaIcon={FacebookIcon}
                      checkChange={checkChange}
                      handleChange={handleChange}
                      setRetry={setRetry}
                      setToSave={setToSave}
                      mobileMenu={mobileMenu}
                    />

                    <SocialMediaField
                      value={values.twitter_username}
                      touched={touched}
                      errors={errors}
                      userInfo={userInfo}
                      mediaName="twitter"
                      link="https://twitter.com/"
                      name="twitter_username"
                      placeholder="twitter-username"
                      dataError={dataErrors.twError}
                      MediaIcon={TwitterIcon}
                      checkChange={checkChange}
                      handleChange={handleChange}
                      setRetry={setRetry}
                      setToSave={setToSave}
                      mobileMenu={mobileMenu}
                    />
                    <SocialMediaField
                      value={values.tiktok_username}
                      touched={touched}
                      errors={errors}
                      userInfo={userInfo}
                      mediaName="tiktok"
                      link="https://tiktok.com/@"
                      name="tiktok_username"
                      placeholder="tiktok-username"
                      MediaIcon={TikTokIcon}
                      checkChange={checkChange}
                      handleChange={handleChange}
                      setRetry={setRetry}
                      setToSave={setToSave}
                      mobileMenu={mobileMenu}
                    />
                    <SocialMediaField
                      value={values.channel_id}
                      touched={touched}
                      errors={errors}
                      userInfo={userInfo}
                      mediaName="youtube"
                      link="https://youtube.com/channel/"
                      name="channel_id"
                      placeholder="Channel-id"
                      MediaIcon={YoutubeIcon}
                      checkChange={checkChange}
                      handleChange={handleChange}
                      setRetry={setRetry}
                      setToSave={setToSave}
                      mobileMenu={mobileMenu}
                    />
                    <SocialMediaField
                      value={values.spotifyId}
                      touched={touched}
                      errors={errors}
                      userInfo={userInfo}
                      mediaName="spotify"
                      link="https://artists.spotify.com/c/artist/"
                      name="spotifyId"
                      placeholder="spotify-id"
                      MediaIcon={SpotifyIcon}
                      checkChange={checkChange}
                      handleChange={handleChange}
                      setRetry={setRetry}
                      setToSave={setToSave}
                      mobileMenu={mobileMenu}
                    />

                    {/* Hide for now */}
                    {/* <Grid item container xs={12} style={{ marginTop: "unset" }}> */}
                    {/* <Box
                        component="p"
                        width={1}
                        className={classes.socialIcon}
                      >
                        <Grid className={classes.socialTitle}>
                          <span className={classes.socialLabels}>
                            <img
                              className={classes.img}
                              src={AppleMusicIcon}
                              alt="AppleMusic-icon"
                            />
                            Apple Music
                          </span>
                          <span className={classes.flex}>
                            <span
                              className={classes.error}
                              style={{ marginRight: "3px" }}
                            >
                              {dataErrors.appleMusicError}
                            </span> */}
                    {/* {permissionMessages.appleMusic !== "" && (
                              <span
                                className={classes.error}
                                style={{ color: "#000" }}
                              >
                                {permissionMessages.appleMusic}
                              </span>
                            )} */}
                    {/* {values.appleMusicId !== "" && (
                              <span
                                className={classes.retry}
                                onClick={async () => {
                                  setPermType("appleMusic");
                                  await permissions(
                                    "appleMusic",
                                    values.appleMusicId
                                  );
                                }}
                              >
                                {permissionLoading &&
                                permType === "appleMusic" ? (
                                  <CircularProgress size={30} color="inherit" />
                                ) : (
                                  "Test permissions"
                                )}
                              </span>
                            )} */}
                    {/* <Tooltip title={tooltips.appleMusic} />
                          </span>
                        </Grid>
                      </Box> */}

                    {/* <Name
                        name="appleMusicId"
                        label=""
                        variant="outlined"
                        marginTop="unset"
                        width="100%"
                        icon={false}
                        inputSpan="https://artists.apple.com/a/artist/"
                        spanMargin="0"
                        placeholder="apple-music-id"
                        value={values.appleMusicId}
                        error={errors.appleMusicId && touched.appleMusicId}
                        onChangeHandler={(e) => {
                          checkChange(e, "tiktok", userInfo, setToSave);
                          handleChange(e);
                        }}
                        borderColor={
                          errors.appleMusicId && touched.appleMusicId
                            ? "#f44336"
                            : "#000"
                        }
                        helperText={
                          errors.appleMusicId &&
                          touched.appleMusicId &&
                          errors.appleMusicId
                        }
                      />
                    </Grid> */}
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                item
                xs={12}
                className={classes.bar}
                container
                style={{ paddingBottom: 12 }}
              >
                <p className={classes.subTitle}>Be-Hookd Notes</p>
                <textarea
                  placeholder={
                    mobileMenu
                      ? "Save notes that can be useful for the team..."
                      : "Save notes that can be useful for the team in this box..."
                  }
                  className={classes.notes}
                  onChange={(e) => {
                    checkChange(e, "notes", userInfo, setToSave);
                    handleChange(e);
                  }}
                  name="notes"
                  value={values.notes}
                  disabled={notesSaving}
                  onBlur={async (e) => {
                    const val = e.target.value;
                    if (val !== lastValue) {
                      setNotesSaving(true);
                      try {
                        await User.updateUser({ notes: values.notes });
                        setLastValue(val);
                        setNotesSaving(false);
                      } catch (err) {
                        setNotesSaving(false);
                        console.error("we had a save error");
                      }
                    }
                  }}
                ></textarea>
                <p className={classes.notesDisclaimer}>
                  Changes in the notes are {mobileMenu ? "" : "automatically"}{" "}
                  saved when you exit the text area
                </p>
              </Grid>
              <Grid
                item
                container
                xs={12}
                className={classes.navigate}
                style={{
                  marginTop: "-45px",
                  marginBottom: 70,
                  gridGap: 0,
                  gridAutoFlow: "column",
                  width: "100%",
                }}
              >
                <Grid>
                  <p className={classes.subTitle}>Quick Access</p>
                  <Grid
                    container
                    style={{
                      alignItems: "center",
                      marginTop: "10px",
                    }}
                  >
                    <Grid xs={12} md={2}>
                      <a
                        className={classes.navigateLink}
                        onClick={() =>
                          updateLastSelection(userInfo, authUser.id)
                        }
                        href={"/be-measured"}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ marginTop: 0 }}
                      >
                        <MediaLink className={classes.linkIcon} />
                        View Be-Measured
                      </a>
                      <a
                        className={classes.navigateLink}
                        onClick={() =>
                          updateLastSelection(userInfo, authUser.id)
                        }
                        href={"/content-plan-feed"}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <MediaLink className={classes.linkIcon} />
                        View Be-Planned
                      </a>
                    </Grid>
                    <Grid
                      xs={12}
                      md={2}
                      style={{ display: "flex", justifyContent: "flex-start" }}
                    >
                      <Deduplicate user={userInfo} />
                    </Grid>
                    <Grid
                      xs={12}
                      md={2}
                      style={{ display: "flex", justifyContent: "flex-start" }}
                    >
                      <HistoricalData user={userInfo} />
                    </Grid>
                    <Grid xs={12} md={2}>
                      {authUser.role !== "viewerAdmin" && (
                        <div className="bePlannedContainer">
                          <Grid className={"navigate"}>
                            <p className={classes.subTitle}>
                              Be-Planned Calendar
                            </p>
                            <div style={{ marginBottom: "6px" }}>
                              <FileUpload
                                isUploading={isUploading}
                                setIsUploading={setIsUploading}
                                title="Add .CSV File"
                                user={userInfo}
                                setError={setEventsError}
                                accept=".csv"
                              />
                            </div>
                            {eventsUploadError && (
                              <p
                                style={{
                                  color: "#f44336",
                                  fontSize: "13px",
                                  marginTop: "-13px",
                                }}
                              >
                                {eventsUploadError}
                              </p>
                            )}
                            <FileLink
                              link={
                                process.env.REACT_APP_BE_PLANNED_EVENT_TEMPLATE
                              }
                              key={
                                process.env.REACT_APP_BE_PLANNED_EVENT_TEMPLATE
                              }
                            />
                            {userInfo.events?.map((event, i) => {
                              return (
                                <FileLink
                                  link={event}
                                  user={userInfo}
                                  key={event}
                                />
                              );
                            })}
                          </Grid>
                        </div>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          );
        }}
      </Formik>
    </ClientInfoStyled>
  );
};
