import React, { FC, useState } from "react";

import SocialPost from "./PostV2";
import { NoPosts } from "./PostV2/NoPosts";
import { ReactComponent as CheckedIcon } from "static/media/checkedicon2.svg";
import { ReactComponent as UncheckedIcon } from "static/media/uncheckicon2.svg";
import { Checkbox, makeStyles } from "@material-ui/core";
import Event from "./EventV2/Event";
import moment from "moment";

interface IBePlanned {
  posts: () => any[];
  events?: () => any[];
  user: any;
  postIds: any[];
  setPostIds: (date: any) => void;
  checkAll: Boolean;
  uncheckAll: Boolean;
  eventIds?: any[];
  setEventIds?: (eventId: any) => void;
  sortDirection?: string;
}

const useStyles2 = makeStyles((theme) => ({
  visibleGrid: {
    display: "flex",
  },
  hiddenGrid: {
    display: "none",
  },

  dayCheckbox: {
    fontFamily: "Avenir",
    fontSize: "20px",
    fontWeight: 900,
    "@media screen and (min-width: 1000px)": {
      paddingLeft: "23px",
    },
  },
}));

export const BePlannedList: FC<IBePlanned> = ({
  posts,
  events,
  user,
  postIds,
  setPostIds,
  checkAll,
  uncheckAll,
  setEventIds,
  eventIds,
  sortDirection,
}) => {
  const allPosts = posts().map((val) => {
    return {
      ...val,
      dataType: "post",
    };
  });
  const allEvents =
    events !== undefined
      ? events()?.map((val) => {
          return {
            ...val,
            dataType: "event",
          };
        })
      : [];

  const classes = useStyles2();

  const groupPostsByDay = (items: any[]) => {
    const groupedPosts: { [key: string]: any[] } = {};
    if (items?.length > 0) {
      items.forEach((post) => {
        let postDate;
        if (post?.dataType === "event") {
          // ("post.new_start_date", post.new_start_date);
          postDate = moment.unix(post.new_start_date.seconds);
          // postDate = moment.unix(post.start_date.seconds);
        } else {
          postDate = moment.unix(post.date.seconds);
        }
        const formattedDate = postDate.format("YYYY-MM-DD");

        if (!groupedPosts[formattedDate]) {
          groupedPosts[formattedDate] = [];
        }

        groupedPosts[formattedDate].push(post);
      });
    }
    return groupedPosts;
  };

  const sortedArray = Object.keys(
    groupPostsByDay([...(allPosts ?? []), ...(allEvents ?? [])])
  )
    // .sort((a, b) => {
    //   const dateA = moment(a, "YYYY-MM-DD");
    //   const dateB = moment(b, "YYYY-MM-DD");
    //   if (sortDirection === "asc") {
    //     return dateA.isBefore(dateB) ? -1 : dateA.isAfter(dateB) ? 1 : 0;
    //   }
    //   return dateB.isBefore(dateA) ? -1 : dateB.isAfter(dateA) ? 1 : 0;
    // })
    .reduce((obj, key) => {
      obj[key] = groupPostsByDay([...(allPosts ?? []), ...(allEvents ?? [])])[
        key
      ];
      return obj;
    }, {});

  const groupedPosts = groupPostsByDay([
    ...(allPosts ?? []),
    ...(allEvents ?? []),
  ]);

  const [formData, setFormData] = useState(() => {
    const initialCheckboxState = {};
    Object.keys(groupedPosts).forEach((day) => {
      initialCheckboxState[day] = false;
    });
    return initialCheckboxState;
  });

  const formatDate = (inputDate) => {
    const dateParts = inputDate.split("-");
    const year = dateParts[0];
    const month = dateParts[1];
    const day = dateParts[2];

    const formattedDate = new Date(`${month}/${day}/${year}`);

    // Formatting options for the date
    const options: any = {
      day: "numeric",
      month: "short",
      year: "numeric",
    };

    return formattedDate.toLocaleDateString("en-GB", options);
  };
  const [dailyCheck, setDailyCheck] = useState(false);

  const handleCheck = (e, day) => {
    const { checked } = e.target;
    const currentIds = checked ? groupedPosts[day].map((post) => post.id) : [];
    const currentEvIds: any[] = [];
    if (checked) {
      groupedPosts[day].forEach((post) => {
        if (post.dataType === "event") {
          currentEvIds.push(post.id);
        }
      });
    }

    setPostIds(currentIds);
    if (setEventIds) {
      setEventIds(currentEvIds);
    }
    setFormData({
      ...formData,
      [day]: checked,
    });
    setDailyCheck(checked);
  };

  return (
    <div
      style={{
        marginBottom: "50px",
      }}
    >
      {/* Posts */}
      {Object.keys(sortedArray).length ? (
        Object.keys(sortedArray).map((day) => (
          <div key={day}>
            <span>
              {" "}
              <Checkbox
                checked={formData[day]}
                style={{ color: "#000", borderRadius: "1px" }}
                icon={<UncheckedIcon />} // SVG icon for unchecked state
                checkedIcon={<CheckedIcon />} // SVG icon for checked state
                onChange={(e) => handleCheck(e, day)}
                value={day}
              />
            </span>
            <span className={classes.dayCheckbox}>{formatDate(day)}</span>
            {sortedArray[day].map((post, index) => (
              <div key={index}>
                {post?.dataType === "post" && (
                  <SocialPost
                    post={post}
                    user={user}
                    key={index}
                    role="multichannel"
                    postIds={postIds}
                    setPostIds={setPostIds}
                    checkAll={checkAll}
                    uncheckAll={uncheckAll}
                    uuid={post.uuid}
                    dailyCheck={dailyCheck}
                  />
                )}
                {post?.dataType === "event" && (
                  <Event
                    event={post}
                    user={user}
                    key={index}
                    role="multichannel"
                    uuid={post.uuid}
                    dailyCheck={dailyCheck}
                    checkAll={checkAll}
                    eventIds={eventIds as any}
                    setEventIds={setEventIds as any}
                    uncheckAll={uncheckAll}
                  />
                )}

                <hr
                  style={{
                    marginTop: 20,
                    color: "#ABADAD",
                    border: "1px solid #ABADAD",
                  }}
                />
              </div>
            ))}
          </div>
        ))
      ) : (
        <NoPosts title="There are no posts in the selected period" />
      )}
    </div>
  );
};
