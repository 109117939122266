import React, { FC, useState, useEffect } from "react";
import {
  Tooltip,
  Modal,
  Backdrop,
  Fade,
  CircularProgress,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { ReactComponent as FilterIcon } from "static/media/filter.svg";

import { useStyles } from "./styles";
import { SendButton, CancelButton, PostPaper } from "components/styled/Common";
import MultiSelectCollapsible from "components/Common/MultiSelectCollapsible";
import preventScroll from "utils/preventScroll";

interface IFilter {
  setMonthlyCategory: (val: any) => void;
  setWeeklyCategory: (val: any) => void;
}

const FilterV2: FC<IFilter> = ({ setMonthlyCategory, setWeeklyCategory }) => {
  const classes = useStyles({});
  const [isOpen, setIsOpen] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const [monthlySelected, setMonthlySelected] = useState([]);
  const [weeklySelected, setWeeklySelected] = useState([]);
  const [windowOffset, setWindowOffset] = useState(window.scrollY);

  // const monthlyStatuses = [
  //   { id: 1, name: "Draft" },
  //   { id: 2, name: "Pending Approval" },
  //   { id: 3, name: "Client Approved" },
  // ];

  const weeklyStatuses = [
    { id: 2, name: "Pending Client Approval" },
    { id: 5, name: "Partially Approved by Client" },
    { id: 3, name: "Client Declined" },
    { id: 4, name: "Client Approved" },
    { id: 1, name: "Draft" },
  ];

  useEffect(() => {
    preventScroll(isOpen, windowOffset, setWindowOffset);
    // eslint-disable-next-line
  }, [isOpen]);

  const close = () => {
    setIsOpen(false);
  };
  const handleClose = (e) => {
    if (e.key === "Escape") {
      handleFilter();
    }
  };

  const reset = () => {
    setSubmitting(true);
    setMonthlyCategory([]);
    setWeeklyCategory([]);
    setMonthlySelected([]);
    setWeeklySelected([]);
    setSubmitting(false);

    close();
  };

  const handleFilter = () => {
    setMonthlyCategory(monthlySelected);
    setWeeklyCategory(weeklySelected);
    close();
  };

  return (
    <div id="filter">
      <Tooltip
        title="Filter"
        arrow
        interactive
        classes={{ tooltip: classes.info, arrow: classes.arrow }}
      >
        <FilterIcon
          style={{ marginBottom: -4, cursor: "pointer" }}
          onClick={() => setIsOpen(true)}
        />
      </Tooltip>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={isOpen}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={isOpen}>
          <div className={classes.container} id="edit-create">
            <PostPaper>
              <div className={classes.top}>
                <div className={classes.print}>Filters</div>
                <Close className={classes.icon} onClick={close} />
              </div>
              <div className={classes.postSection} id="post-section">
                <div className={classes.field}>
                  {/* <div className={classes.choose} style={{ border: "none" }}>
                    <MultiSelectCollapsible
                      status="Monthly Statuses"
                      selected={monthlySelected}
                      setSelected={setMonthlySelected}
                      options={monthlyStatuses}
                    />
                  </div> */}
                  <div className={classes.choose} style={{ border: "none" }}>
                    <MultiSelectCollapsible
                      status="Post Approval"
                      selected={weeklySelected}
                      setSelected={setWeeklySelected}
                      options={weeklyStatuses}
                    />
                  </div>
                </div>
              </div>
            </PostPaper>
            <div className={classes.line} />
            <div className={classes.bottom}>
              <CancelButton disableRipple onClick={reset}>
                Reset
              </CancelButton>
              <div style={{ display: "flex", alignItems: "center" }}>
                <SendButton
                  onClick={handleFilter}
                  disabled={submitting}
                  disableRipple
                  style={{ marginBottom: 0 }}
                >
                  {submitting ? (
                    <CircularProgress size={30} color="inherit" />
                  ) : (
                    <>Save</>
                  )}
                </SendButton>
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

export default FilterV2;
