import React, { FC, useEffect, useState } from "react";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Table from "@material-ui/core/Table";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import Paper from "@material-ui/core/Paper";
import { navigate } from "@reach/router";
// import history from "history/browser";

import { PageType } from "types/PageType";
import PageTitle from "components/Common/PageTitle";
import { useStyles, CTAContainer } from "./styles";
import { SendButton } from "components/styled/Common";
import CampaignTypes from "components/BeAmplified/Campaigns/Organic/Components/CampaignTypes";
import BeAmplified from "components/BeAmplified/Campaigns/Organic";
import { ClientList } from "components/BeMeasured/ClientList";
import { useUser } from "UserContext";
import { IUser } from "interfaces/IUser";
import { firestore } from "firebase-tools/firebase";
import useDocumentSubscriber from "hooks/useDocumentSubscriber";
import useCollectionSubscriber from "hooks/useCollectionSubscriber";
import { NoPosts } from "components/BePlanned/Post/NoPosts";
import { Modal } from "@material-ui/core";
import Archived from "components/BeAmplified/Campaigns/Organic/Components/Archived";
import CustomLoader from "components/CustomLoader";
import showToast from "utils/toast";
// import { FlowType } from "components/BeAmplified/Campaigns/Organic/Components/FlowTypes/FlowType";
import queries from "./queries";
import authService from "helpers/user/auth.service";

const BeAmplifiedPage: FC<PageType> = ({ location }) => {
  const classes = useStyles({});
  const [authUser, setAuthUser] = useUser() as [IUser, any];
  const [archivedOpen, setArchivedOpen] = useState(false);
  const [currentOption, setCurrentOption] = useState("Automated Flow");

  const search = location?.search;

  const {
    twitterQueries,
    instagramQueries,
    facebookQueries,
    facebookScrapperQueries,
    twitterScrappersQueries,
    instagramScrapperQueries,
  } = queries(authUser);

  useEffect(() => {
    if (search?.includes("scrappers")) {
      setCurrentOption("Scrappers");
    } else {
      setCurrentOption("Automated Flow");
    }
  }, [location, search]);

  const twitterCampaigns = useCollectionSubscriber<any[]>({
    queries: search?.includes("scrappers")
      ? twitterScrappersQueries
      : twitterQueries,
    deps: [authUser, search],
  });

  const instagramCampaigns = useCollectionSubscriber<any[]>({
    queries: search?.includes("scrappers")
      ? instagramScrapperQueries
      : instagramQueries,
    deps: [authUser, search],
  });
  const facebookCampaigns = useCollectionSubscriber<any[]>({
    queries: search?.includes("scrappers")
      ? facebookScrapperQueries
      : facebookQueries,
    deps: [authUser, search],
  });

  const [selected, setSelected] = useState({
    facebook: [],
    instagram: [],
    twitter: [],
  });
  const [selectedArr, setSelectedArr]: any = useState([]);

  const [, setClient]: any = useState("");
  const setClientSelection = (e) => {
    // const User = new UserService(authUser.id);
    return authService
      .updateUser(authUser.id, {
        lastClientSelection: e,
      })
      .then((res) => {
        setClient(e);
        setAuthUser((user) => ({ ...user, lastClientSelection: e }));
      })
      .catch((err) => {});
  };

  let userRef = firestore.collection("users").doc(authUser.id);
  let { document, loading } = useDocumentSubscriber({ ref: userRef });
  let client = document?.lastClientSelection || "";

  const processInstagramData = (data: any[]): any[] => {
    const instagramMap = new Map();
    instagramMap.set("Instagram Auto Followers", "autoFollow");
    instagramMap.set("Instagram Auto Liker", "igAutoLiker");
    instagramMap.set("Instagram Auto Commenter", "igAutoCommenter");
    const typesArray = selectedArr.map((typeLabel) => {
      // ({ typeLabel });
      return instagramMap.get(typeLabel);
    });
    return data
      .map((campaign) => ({
        ...campaign,
        posts: campaign.profiles?.length,
        followsCount: campaign.profiles?.filter((profile) => profile.following)
          .length,
      }))
      .filter((campaign) => {
        return selectedArr.length > 0
          ? typesArray.indexOf(campaign.campaignType) > -1
          : [""].indexOf(campaign.campaignType);
      });
    // .filter((campaign: any) => {
    //   if (currentOption.toLocaleLowerCase() === "scrappers") {
    //     return campaign?.campaignType?.includes("-scrapper");
    //   } else {
    //     return !campaign?.campaignType?.includes("-scrapper");
    //   }
    // });
  };

  const processTwitterData = (data: Array<any>): Array<any> => {
    const twitterMap = new Map();
    // twitterMap.set("Twitter Auto Followers", "autofollow");
    // twitterMap.set("Twitter Auto Poster", "autoPoster");
    // twitterMap.set("Twitter Auto Direct Message", "auto-dm");
    twitterMap.set("Twitter Auto Liker", "autoLiker");
    const typesArray = selectedArr.map((typeLabel) => {
      return twitterMap.get(typeLabel);
    });
    return data
      .map((campaign: any) => ({
        ...campaign,
        posts:
          campaign.campaignType === "autoPoster"
            ? campaign.posts?.length || 0
            : campaign.tweetsCount,
        postCount:
          campaign.campaignType === "autoPoster"
            ? campaign.posts?.filter((post: any) => post.sent)?.length || 0
            : 0,
      }))
      .filter((campaign) => {
        return selectedArr.length > 0
          ? typesArray.indexOf(campaign.campaignType) > -1
          : [""].indexOf(campaign.campaignType);
      })
      .filter(
        (campaign) =>
          campaign.username === client.twitter?.username ||
          (!campaign.username && client.firstName === "1test")
      );
    // .filter((campaign: any) => {
    //   if (currentOption.toLocaleLowerCase() === "scrappers") {
    //     return campaign?.campaignType?.includes("-scrapper");
    //   } else {
    //     return !campaign?.campaignType?.includes("-scrapper");
    //   }
    // });
  };

  const processFacebookData = (data: Array<any>): Array<any> => {
    const facebookMap = new Map();
    // facebookMap.set("Facebook Auto Liker", "fbAutoLiker");
    facebookMap.set("Facebook Group Scrapper", "groupScrapper");
    facebookMap.set("Facebook Auto Direct Message", "auto-dm");
    const typesArray = selectedArr.map((typeLabel) => {
      return facebookMap.get(typeLabel);
    });
    return data
      .filter((campaign) => {
        return selectedArr.length > 0
          ? typesArray.indexOf(campaign.campaignType) > -1
          : [""].indexOf(campaign.campaignType);
      })
      .filter((campaign) => campaign.username === client.facebook?.username)
      .map((campaign) => ({
        ...campaign,
        posts: campaign.profiles?.length || 0,
        followedProfilesCount:
          campaign.profiles?.filter((profile) => profile.sent)?.length || 0,
      }));
    // .filter((campaign: any) => {
    //   if (currentOption.toLocaleLowerCase() === "scrappers") {
    //     return campaign?.campaignType?.includes("-scrapper");
    //   } else {
    //     return !campaign?.campaignType?.includes("-scrapper");
    //   }
    // });
  };

  const twitterActiveCampaings = twitterCampaigns.documents
    .filter((campaign: any) => !campaign.archived)
    .map((camp: any) => {
      camp.platform = "twitter";
      return camp;
    });
  const instagramActiveCampaigns = instagramCampaigns.documents
    .filter((campaign: any) => !campaign.archived)
    .map((camp: any) => {
      camp.platform = "instagram";
      return camp;
    });
  const facebookActiveCampaigns = facebookCampaigns.documents
    .filter((campaign: any) => !campaign.archived)
    .map((camp: any) => {
      camp.platform = "facebook";
      return camp;
    });

  let twitterFilteredData = processTwitterData(twitterActiveCampaings);
  let instagramFilteredData = processInstagramData(instagramActiveCampaigns);
  let facebookFilteredData = processFacebookData(facebookActiveCampaigns);
  let allCampaigns = [
    ...twitterActiveCampaings,
    ...instagramActiveCampaigns,
    ...facebookFilteredData,
  ];

  useEffect(() => {
    // eslint-disable-next-line
    twitterFilteredData = processTwitterData(twitterActiveCampaings);
    // eslint-disable-next-line
    instagramFilteredData = processInstagramData(instagramActiveCampaigns);
    // eslint-disable-next-line
    facebookFilteredData = processFacebookData(facebookActiveCampaigns);
    // eslint-disable-next-line
    allCampaigns = [
      ...twitterActiveCampaings,
      ...instagramActiveCampaigns,
      ...facebookFilteredData,
    ];
  }, [currentOption]);

  const onClickCreateNewCampaign = () => {
    if (client) {
      if (currentOption === "Scrappers")
        return navigate("/campaigns/scrappers/create");

      navigate("/campaigns/organic/create");
    } else {
      showToast(`Please Choose Client.`);
    }
  };

  return (
    <>
      <Modal
        style={{
          marginTop: "10%",
          marginLeft: "30%",
          width: "60%",
          height: "auto",
        }}
        open={archivedOpen}
        onClose={() => setArchivedOpen(false)}
      >
        <Archived setOpen={setArchivedOpen} />
      </Modal>
      <>
        <div className={classes.root}>
          <PageTitle title="Campaigns" />
          <CTAContainer>
            <div>
              <p>Ready to start a new campaign?</p>
              <p>
                Use the button on the right side to bring your marketing success
                to life.
              </p>
            </div>
            <SendButton onClick={onClickCreateNewCampaign} disableRipple>
              Create new campaign
            </SendButton>
          </CTAContainer>
          <div className={classes.selectors}>
            <SendButton
              style={{
                width: "auto",
                height: "50px",
                padding: 10,
              }}
              onClick={() => setArchivedOpen(true)}
              disableRipple
            >
              Un-archive campaigns
            </SendButton>
            <div className={classes.selectItems}>
              {/* <FlowType
                currentOption={currentOption}
                setCurrentOption={setCurrentOption}
                location={location}
                history={history}
              /> */}
              <CampaignTypes
                selected={selected}
                setSelected={setSelected}
                selectedArr={selectedArr}
                setSelectedArr={setSelectedArr}
              />
              <ClientList client={client} setClient={setClientSelection} />

              {/* {currentOption.toLocaleLowerCase() !== "scrappers" && (
                <ClientList client={client} setClient={setClientSelection} />
              )} */}
            </div>
          </div>
          {!facebookCampaigns.loading ||
          !twitterCampaigns.loading ||
          !instagramCampaigns.loading ? (
            <>
              {client || loading ? (
                allCampaigns.length ? (
                  <div id="admin-table" style={{ marginTop: 24 }}>
                    <TableContainer
                      component={Paper}
                      className={classes.tableContainer}
                    >
                      <Table
                        className={classes.table}
                        aria-label="simple table"
                      >
                        <TableHead>
                          <TableRow className={classes.tableHeader}>
                            <TableCell
                              className={classes.tableCell}
                              align="left"
                              style={{ padding: "0px 0px 0px 17px" }}
                              colSpan={7}
                            >
                              {twitterFilteredData?.length +
                                facebookFilteredData?.length +
                                instagramFilteredData?.length}{" "}
                              campaigns
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <BeAmplified
                          campaigns={{
                            twitter: twitterFilteredData,
                            instagram: instagramFilteredData,
                            facebook: facebookFilteredData,
                          }}
                        />
                      </Table>
                    </TableContainer>
                  </div>
                ) : (
                  <div style={{ marginTop: 20 }}>
                    <NoPosts title="There are no campaigns for the selected client" />
                  </div>
                )
              ) : (
                <div className={classes.noClient}>
                  To view the list of available campaigns, please choose a
                  client above.
                </div>
              )}
            </>
          ) : (
            <CustomLoader />
          )}
        </div>
      </>
    </>
  );
};

export default BeAmplifiedPage;
