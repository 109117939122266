import React, { FunctionComponent, useRef, useEffect } from "react";
import Button from "@material-ui/core/Button";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";

import { useStyles } from "./styles";

import Popup from "./Popup";
import { DeleteAdmin } from "./Delete";

interface IActions {
  user: any;
}

export const Actions: FunctionComponent<IActions> = ({ user }) => {
  const classes = useStyles({});
  const [open, setOpen] = React.useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleListKeyDown = (event) => {
    if (event.key === "tab") {
      event.preventDefault();
      setOpen(false);
    }
  };

  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      if (anchorRef.current) {
        anchorRef.current.focus(); // Call the focus method here
      }
    }
  }, [open]);

  return (
    <div className={classes.root}>
      <Button
        disableRipple
        ref={anchorRef}
        variant="outlined"
        aria-controls={open ? "menu-list-grow" : undefined}
        aria-haspopup="true"
        classes={{
          label: classes.label,
          root: classes.button,
        }}
        style={
          open
            ? {
                background: "#E8E8E8",
                borderBottomLeftRadius: "initial",
                borderBottomRightRadius: "initial",
              }
            : { background: "#fffff" }
        }
        onClick={handleToggle}
      >
        Actions <ArrowDropDownIcon />
      </Button>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        className={classes.popper}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id="menu-list-grow"
                  onKeyDown={handleListKeyDown}
                  style={{ padding: "0px" }}
                >
                  <MenuItem className={classes.li} onClick={() => {}}>
                    <Popup edit="edit" user={user} />
                  </MenuItem>
                  <div className={classes.line}></div>
                  <MenuItem className={classes.li} onClick={() => {}}>
                    <DeleteAdmin user={user} />
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
};
