import React, { FC } from "react";
import Avatar from "@material-ui/core/Avatar";
import { Checkbox, FormControlLabel, Grid } from "@material-ui/core";
import cuid from "cuid";

import { IUser } from "interfaces/IUser";
import { useStyles } from "./styles";
import { useStyles as useStylesPlanned } from "../styles";
import { saveNote } from "../helpers/notes";
import { SendButton } from "components/styled/Common";
import {
  addCommentNotification,
  addEventCommentNotification,
} from "helpers/notification/addNotification";
import { NotificationActions } from "interfaces/INotification";

interface Props {
  user: IUser;
  note: string;
  setNote: (note: string) => void;
  setNoteType?: (type: string) => void;
  noteType?: string;
  post?: any;
  tempNotes?: Array<any>;
  setTempNotes?: (notes: Array<any>) => void;
  collection?: string;
  client?: IUser;
}

const NoteField: FC<Props> = ({
  user,
  note,
  setNote,
  setNoteType,
  noteType,
  post,
  tempNotes,
  setTempNotes,
  collection,
  client,
}) => {
  const classes = useStyles({});
  const classes2 = useStylesPlanned({});

  const submit = async (noteText) => {
    if (noteText.trim() === "") return;
    setNoteType && setNoteType("internal");
    let noteObject = {
      id: cuid(),
      user: user,
      message: noteText,
      created_at: new Date(),
      type: noteType,
    };
    if (!noteType) {
      delete noteObject.type;
    }
    if (!post) {
      setTempNotes && setTempNotes([...(tempNotes as any), noteObject]);

      setNote(noteText);
    } else {
      await saveNote(post.id, noteObject, collection);

      if (collection === "events") {
        addEventCommentNotification(
          NotificationActions.added_comment,
          post?.subject,
          client ? client?.firstName + " " + client?.lastName : "",
          post.id,
          client ?? null,
          user,
          post?.start_date,
          noteText
        )
          .then((res) => {
            // ("creating notification", res);
          })
          .catch((err) => {
            // ("error creating notification", err);
          });
      } else {
        addCommentNotification(
          NotificationActions.added_comment,
          post?.title,
          client ? client?.firstName + " " + client?.lastName : "",
          post.id,
          client ?? null,
          user,
          post.date,
          noteText
        )
          .then((res) => {
            // ("creating notification", res);
          })
          .catch((err) => {
            // ("error creating notification", err);
          });
      }
    }
    setNote("");
  };

  return (
    <div style={{ marginBottom: "-4px" }}>
      <div
        className={classes.noteField}
        style={{ alignItems: "flex-start", marginTop: "10px" }}
      >
        <Avatar
          alt={user.firstName + " " + user.lastName}
          src={user.avatarUrl}
          className={classes.notesAvatar}
          style={{ background: user.avatarUrl ? "#fff" : "#000" }}
        >
          <p className={classes.avatarText}>
            {user?.firstName &&
              user?.lastName &&
              user.firstName[0] + user.lastName[0]}
          </p>
        </Avatar>
        <div
          className={classes2.choose}
          style={{ border: "none", marginTop: "0px" }}
        >
          <textarea
            className={classes2.input}
            placeholder=""
            value={note}
            onKeyDown={async (e) => {
              const noteText = e.currentTarget.value;
              if (e.key === "Enter" && !e.shiftKey && noteText.trim() !== "") {
                e.preventDefault();
                await submit(noteText);
              }
            }}
            onChange={async (e: any) => setNote(e.target.value)}
            style={{ width: "100%", resize: "vertical", overflow: "hidden" }}
          />

          {/* <SendButton
            onClick={() => submit(note)}
            disableRipple
            style={{ width: "unset" }}
          >
            Post
          </SendButton> */}
        </div>
      </div>
      {noteType && (
        <Grid
          container
          style={{
            paddingTop: "10px",
          }}
        >
          {/* <div style={{ display: "flex", justifyContent: "flex-end", marginTop: 10 }}> */}
          <Grid xs={9} md={9} item>
            {collection !== "events" && (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={noteType === "external"}
                    className={classes.checkbox}
                    disableRipple
                    style={{
                      color: "#ABADAD",
                    }}
                    onChange={(e) =>
                      setNoteType &&
                      setNoteType(e.target.checked ? "external" : "internal")
                    }
                    inputProps={{
                      "aria-label": "Make Visible To Client",
                    }}
                  />
                }
                label={
                  <span className={classes.checkboxLabel}>
                    Make visible externally (to clients)
                  </span>
                }
                style={{ marginRight: "0px" }}
              />
            )}
          </Grid>
          <Grid
            xs={3}
            md={3}
            item
            style={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <SendButton
              onClick={() => submit(note)}
              disableRipple
              style={{ width: "unset" }}
            >
              Post
            </SendButton>
          </Grid>
          {/* </div> */}
        </Grid>
      )}
    </div>
  );
};

export default NoteField;
