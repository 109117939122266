/**
 * @prettier
 */
export interface IUser {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  avatarUrl: string;
  isAdmin: boolean;
  role: Roles;
  isActivated: boolean;
  isSignedUp: boolean;
  isArchived: boolean;
  isInvited: boolean;
  secondaryEmails: any;
  createdAt: Date;
  facebook: {
    url?: string;
    username?: string;
    apiKey?: string;
    error?: string;
    permission?: string;
    accessToken?: string;
    pages?: any[];
  };
  instagram: {
    url?: string;
    username?: string;
    apiKey?: string;
    error?: string;
    permission?: string;
    accessToken?: string;
    metadata?: {
      id: string;
    };
  };
  twitter: {
    url?: string;
    username?: string;
    apiKey?: string;
    error?: string;
    permission?: string;
    accessToken?: string;
  };
  tiktok: {
    url?: string;
    username?: string;
    apiKey?: string;
    error?: string;
    permission?: string;
    accessToken?: string;
  };
  youtube: {
    url?: string;
    username?: string;
    apiKey?: string;
    error?: string;
    permission?: string;
    accessToken?: string;
  };
  spotify: {
    id?: string;
    url?: string;
    username?: string;
    apiKey?: string;
    error?: string;
    permission?: string;
  };
  appleMusic: {
    url?: string;
    username?: string;
    apiKey?: string;
    error?: string;
  };
  notes?: string;
  page_visit?: Array<string>;
  isExternal?: boolean;
  [key: string]: any;
}

export enum Roles {
  user = "user",
  admin = "admin",
  viewerAdmin = "viewerAdmin",
  superAdmin = "superAdmin",
}

export interface IUserArray {
  documents: Array<IUser>;
  loading: boolean;
  error: string;
}
