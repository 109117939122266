import React, { useEffect, useRef, useState } from "react";
import StatusV2 from "./StatusV2";
import { DateTime } from "luxon";

import {
  Button,
  Modal,
  TextField,
  makeStyles,
  Grid,
  IconButton,
  Typography,
  Chip,
  CircularProgress,
  Tooltip,
  ClickAwayListener,
  Backdrop,
} from "@material-ui/core";
import { handleDeleteVideo, updateSuggestions } from "./helpers/posts";
import CancelIcon from "@material-ui/icons/Cancel";
import CloseIcon from "@material-ui/icons/Close";
// import moment from "moment";
// import { DateRange } from "materialui-daterange-picker";
import useDocumentSubscriber from "hooks/useDocumentSubscriber";
import { firestore } from "../../firebase-tools/firebase";
import { useUser } from "UserContext";
import { IUser } from "interfaces/IUser";
import { UserService } from "services/UserService";
import { FieldTitle } from "components/styled/CreateEditPost";
import AutoTags from "components/Common/AutoTags";
import useCollectionSubscriber from "hooks/useCollectionSubscriber";
import Editable from "components/ContentEditableV2";
import UploadImage from "../Common/UploadImage/UploadImage";
import VideoUpload from "../Common/VideoUpload";
// import { highlightDoc, unHighlight } from "../ContentEditable/Editable";
import { ReactComponent as HighlightIcon } from "../../static/media/highlight.svg";
import { ReactComponent as UnHighlightIcon } from "../../static/media/unhighlight.svg";
import { ReactComponent as AddEmoji } from "../../assets/add-emoji.svg";
import { ReactComponent as EditIcon } from "../../assets/Edit.svg";
import { capitalize } from "utils/stringFormat";
import { v4 as uuidv4 } from "uuid";
import firebase from "firebase/app";

import {
  SendButton,
  PostPaper,
  EmojiPicker,
  DateInput,
  CancelButton2,
} from "components/styled/Common";
import { Picker } from "emoji-mart";
import Name from "components/Common/Form/Name";
import Carousel from "components/Common/Carousel";
import { allSelectedCategories } from "./PostCategory";
import { PostService } from "services/PostService";
import { IPost } from "interfaces/IPost";
import { errorCheckerV2 } from "./helpers/errorChecker";
import ChannelsV2 from "./ChannelsV2";
import ChatGptPostGenerator from "./ChatGptPostGenerator";
import PostCategoriesV2 from "./PostCategoriesV2";
import TextArea from "components/TextArea";
import {
  highlightDoc,
  unHighlight,
} from "components/ContentEditableV2/Editable";
import { DeleteV2 } from "./DeleteV2";
import { NotificationActions } from "interfaces/INotification";
import { success } from "utils/notification";
import { addPostNotification } from "helpers/notification/addNotification";
import { ClientBeplanned } from "./ClientBePlanned";
import UnsavedChangesPrompt from "./ActionPrompts/UnsavedChangesPrompt";
import CustomGenerateImage from "./GenerateImage/CustomGenerateImage";
import ChatGptPostGenerateImage from "./GenerateImage/ChatGptGenerateImage";
const channelOptions = [
  "instagram",
  "facebook",
  "twitter",
  "tiktok",
  "youtube",
  "threads",
  "snapchat",
  "discord",
];

const topMedia = [
  "Instagram",
  "Facebook",
  "Twitter",
  "TikTok",
  "Triller",
  "Snapchat",
  "Pinterest",
  "Tumblr",
];
export const channelsList = [
  "instagram",
  "facebook",
  "twitter",
  "tiktok",
  "youtubeshort",
  "youtube",
];

export const deleteMedia = async (media) => {
  const mediaRef = await firebase.storage().refFromURL(media);
  await mediaRef.delete();
};

// Custom styles for the modal and form
const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    overflowY: "auto",
  },
  form: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(3),
    width: "984px",
    height: "100%",
    minHeight: "109px", // Assuming "hug" means auto
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    outline: "none",
    overflowY: "auto",
  },
  closeIcon: {
    position: "absolute",
    top: theme.spacing(1),
    right: theme.spacing(1),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  chip: {
    margin: "4px 4px 0px 0px",
    maxWidth: "100%",
    fontFamily: "'Avenir Book', sans-serif",
  },
  choose: {
    display: "flex",
    alignItems: "center",
    border: "2px solid #ABADAD",
    // border: (props: any) =>
    //   props.dropdown ? "2px solid #000" : "2px solid #ABADAD",
    width: "100%",
    borderRadius: "10px",
    marginTop: "5px",
    "&:hover": {
      border: "2px solid #000",
    },
  },

  input: {
    width: "94%",
    padding: "12.5px",
    border: "2px solid #ABADAD",
    boxSizing: "border-box",
    borderRadius: "10px",
    cursor: "pointer",
    fontFamily: "'Avenir Book', sans-serif",
    lineHeight: "20px",
    fontSize: "15px",
    "&:focus": {
      border: "2px solid #000",
      outline: "none",
    },
  },
  field: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    // marginTop: "26px",
    transition: "all 0.3s ease",
  },
  uploadIcons: {
    display: "flex",
    // paddingTop: 5,
    alignItems: "end",
    // justifyContent: "flex-end",
    width: "94%",
    "& > *": {
      cursor: "pointer",
    },
  },
  error: {
    color: "#f44336",
    fontSize: "15px",
    textAlign: "center",
    width: "75%",
  },
  highlight: {
    border: "none",
    background: "none",
    padding: "0px",
    "&:active, &:focus": {
      border: "none",
      outline: "none",
      padding: "0px",
    },
  },

  uploadImgContainer: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    // marginTop: 12,
    width: "94%",
  },

  icon: {
    color: "#000",
    cursor: "pointer",
    fontSize: "30px",
    fontWeight: "bold",
    transition: "all .3s ease",
  },
  btnstyle: {
    width: "150px",
    height: "45px",
    padding: "11.5px 8px 11.5px 8px",
    borderRadius: "15px",
    backgroundColor: "#000000",
    color: "white",
    fontFamily: "Avenir",
    fontSize: "13px",
    fontWeight: 900,
    lineHeight: "22px",
    letterSpacing: "0.429px",
    textAlign: "center",
  },

  editBox: {
    border: "1px solid #ABADAD",
    width: "100%",
    // padding: "12.5px",
    boxSizing: "border-box",
    borderRadius: "10px",
    fontFamily: "'Avenir Book', sans-serif",
    resize: "vertical",
    overflow: "auto",
    marginTop: "5px",
    marginBottom: "5px",
    fontSize: "15px",
    "&:focus": {
      outline: "none",
      border: "2px solid #000",
    },
    "&:hover": {
      border: "1px solid #000",
    },
    "&:active": {
      border: "1px solid #000",
    },
    "&:empty:not(:focus):before": {
      content: "attr(placeholder)",
      color: "#ABADAD",
      fontFamily: "'Avenir Book', sans-serif",
      fontSize: "inherit",
    },
  },
  btn: {
    width: "159px",
    height: "45px",
    background: "#000000",
    borderRadius: "15px",
    textTransform: "capitalize",
    fontFamily: "'Avenir Black', sans-serif",
    fontSize: "15px",
    fontWeight: 900,
    fontStyle: "normal",
    lineHeight: "22px",
    color: "#FFFFFF",
    marginBottom: "5px",
    justifyContent: "center",
    float: "right",
    alignItems: "center",

    "&:hover": {
      backgroundColor: "#000000",
      opacity: 0.85,
    },

    "&:disabled": {
      color: "#fff !important",
      opacity: 0.5,
      cursor: "not-allowed !important",
    },
  },
  edit: {
    width: "150px !important",
  },
}));

interface ICreateEditPostV2 {
  edit?: string;
  create?: string;
  role: string;
  user?: any;
  monthly?: string;
  post?: any;
  externalOpen: boolean;
  setExternalOpen: (open: boolean) => void;
  externalDate?: any;
  setExternalDate?: (date: any) => void;
  calenderView?: boolean;
  clearRefresh?: boolean;
}
const EditPost = ({
  edit,
  create = "",
  user,
  setExternalOpen,
  setExternalDate,
  externalDate,
  externalOpen,
  post,
  role,
  calenderView,
}: ICreateEditPostV2) => {
  const classes = useStyles();
  const [selectedOption, setSelectedOption] = useState(1);
  // const [client, setClient] = useState("");
  const [channels, setChannels]: any = useState([]);
  const [categories, setCategories]: any = useState([]);
  // const [dateRange, ] = React.useState<DateRange>({
  //   startDate: moment().startOf("day").toDate(),
  //   endDate: moment().add(6, "days").startOf("day").toDate(),
  // });

  const [, setLoading] = useState(false);

  // let toDate = new Date(dateRange.endDate as Date);
  const [authUser] = useUser() as Array<IUser>;

  let userRef = firestore.collection("users").doc(authUser.id);

  let client =
    useDocumentSubscriber({ ref: userRef }).document?.lastClientSelection || "";

  const queries = [
    firestore
      .collection("users")
      .where("role", "==", "user")
      .orderBy("firstName", "asc"),
  ];
  let users = useCollectionSubscriber<IUser>({ queries }).documents;
  users = users.filter((user) => !user.isArchived && !user.isExternal);

  const stripHtml = (html) => {
    let tmp = document.createElement("DIV");
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || "";
  };

  const settClient = (e) => {
    const User = new UserService(authUser.id);

    return User.updateUser({
      lastClientSelection: e,
    })
      .then(() => {
        // setClient(e);
        setUser(e);
      })
      .catch(() => {});
  };
  const [selectedClients, setSelectedClients] = useState([]);
  const handleOpen = () => {
    setExternalOpen(true);
  };

  const [openAi, setOpenAi] = useState(false);

  const handleOptionClick = (option: number) => {
    setSelectedOption(option);
    // if (option === 2) {
    setOpenAi(true);
    handleCloseOnSave(); //
    // }
  };

  const handleOptionClickCreate = (option: number) => {
    setSelectedOption(option);
  };

  const getOptionStyle = (option) => {
    return {
      fontFamily: "Avenir",
      fontSize: "21px",
      fontWeight: 900,
      lineHeight: "24px",
      cursor: "pointer",
      textDecoration: selectedOption === option ? "underline" : "none",
      color: selectedOption === option ? "#000000" : "#606060",
    };
  };
  const getPostDate = (dateInSeconds: number) => {
    return DateTime.fromSeconds(dateInSeconds).toFormat("yyyy-MM-dd'T'HH:mm");
  };

  const getVideoPlaceholder = (linkType: string) => {
    switch (linkType) {
      case "vimeo":
        return "Paste your Vimeo link here...";
      case "youtube":
        return "Paste your Youtube link here...";
      case "dropbox":
        return "Paste your Dropbox link here...";
      default:
        return "";
    }
  };

  const [categoryOptions, setCategoryOptions] = useState([
    "Facebook Feed",
    "Facebook Story",
    "Facebook Group Post",
    "Facebook Live",
    "Facebook Reel",
    "Instagram Feed",
    "Instagram Carousel",
    "Instagram Story",
    "Instagram IGTV",
    "Instagram Reels",
    "Instagram Live",
    "Instagram Broadcast Channel",
    "Twitter Feed",
    "Twitter Thread",
    "Twitter Poll",
    "Tweet To Reveal",
    "Twitter Fleets",
    "Twitter Live",
    "Twitter Voice",
    "TikTok Feed",
    "TikTok Live",
    "TikTok Short",
    "Threads Feed",
    "Snapchat Spotlight",
    "Snapchat Story",
    "Discord Channel Message",
    "Youtube Feed",
    "YouTube Community Tab",
    "YouTube Live",
    "YouTube Short",
    "All",
  ]);

  // const [create, setCreate] = useState("create");
  const monthly = "";

  // startd here
  const [isOpen, setIsOpen] = useState(false);
  const [image, setImage] = useState("");
  const [images, setImages]: any = useState([]);
  const [video, setVideo]: any = useState("");
  const [link, setLink] = useState("");
  // const [videoLink, setVideoLink] = useState("");
  // const [videoUpload, setVideoUpload] = useState("");
  const [linkInput, setLinkInput] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [isGifUploading, setIsGifUploading] = useState(false);
  const [isVideoUploading, setIsVideoUploading] = useState(false);
  const [selectedDate, setSelectedDate]: any = useState(
    DateTime.fromJSDate(new Date()).toFormat("yyyy-MM-dd'T'HH:mm")
  );
  const [postText, setPostText]: any = useState("");
  const [graphicsBrief, setGraphicsBrief]: any = useState("");
  const [additionalInfo, setInfo]: any = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [toEdit, setToEdit] = useState("");
  const [toCreate, setToCreate] = useState("");
  const [error, setError] = useState({
    textError: "",
    linkErr: "",
    platformError: "",
    instagramError: "",
    titleError: "",
    channelsError: "",
    clientError: "",
  });
  const [platform, setPlatform] = useState("");
  const [, setAutocomplete] = useState(false);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [postUpdate, setPostUpdate] = useState(0);
  const [videoProgress, setVideoProgress] = useState(0);
  const [imageProgress, setImageProgress] = useState(0);
  const [gifProgress, setGifProgress] = useState(0);
  const [tags, setTags]: Array<any> = useState([]);
  const [selectedChannels, setSelectedChannels]: any = useState([]);
  const [internalStatusMonthly, setInternalStatusMonthly] = useState("Draft");
  const [isVisibletoClient, setIsVisibleToClient] = useState("No");
  const [internalStatusWeekly, setInternalStatusWeekly] = useState("Draft");
  const [postEmail, setPostEmail] = useState("");
  const [category, setCategory] = React.useState({
    instagram: [],
    facebook: [],
    twitter: [],
    tiktok: [],
    youtube: [],
    youtubeshort: [],
    discord: [],
    snapchat: [],
    threads: [],
    custom: [],
  });

  const [categoryError, setCategoryError] = useState({
    facebook: "",
    instagram: "",
    twitter: "",
    tiktok: "",
    youtube: "",
    discord: "",
    snapchat: "",
    threads: "",
    custom: "",
  });
  const [linkType, setLinkType] = useState("");
  const [postHtml, setHtml] = useState("");
  const [title, setTitle] = useState("");
  const [graphicsHtml, setGraphicsHtml] = useState("");
  const [curUser, setUser]: any = useState({});
  const [infoHtml, setInfoHtml] = useState("");

  const handleEmojiSelect = (emoji: { native: string | undefined }) => {
    document.execCommand("insertText", false, emoji.native);
    setShowEmojiPicker(!showEmojiPicker);
  };

  const postId = post?.id;
  const platformQueries = [
    firestore.collection("social-media").orderBy("created_at", "asc"),
  ];
  const documents: any = useCollectionSubscriber({
    queries: platformQueries,
  }).documents[0];
  const platforms = documents ? documents.platforms : topMedia;
  const oldTags = documents?.tags || [];

  const vimeoRegex = /^(http:\/\/|https:\/\/)(vimeo\.com)\/([\w]+)?$/;
  const youtubeRegex = /(http:|https:)?\/\/(www\.)?(youtube.com|youtu.be)\/(watch)?(\?v=)?(\S+)?/;
  const clearAllErrors = () => {
    setError({
      textError: "",
      linkErr: "",
      platformError: "",
      instagramError: "",
      titleError: "",
      channelsError: "",
      clientError: "",
    });
  };

  type SocialMediaObject = {
    custom: string[];
    instagram: string[];
    twitter: string[];
    youtube: string[];
    facebook: string[];
    tiktok: string[];
    youtubeshort: string[];
  };
  const combineArrays = (socialMediaObject: SocialMediaObject) => {
    let combinedArray: string[] = [];

    for (const key in socialMediaObject) {
      if (socialMediaObject.hasOwnProperty(key)) {
        const currentArray: string[] = socialMediaObject[key];
        combinedArray = combinedArray.concat(currentArray);
      }
    }

    return combinedArray;
  };
  useEffect(() => {
    // preventScroll(isOpen, windowOffset, setWindowOffset);
    // eslint-disable-next-line
  }, [isOpen, externalOpen]);

  //set initial post state
  useEffect(() => {
    const isVideoLink = (link) => {
      if (vimeoRegex.test(link)) {
        setLinkInput(true);
        setLink(post.video_url);
        setLinkType("vimeo");
        // setVideoLink(post.video_url);
      } else if (youtubeRegex.test(link)) {
        setLinkInput(true);
        setLink(post.video_url);
        setLinkType("youtube");
        // setVideoLink(post.video_url);
      } else if (post?.video_url?.includes("dropbox")) {
        setLinkInput(true);
        setLink(post.video_url);
        setLinkType("dropbox");
        // setVideoLink(post.video_url);
      } else {
        setVideo(post.video_url);
        // setVideoUpload(post.video_url);
      }
    };

    setPlatform(capitalize(role || ""));
    if (post) {
      if (post?.channels?.length > 0) {
        setChannels(post.channels);
        setSelectedChannels(post.channels);
      } else {
        const channel: any = [];
        channel.push(role);

        setSelectedChannels(channel);
      }
      if (post.category) {
        let newArr = combineArrays(post.category);
        const categoryUpdate = { ...category };

        Object.keys(post.category).forEach((element) => {
          if (post.category[element].length) {
            categoryUpdate[element] = post.category[element];
          }
        });
        setCategory(categoryUpdate);
        setCategories(newArr);
      }
      if (post.user_email) {
        let userEmail: any = post.user_email;
        setPostEmail(userEmail);
        setSelectedClients([userEmail] as any);
      }
      const date = getPostDate(post?.date?.seconds);
      if (post?.body) {
        setPostText(post.body);
      }
      setSelectedDate(date);
      setTags(post.tags || []);
      setInternalStatusMonthly(post.internal_status_monthly || "Draft");
      setInternalStatusWeekly(post.internal_status_weekly || "Draft");
      setIsVisibleToClient(post?.is_visible_to_client || "No");

      if (typeof post.image_url === "string") {
        setImage(post.image_url);
        setImages(post.image_url !== "" ? [post.image_url] : []);
      } else {
        setImages(post.image_url);
      }
      setHtml(post.body);
      isVideoLink(post.video_url);
      setPostUpdate(1);
      if (post.title) {
        setTitle(post.title);
      } else {
        setTitle(capitalize(role || "") + " Post");
      }
      if (post.graphicsBrief) {
        setGraphicsHtml(post.graphicsBrief);
        setGraphicsBrief(post.graphicsBrief);
      } else {
        setGraphicsHtml(`
        <div>TITLE:</div><div>DUE:</div><div>POSTED:</div><div>Asset type:</div><div>Asset purpose:</div><div>Format:</div><div>Brief:</div><div>Asset:</div><div>Text:</div><div>Timecodes:</div><div>Links:</div><div>Reference:</div>`);
      }
      if (post.additional_info) {
        setInfo(post.additional_info);
        setInfoHtml(post.additional_info);
      } else {
        setInfo(post?.additional_ifo || "");
      }
    } else {
      setGraphicsHtml(`
          <div>TITLE:</div><div>DUE:</div><div>POSTED:</div><div>Asset type:</div><div>Asset purpose:</div><div>Format:</div><div>Brief:</div><div>Asset:</div><div>Text:</div><div>Timecodes:</div><div>Links:</div><div>Reference:</div>`);
    }

    // eslint-disable-next-line
  }, [post, role]);

  const setEditCreate = () => {
    setToCreate(create ? create : "");
    setToEdit(edit ? edit : "");
  };

  useEffect(() => {
    setEditCreate();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (monthly?.includes("monthly")) setUser({ email: user.email });
    // eslint-disable-next-line
  }, [monthly, user.email]);

  const handleDateChange = (date: any) => {
    setSelectedDate(date);
  };
  const getFormattedDate = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = `${(now.getMonth() + 1).toString().padStart(2, "0")}`;
    const day = `${now.getDate().toString().padStart(2, "0")}`;
    const hours = `${now.getHours().toString().padStart(2, "0")}`;
    const minutes = `${now.getMinutes().toString().padStart(2, "0")}`;

    return `${year}-${month}-${day}T${hours}:${minutes}`;
  };

  const toggleEmojiPicker = () => {
    setShowEmojiPicker(!showEmojiPicker);
  };

  const clearForm = () => {
    setSubmitting(false);
    setExternalOpen ? setExternalOpen(false) : setIsOpen(false);
    setAutocomplete(false);
    setCategoryError({
      facebook: "",
      instagram: "",
      twitter: "",
      tiktok: "",
      youtube: "",
      discord: "",
      snapchat: "",
      threads: "",
      custom: "",
    });
    setError({
      textError: "",
      linkErr: "",
      platformError: "",
      instagramError: "",
      titleError: "",
      channelsError: "",
      clientError: "",
    });
    setExternalDate && setExternalDate(undefined);
    handleCloseOnSave();
    setUnSavedPrompt(false);
    setChannels(post?.channels || []);
    const arr = combineArrays(post?.category);
    setCategories(arr || []);
  };
  //return post to save
  const postSave = async (id, body, category, selectedChannels) => {
    let Post;
    let post;
    const { finalCategories } = allSelectedCategories(
      category,
      selectedChannels
    );

    Post = new PostService("multichannel-posts", id);
    post = {
      created_at: new Date(),
      date: new Date(selectedDate),
      body,
      image_url: images,
      video_url: video && !link ? video : link ? link : "",
      user_email: postEmail ?? curUser.email,
      channels: selectedChannels,
      title,
      graphicsBrief,
      additional_info: additionalInfo,
      tags,
      internal_status_monthly: internalStatusMonthly,
      internal_status_weekly: internalStatusWeekly,
      category: finalCategories,
      is_visible_to_client: isVisibletoClient,
    };

    return { Post, post };
  };

  const deleteVid = async (linkUpdate) => {
    await handleDeleteVideo(
      video,
      post,
      toEdit,
      setVideo,
      setLink,
      setLinkInput,
      false
    );
    setLink(linkUpdate);
    setLinkInput(true);
  };

  const isPlatform = (link, linkType) => {
    if (
      (link.includes("vimeo") && linkType === "vimeo") ||
      (link.includes("vimeo") && linkType === "")
    ) {
      return "vimeo";
    } else if (
      (linkType === "youtube" && link.includes("youtube")) ||
      (linkType === "" && link.includes("youtube"))
    ) {
      return "youtube";
    } else if (
      (linkType === "dropbox" && !link.includes("dropbox")) ||
      (linkType === "" && link.includes("dropbox"))
    ) {
      return "dropbox";
    } else {
      return "";
    }
  };

  //save popup
  // Create refs for each section where you have an error
  const clientErrorRef = useRef(null) as any;
  const channelErrorRef = useRef(null) as any;
  const categoryErrorRef = useRef(null) as any;
  const postTitleErrorRef = useRef(null) as any;
  const handleSubmit = async () => {
    clearAllErrors();

    if (selectedClients.length === 0) {
      clientErrorRef.current.scrollIntoView({ behavior: "smooth" });
      return setError({
        ...error,
        clientError: "At least one client is required!",
      });
    } else {
      setError({ ...error, channelsError: "" });
    }

    if (channels.length === 0) {
      // ("At least one channel is required!", error.channelsError);
      channelErrorRef.current.scrollIntoView({ behavior: "smooth" });

      return setError({
        ...error,
        channelsError: "At least one channel is required!",
      });
    } else {
      setError({ ...error, channelsError: "" });
    }

    // const { errorCheck, errors } = errorChecker(selectedChannels, category, []);
    const { errorCheck, errors } = errorCheckerV2(channels, categories, []);

    setCategoryError(errors);
    if (errorCheck) {
      categoryErrorRef.current.scrollIntoView({ behavior: "smooth" });

      return;
    }

    if (title === "") {
      postTitleErrorRef.current.scrollIntoView({ behavior: "smooth" });
      return setError({ ...error, titleError: "Post title is required!" });
    } else {
      setError({ ...error, titleError: "" });
    }

    if (linkInput) {
      if (link === "") {
        return setError({ ...error, linkErr: "Video link is required!" });
      } else {
        if (
          !vimeoRegex.test(link) &&
          !youtubeRegex.test(link) &&
          !link.includes("dropbox") &&
          !link.includes("firebasestorage")
        ) {
          const platform = isPlatform(link, linkType);

          return setError({
            ...error,
            linkErr: `Enter a valid ${platform} link!`,
          });
        } else {
          setError({
            ...error,
            linkErr: "",
          });
        }
      }
    }

    if (
      toEdit &&
      video &&
      (vimeoRegex.test(link) || youtubeRegex.test(link)) &&
      postUpdate === 1
    ) {
      deleteVid(link);
      setVideo("");
      await new Promise((resolve) => setTimeout(resolve, 1000));
    }
    setSubmitting(true);

    if (toCreate) {
      // const { Post, post } = await postSave(uuid);
      const postCtService = multiplePostSave(postText);
      const created: any[] = [];

      for (const item of postCtService) {
        const { Post, post } = item;
        Post.addPost(post as IPost)
          .then(async (res: any) => {
            if (res) {
              let data = { ...post, id: res };
              created.push(data);

              // ("about creating item", item);
              let notifyData = data as IPost;
              const clientData = (users.find(
                (user: IUser) => user.email === notifyData.user_email
              ) as unknown) as IUser;
              let clientName = clientData.firstName || "";
              if (clientData.lastName) {
                clientName = clientData.firstName + " " + clientData.lastName;
              }
              addPostNotification(
                NotificationActions.updated_post,
                internalStatusWeekly,
                title,
                clientName,
                postId,
                clientData,
                authUser,
                selectedDate
              )
                .then((res) => {
                  // ("creating notification", res);
                })
                .catch((err) => {
                  // ("error creating notification", err);
                });

              if (created.length === postCtService.length) {
                clearForm();
              }
            }
          })
          .catch((e) => {
            // ("ERROR:", e);
            setSubmitting(false);
            return setError(e);
          });
      }
    }

    if (toEdit) {
      const { Post, post } = await postSave(
        postId,
        postText,
        category,
        channels
      );

      Post.updatePost(post)
        .then(async () => {
          setSubmitting(false);
          setExternalOpen ? setExternalOpen(false) : setIsOpen(false);
          setAutocomplete(false);
          // setSelectMedia(false);
          updateSuggestions(oldTags, tags, platforms, platform, documents.id);
          setExternalDate && setExternalDate(undefined);
          handleCloseOnSave();
        })
        .catch((e) => {
          // ("ERROR:", e);
          setSubmitting(false);
          return setError(e);
        });

      const clientData = (users.find(
        (user: IUser) => user.email === post.user_email
      ) as unknown) as IUser;
      let clientName = clientData.firstName || "";
      if (clientData.lastName) {
        clientName = clientData.firstName + " " + clientData.lastName;
      }

      addPostNotification(
        NotificationActions.updated_post,
        internalStatusWeekly,
        title,
        clientName,
        postId,
        clientData,
        authUser,
        selectedDate
      )
        .then((res) => {
          // ("creating notification", res);
        })
        .catch((err) => {
          // ("error creating notification", err);
        });
    }

    setUser({});
    setEditCreate();
    clearForm();
  };

  const multiplePostSave = (body) => {
    let Post;
    let post;
    const { finalCategories } = allSelectedCategories(
      category,
      selectedChannels
    );

    let postArray: { post: any; Post: PostService }[] = [];

    for (const email of selectedClients) {
      const id = uuidv4();
      Post = new PostService("multichannel-posts", id);
      post = {
        created_at: new Date(),
        date: new Date(selectedDate),
        body,
        image_url: images,
        video_url: video && !link ? video : link ? link : "",
        user_email: email,
        channels: selectedChannels,
        title,
        graphicsBrief,
        additional_info: additionalInfo,
        tags,
        internal_status_monthly: internalStatusMonthly,
        internal_status_weekly: internalStatusWeekly,
        category: finalCategories,
        is_visible_to_client: isVisibletoClient,
      };
      postArray.push({ post, Post });
    }

    return postArray;

    // return { Post, post };
  };

  const deleteTags = (tagToDelete) => () => {
    setTags(tags.filter((tag) => tag !== tagToDelete));
  };

  const postRef = useRef(null);
  const graphicsRef = useRef(null);
  const infoRef = useRef(null);

  const [unSavedPrompt, setUnSavedPrompt] = useState(false);

  const handleCloseOnCancel = (clearData = false) => {
    let unsaved = checkforUnsavedChanges();
    if (unsaved) {
      return;
    }
    setExternalOpen(false);
    clearForm();
  };
  const handleCloseOnSave = (clearData = false) => {
    setExternalOpen(false);
    if (clearData) {
      clearForm();
    }
  };

  const checkforUnsavedChanges = () => {
    let unsaved = false;
    if (link && post?.video_url !== link) {
      unsaved = true;
    }

    if (video && post?.video_url !== video) {
      unsaved = true;
    }

    if (videoProgress > 0) {
      unsaved = true;
    }

    if (isUploading) {
      unsaved = true;
    }

    if (post?.body !== postText) {
      unsaved = true;
    }

    if (post.title !== title) {
      unsaved = true;
    }

    if (post?.additional_info !== additionalInfo) {
      unsaved = true;
    }

    if (post?.tags?.length > 0) {
      if (tags.length !== post?.tags?.length) {
        unsaved = true;
      } else {
        post?.tags?.forEach((tag) => {
          if (!tags.includes(tag)) {
            unsaved = true;
          }
        });
      }
    }

    if (post?.channels?.length > 0) {
      if (channels?.length !== post?.channels?.length) {
        unsaved = true;
      } else {
        post?.channels?.forEach((channel) => {
          if (
            !channels
              ?.map((ch) => ch?.toLowerCase())
              ?.includes(channel?.toLowerCase())
          ) {
            unsaved = true;
          }
        });
      }
    }

    if (post?.category) {
      let newArr = combineArrays(post?.category);
      if (newArr?.length !== categories?.length) {
        unsaved = true;
      } else {
        newArr?.forEach((category) => {
          if (
            !categories
              ?.map((ch) => ch?.toLowerCase())
              ?.includes(category?.toLowerCase())
          ) {
            unsaved = true;
          }
        });
      }
    }

    if (post?.graphicsBrief !== graphicsBrief) {
      unsaved = true;
    }

    if (post?.image_url?.length > 0) {
      post?.image_url?.forEach((image) => {
        if (!images.includes(image)) {
          unsaved = true;
        }
      });
    }

    if (unsaved) {
      setUnSavedPrompt(true);
    }
    return unsaved;
  };
  const handleCloseExternal = (clearData = false) => {
    setExternalOpen(false);
    setUnSavedPrompt(false);
    clearForm();
  };

  useEffect(() => {
    if (image.includes("http")) {
      if (image === "") {
        setImages([]);
      } else {
        if (!images.includes(image)) {
          const updatedImages = [...images, image];
          setImages(updatedImages);
        }
      }
    }
    //eslint-disable-next-line
  }, [image]);

  useEffect(() => {
    if (
      (!toEdit && link && video !== "") ||
      (toEdit && link && video !== "" && postUpdate === 1)
    ) {
      setLink("");
    }

    //eslint-disable-next-line
  }, [video, error]);

  useEffect(() => {
    const linkUpdate = link;

    if (
      !toEdit &&
      video &&
      (vimeoRegex.test(link) || youtubeRegex.test(link))
    ) {
      deleteVid(linkUpdate);
    }
    //eslint-disable-next-line
  }, [link]);

  useEffect(() => {
    externalDate &&
      setSelectedDate(
        DateTime.fromJSDate(externalDate).toFormat("yyyy-MM-dd'T'HH:mm")
      );
  }, [externalDate, post]);

  return (
    <>
      <Grid container>
        {!calenderView && (toEdit || !!edit) && (
          <>
            <EditIcon
              style={{
                marginTop: "8px",
                cursor: "pointer",
              }}
              onClick={() => {
                handleOpen();
                setUser(user);
              }}
            />
          </>
        )}
      </Grid>
      <ChatGptPostGenerator
        openAi={openAi}
        setOpenAi={setOpenAi}
        setPostText={setPostText}
        setOpen={setExternalOpen}
        handleOptionClickCreate={handleOptionClickCreate}
        setTypedData={setHtml}
        selectedOption={selectedOption}
        setSelectedOption={setSelectedOption}
      />
      <UnsavedChangesPrompt
        handleChange={handleCloseExternal}
        setIsOpen={setUnSavedPrompt}
        isOpen={unSavedPrompt}
      />
      <Modal
        className={classes.modal}
        open={externalOpen ? true : false}
        onClose={() => {
          handleCloseOnCancel(true);
        }}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <div className={classes.form} id="edit-create">
          <PostPaper>
            <IconButton
              className={classes.closeIcon}
              onClick={() => {
                handleCloseOnCancel();
              }}
            >
              <CloseIcon />
            </IconButton>
            <Typography
              variant="h6"
              gutterBottom
              style={{
                fontFamily: "Avenir",
                fontSize: "30px",
                fontWeight: 900,
                lineHeight: "41px",
                letterSpacing: "0em",
                textAlign: "left",
              }}
            >
              {toCreate && <span>New </span>}
              {toEdit && <span>Edit </span>}
              Post
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={3} md={1}>
                <span
                  onClick={() => handleOptionClick(1)}
                  style={getOptionStyle(1)}
                >
                  Create
                </span>
              </Grid>
              <Grid item xs={6} md={3}>
                <span
                  onClick={() => handleOptionClick(2)}
                  style={getOptionStyle(2)}
                >
                  AI Generator
                </span>
              </Grid>
            </Grid>
            <br></br>

            {/* Your additional form fields */}
            <Grid container spacing={2}>
              {/* Client Dropdown */}
              <Grid item xs={12} md={3} ref={clientErrorRef}>
                <div>
                  <ClientBeplanned
                    client={client}
                    setClient={settClient}
                    setLoading={setLoading}
                    selectedClients={selectedClients}
                    setSelectedClients={setSelectedClients}
                    label="Client"
                    setError={setError}
                  />
                </div>
                <span className={classes.error}>{error.clientError}</span>
              </Grid>

              {/* Channel Dropdown with Checkbox */}
              <Grid item xs={12} md={3} ref={channelErrorRef}>
                <ChannelsV2
                  setItems={setChannels as any}
                  value={channels}
                  multiple={true}
                  label="Choose Channels"
                  options={channelOptions}
                  setError={setError}
                  setCategoryOption={setCategoryOptions}
                />
                <span className={classes.error}>{error.channelsError}</span>
              </Grid>
              <Grid item xs={12} md={3} ref={categoryErrorRef}>
                <div>
                  <PostCategoriesV2
                    setItems={setCategories as any}
                    selectedCategories={categories}
                    setSelectedCategories={setCategories}
                    multiple={true}
                    label="Post Category"
                    setError={setError}
                    selectedChannels={channels}
                    category={category}
                    setCategory={setCategory}
                    categoryError={categoryError}
                    setCategoryError={setCategoryError}
                    categoryOption={categoryOptions}
                    setCategoryOption={setCategoryOptions}
                  />
                </div>
              </Grid>

              {/* Publish Date DatePicker */}
              <Grid item xs={12} md={3}>
                <div>
                  <div className={classes.field}>
                    <FieldTitle>Publish Date/Time</FieldTitle>
                    <div>
                      <DateInput
                        style={{
                          border: "1px solid #E8E8E8",
                          borderRadius: "15px",
                          marginTop: "8px",
                        }}
                      >
                        <TextField
                          id="datetime-local"
                          type="datetime-local"
                          value={selectedDate}
                          InputLabelProps={{
                            shrink: false,
                          }}
                          onChange={(e) => handleDateChange(e.target.value)}
                          inputProps={{
                            min: getFormattedDate(),
                          }}
                        />
                      </DateInput>
                    </div>
                  </div>
                </div>
              </Grid>
            </Grid>
            <br></br>

            <div className={classes.field}>
              <FieldTitle>Post Title</FieldTitle>
              <div
                className={classes.choose}
                style={{ border: "none" }}
                ref={postTitleErrorRef}
              >
                <input
                  type="text"
                  className={classes.input}
                  placeholder="Start writing..."
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  style={{ width: "100%" }}
                />
              </div>
            </div>
            <span className={classes.error}>{error.titleError}</span>

            <br></br>

            {toEdit && (
              <Grid container>
                <SendButton
                  disableRipple
                  type="button"
                  className={`${classes.btn} ${classes.edit}`}
                  id="create-btn"
                  onClick={() => {
                    success("Post duplicated successfully!");
                    setToCreate("create");
                    setToEdit("");
                  }}
                >
                  Duplicate Post
                </SendButton>
                <br></br>
              </Grid>
            )}

            {/* Existing form fields */}
            <div className={classes.field}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "94%",
                  marginBottom: "5px",
                }}
              >
                <FieldTitle>Post Copy</FieldTitle>
                {selectedChannels.length === 1 &&
                  selectedChannels.includes("twitter") && (
                    <div
                      style={
                        stripHtml(postText).length < 241
                          ? { color: "green" }
                          : postText.length > 240 &&
                            stripHtml(postText).length < 281
                          ? { color: "#CCCC00" }
                          : { color: "red" }
                      }
                    >
                      {stripHtml(postText).length}/280
                    </div>
                  )}
              </div>

              <TextArea
                setContent={setPostText}
                setShowEmojiPicker={setShowEmojiPicker}
                postHtml={postHtml}
                input={postRef}
                placeholder="Start writing..."
              />
              <div></div>
            </div>
            <span className={classes.error}>{error.textError}</span>
            <CustomGenerateImage images={images} setImages={setImages} />
            <ChatGptPostGenerateImage images={images} setImages={setImages} />
            <div className={classes.uploadIcons}>
              {isUploading ? (
                <CircularProgress
                  size={30}
                  color="inherit"
                  variant="determinate"
                  value={imageProgress}
                />
              ) : (
                <UploadImage
                  image={image}
                  setImage={setImage}
                  isUploading={isUploading}
                  setIsUploading={setIsUploading}
                  imgUpload={true}
                  setProgress={setImageProgress}
                />
              )}
              {isGifUploading ? (
                <CircularProgress
                  size={30}
                  color="inherit"
                  variant="determinate"
                  value={gifProgress}
                />
              ) : (
                <UploadImage
                  image={image}
                  setImage={setImage}
                  isUploading={isGifUploading}
                  setIsUploading={setIsGifUploading}
                  gifUpload={true}
                  setProgress={setGifProgress}
                />
              )}
              {isVideoUploading ? (
                <CircularProgress
                  size={30}
                  color="inherit"
                  variant="determinate"
                  value={videoProgress}
                />
              ) : (
                <VideoUpload
                  video={video}
                  setVideo={setVideo}
                  isUploading={isVideoUploading}
                  setIsUploading={setIsVideoUploading}
                  setLinkInput={setLinkInput}
                  linkInput={linkInput}
                  setVideoProgress={setVideoProgress}
                  setLinkType={setLinkType}
                />
              )}
              <button
                className={classes.highlight}
                onClick={(e) => {
                  e.preventDefault();
                  highlightDoc();
                }}
              >
                <Tooltip title="Highlighted text" arrow>
                  <HighlightIcon />
                </Tooltip>
              </button>
              <button
                className={classes.highlight}
                onClick={(e) => {
                  e.preventDefault();
                  unHighlight();
                }}
              >
                <Tooltip title="Un-highlight text" arrow>
                  <UnHighlightIcon />
                </Tooltip>
              </button>
              <div style={{ position: "relative" }}>
                <Tooltip title="Add Emoji" arrow>
                  <button
                    className={classes.highlight}
                    onClick={toggleEmojiPicker}
                  >
                    <AddEmoji />
                  </button>
                </Tooltip>

                {showEmojiPicker ? (
                  <ClickAwayListener onClickAway={toggleEmojiPicker}>
                    <EmojiPicker>
                      <Picker
                        set="twitter"
                        onSelect={handleEmojiSelect}
                        style={{
                          position: "absolute",
                          top: "-326px",
                          height: 320,
                          overflow: "hidden",
                        }}
                      />
                    </EmojiPicker>
                  </ClickAwayListener>
                ) : null}
              </div>
            </div>

            <div className={classes.uploadImgContainer}>
              {linkInput && (
                <div style={{ display: "block", width: "100%" }}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      // width: "94%",
                      // paddingTop: 10,
                    }}
                  >
                    <Name
                      name="video_link"
                      label=""
                      variant="outlined"
                      marginTop="unset"
                      width="100%"
                      icon={false}
                      spanMargin="0"
                      placeholder={getVideoPlaceholder(linkType)}
                      value={link}
                      onChangeHandler={(e) => setLink(e.target.value)}
                      error={!!error.linkErr}
                      borderColor={error.linkErr ? "#f44336" : "#1D4E89"}
                    />

                    <Button
                      disableRipple
                      style={{
                        color: "#FF5269",
                        minWidth: "20px",
                        marginLeft: "12px",
                      }}
                      onClick={() => {
                        setError({ ...error, linkErr: "" });
                        handleDeleteVideo(
                          video,
                          post,
                          toEdit,
                          setVideo,
                          setLink,
                          setLinkInput,
                          true
                        );
                      }}
                    >
                      <CancelIcon />
                    </Button>
                  </div>
                  <span className={classes.error}>{error.linkErr}</span>
                </div>
              )}
            </div>
            <div
              style={{
                padding: images.length ? "10px 0px 20px 0px" : "0 0 16px 0",
                width: "94%",
              }}
            >
              <Carousel
                images={images}
                video={video !== "" && !linkInput ? video : link}
                setLink={setLink}
                setVideo={setVideo}
                setLinkInput={setLinkInput}
                iconClass={classes.icon}
                setImages={setImages}
                post={post}
                platform={platform}
                edit={toEdit}
              />
            </div>

            <Grid container spacing={2} item>
              <Grid
                xs={12}
                md={6}
                item
                style={{
                  padding: 7,
                }}
              >
                <div className={classes.field}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                      marginBottom: "5px",
                    }}
                  >
                    <FieldTitle>Creative Content Brief</FieldTitle>
                  </div>
                  <Editable
                    setContent={setGraphicsBrief}
                    setShowEmojiPicker={setShowEmojiPicker}
                    postHtml={graphicsHtml}
                    input={graphicsRef}
                    placeholder="Start writing..."
                  />
                </div>
              </Grid>
              <Grid
                xs={12}
                md={6}
                item
                style={{
                  padding: 7,
                }}
              >
                <div className={classes.field}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                      marginBottom: "5px",
                    }}
                  >
                    <FieldTitle>Actions</FieldTitle>
                  </div>
                  <Editable
                    setContent={setInfo}
                    setShowEmojiPicker={setShowEmojiPicker}
                    postHtml={infoHtml}
                    input={infoRef}
                    placeholder="Start writing..."
                    minHeight={270}
                  />
                </div>
              </Grid>
            </Grid>

            <br></br>
            <Grid container>
              <FieldTitle>Category tags</FieldTitle>
              <AutoTags suggestions={oldTags} tags={tags} setTags={setTags} />
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  width: "100%",
                }}
              >
                {tags.map((tag, index) => {
                  return (
                    <li key={index} style={{ maxWidth: "100%" }}>
                      <Chip
                        label={`#${tag}`}
                        onDelete={deleteTags(tag)}
                        className={classes.chip}
                      />
                    </li>
                  );
                })}
              </div>
            </Grid>

            <br></br>

            <div
              className={classes.field}
              style={{
                width: "100%",
              }}
            >
              <StatusV2
                internalStatusMonthly={internalStatusMonthly}
                setInternalStatusMonthly={setInternalStatusMonthly}
                internalStatusWeekly={internalStatusWeekly}
                setInternalStatusWeekly={setInternalStatusWeekly}
                isVisibletoClient={isVisibletoClient}
                setIsVisibleToClient={setIsVisibleToClient}
              />
            </div>
            <br></br>

            {/* Add more form fields as needed */}
            <Grid container>
              <Grid xs={1} md={6} item></Grid>
              <Grid
                xs={12}
                md={6}
                sm={12}
                item
                container
                justifyContent="flex-end"
              >
                <Grid xs={1} md={1} item>
                  {toEdit && (
                    // <TrashCan style={{ marginLeft: "8px", marginTop: 10 }} />
                    <DeleteV2
                      role={role}
                      id={post.id}
                      handleclick={handleCloseOnSave}
                    />
                  )}
                </Grid>
                <Grid
                  xs={12}
                  md={4}
                  item
                  style={{
                    padding: 5,
                  }}
                >
                  <CancelButton2
                    disableRipple
                    disable={true}
                    onClick={handleCloseOnCancel}
                    style={{
                      width: "100%",
                      height: "45px",
                      padding: "11.5px 8px 11.5px 8px",
                      borderRadius: "15px",
                      // border: "1px solid",
                      color: "#000000",
                      backgroundColor: "white",
                      border: "1px solid #000000 solid",
                    }}
                  >
                    cancel
                  </CancelButton2>
                </Grid>
                <Grid
                  xs={12}
                  md={4}
                  item
                  container
                  justifyContent="flex-end"
                  style={{
                    padding: 5,
                  }}
                >
                  <SendButton
                    onClick={handleSubmit}
                    disabled={isUploading || isGifUploading || isVideoUploading}
                    style={{
                      width: "100%",
                      height: "45px",
                      padding: "11.5px 8px 11.5px 8px",
                      borderRadius: "15px",
                      // border: "1px solid",
                      color: "white",
                      backgroundColor: "black",
                      border: "1px solid black solid",
                    }}
                    disableRipple
                  >
                    {submitting ? (
                      <CircularProgress size={30} color="inherit" />
                    ) : (
                      <span>Save Post</span>
                    )}
                  </SendButton>
                </Grid>
              </Grid>
            </Grid>
          </PostPaper>
        </div>
      </Modal>
    </>
  );
};

export default EditPost;
