// import { navigate } from "@reach/router";
import axios from "axios";

export const fetchAllCustomers = async () => {
  if (!process.env.REACT_APP_FUNCTIONS_BASE_URL) {
    // ("Invalid url");
    return;
  }
  const result = await axios.get(
    `${process.env.REACT_APP_FUNCTIONS_BASE_URL}authentication-getUsers`
  );
  return result.data.users;
};
