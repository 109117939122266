import React, { FunctionComponent } from "react";
import { Grid, Box, createTheme, InputAdornment } from "@material-ui/core";
import LuxonUtils from "@date-io/luxon";
import { MuiPickersUtilsProvider, DateTimePicker } from "@material-ui/pickers";
import { ThemeProvider } from "@material-ui/styles";
import TodayIcon from "@material-ui/icons/Today";

import { useStyles } from "./styles";
import { ParsableDate } from "@material-ui/pickers/constants/prop-types";

interface DateProps {
  handleDateFromChange: (date: any) => void;
  handleDateToChange: (date: any) => void;
  selectedDateFrom: ParsableDate;
  selectedDateTo: ParsableDate;
  startText: string;
  endText: string;
  disableFuture?: boolean;
  setLoading?: (loading: boolean) => void;
}

export const theme = (createTheme as any)({
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: "#1D4E89",
      },
    },
    MuiPickersDay: {
      daySelected: {
        backgroundColor: "#1D4E89",
      },
      current: {
        color: "#1D4E89",
      },
    },
    MuiPickerDTTabs: {
      tabs: {
        backgroundColor: "#1D4E89",
      },
    },
    MuiPickersClockNumber: {
      clockNumberSelected: {
        backgroundColor: "#1D4E89",
      },
    },
    MuiPickersClockPointer: {
      pointer: {
        backgroundColor: "#1D4E89",
      },
    },
    MuiTabs: {
      indicator: {
        backgroundColor: "#093263",
        height: "4px",
      },
    },
    MuiButton: {
      label: {
        color: "#1D4E89",
      },
    },
  },
});

const DateSelector: FunctionComponent<DateProps> = ({
  handleDateFromChange,
  handleDateToChange,
  selectedDateFrom,
  selectedDateTo,
  startText,
  endText,
  disableFuture,
  setLoading,
}) => {
  const classes = useStyles({});
  return (
    <MuiPickersUtilsProvider utils={LuxonUtils}>
      <div
        id="date-picker"
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <Grid style={{ flexGrow: 1, marginRight: 4 }}>
          <Box component="span" className={classes.dateLabel}>
            Start Date
          </Box>
          <Box>
            <ThemeProvider theme={theme}>
              <DateTimePicker
                variant="inline"
                id="date-from"
                value={selectedDateFrom}
                onChange={(e) => {
                  handleDateFromChange(e);
                  setLoading && setLoading(true);
                }}
                disablePast
                helperText=""
                PopoverProps={{
                  disableScrollLock: true,
                }}
                InputProps={{
                  disableUnderline: true,
                  className: classes.dateInput,
                  endAdornment: (
                    <InputAdornment position="end">
                      <TodayIcon />
                    </InputAdornment>
                  ),
                }}
                style={{
                  marginRight: "15px",
                  backgroundColor: "#fff",
                  width: "100%",
                }}
                autoOk
                className="campaign__input"
              />
            </ThemeProvider>
          </Box>
        </Grid>
        <Grid style={{ flexGrow: 1, marginLeft: 4 }}>
          <Box component="span" className={classes.dateLabel}>
            End Date
          </Box>
          <Box>
            <ThemeProvider theme={theme}>
              <DateTimePicker
                variant="inline"
                id="date-to"
                value={selectedDateTo}
                onChange={(e) => {
                  handleDateToChange(e);
                  setLoading && setLoading(true);
                }}
                helperText=""
                minDate={selectedDateFrom}
                PopoverProps={{
                  disableScrollLock: true,
                }}
                InputProps={{
                  disableUnderline: true,
                  className: classes.dateInput,
                  endAdornment: (
                    <InputAdornment position="end">
                      <TodayIcon />
                    </InputAdornment>
                  ),
                }}
                autoOk
                className="campaign__input"
                style={{ backgroundColor: "#fff", width: "100%" }}
              />
            </ThemeProvider>
          </Box>
        </Grid>
      </div>
    </MuiPickersUtilsProvider>
  );
};

export default DateSelector;
