import {
  CircularProgress,
  Grid,
  IconButton,
  MenuItem,
  Modal,
  Select,
  Typography,
  makeStyles,
} from "@material-ui/core";
import React, { useRef, useState } from "react";
import CloseIcon from "@material-ui/icons/Close";
import { FieldTitle } from "components/styled/CreateEditPost";
import { ReactComponent as StarIcon } from "../../assets/Stars.svg";
import { ReactComponent as ChatGptIcon } from "../../assets/chatgptIcon.svg";
import Editable from "components/ContentEditableV2";
import Axios from "axios";

const useStyles = makeStyles((theme) => ({
  // Add any additional styles you may need
  formContainer: {
    backgroundColor: "white",
    padding: theme.spacing(2),
    borderRadius: "8px",
    boxShadow: theme.shadows[5],
    outline: "none",
  },
  // Add any additional styles you may need for the form
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    overflowY: "auto",
  },
  form: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(3),
    width: "984px",
    height: "100%",
    minHeight: "109px", // Assuming "hug" means auto
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    outline: "none",
    overflowY: "auto",
  },
  closeIcon: {
    position: "absolute",
    top: theme.spacing(1),
    right: theme.spacing(1),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  chip: {
    margin: "4px 4px 0px 0px",
    maxWidth: "100%",
    fontFamily: "'Avenir Book', sans-serif",
  },
  choose: {
    display: "flex",
    alignItems: "center",
    border: "2px solid #ABADAD",
    // border: (props: any) =>
    //   props.dropdown ? "2px solid #000" : "2px solid #ABADAD",
    width: "100%",
    borderRadius: "10px",
    marginTop: "5px",
    "&:hover": {
      border: "2px solid #000",
    },
  },

  input: {
    width: "94%",
    padding: "12.5px",
    border: "2px solid #ABADAD",
    boxSizing: "border-box",
    borderRadius: "10px",
    cursor: "pointer",
    fontFamily: "'Avenir Book', sans-serif",
    lineHeight: "20px",
    fontSize: "15px",
    "&:focus": {
      border: "2px solid #000",
      outline: "none",
    },
  },
  field: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    // marginTop: "26px",
    transition: "all 0.3s ease",
  },
  uploadIcons: {
    display: "flex",
    // paddingTop: 5,
    alignItems: "end",
    // justifyContent: "flex-end",
    width: "94%",
    "& > *": {
      cursor: "pointer",
    },
  },
  error: {
    color: "#f44336",
    fontSize: "15px",
    textAlign: "center",
    width: "75%",
  },
  highlight: {
    border: "none",
    background: "none",
    padding: "0px",
    "&:active, &:focus": {
      border: "none",
      outline: "none",
      padding: "0px",
    },
  },

  uploadImgContainer: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    // marginTop: 12,
    width: "94%",
  },

  icon: {
    color: "#000",
    cursor: "pointer",
    fontSize: "30px",
    fontWeight: "bold",
    transition: "all .3s ease",
  },
  loadingIconContainer: {
    display: "inline-block",
    animation: "$pulse 1.5s infinite alternate", // Reference the pulse animation
  },

  loadingIcon: {
    width: "30px", // Adjust the initial size of the icon
    height: "30px", // Adjust the initial size of the icon
    transition: "all 0.3s ease-in-out", // Smooth transition for size change
  },

  loadingText: {
    marginLeft: "8px", // Add some spacing between the icon and text
  },

  "@keyframes pulse": {
    to: {
      transform: "scale(1.2)", // Adjust the scale factor for the pulse effect
    },
  },
  savebtn: {
    marginTop: "10px",
    width: "150px",
    height: "45px",
    padding: "11.5px 8px 11.5px 8px",
    borderRadius: "15px",
    border: "1px solid",
    backgroundColor: "#000",
    cursor: "pointer",
    position: "relative",
    display: "flex",
    justifyContent: "center",
    color: "white",
    fontSize: "15px",
    fontWeight: 900,
    fontFamily: "Avenir",
    alignItems: "center",
    "&:disabled": {
      backgroundColor: "#888",
      cursor: "not-allowed",
      color: "#666",
    },
  },
  chatgpticonContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%", // Optional: Set height for full-height centering
  },
  "@media (max-width: 600px)": {
    chatgpticonContainer: {
      height: "50vh", // Adjust the height for smaller screens
    },
  },
  label: {
    marginTop: "5px",
  },
}));

interface IProp {
  openAi: boolean;
  setOpenAi: (data: any) => void;
  setPostText: (data: any) => void;
  setOpen: (data: any) => void;
  handleOptionClickCreate: (data: any) => void;
  setTypedData: (data: any) => void;
  setSelectedOption: (data: any) => void;
  selectedOption: number;
}

enum Tone {
  NEUTRAL = "neutral",
  FRIENDLY = "friendly",
  FORMAL = "formal",
  INFORMAL = "informal",
  PROFESSIONAL = "professional",
}

enum PostType {
  GENERAL = "general",
  NEWS = "news",
  STORY = "story",
  TECHNICAL = "technical",
  INSTAGRAM_POST = "instagram post",
  FACEBOOK_POST = "facebook post",
  TWITTER_POST = "twitter post",
}

const ChatGptPostGenerator = ({
  openAi,
  setOpenAi,
  setPostText,
  setOpen,
  handleOptionClickCreate,
  setTypedData,
  selectedOption,
  setSelectedOption,
}: IProp) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  // const [selectedOption, setSelectedOption] = useState(2);

  // const [formData, setFormData] = useState({
  //   // Add your form fields here
  //   title: "",
  //   content: "",
  // });

  const handleClose = () => {
    setOpenAi(false);
  };

  const getOptionStyle = (option) => {
    return {
      fontFamily: "Avenir",
      fontSize: "21px",
      fontWeight: 900,
      lineHeight: "24px",
      cursor: "pointer",
      textDecoration: selectedOption === option ? "underline" : "none",
      color: selectedOption === option ? "#000000" : "#606060",
    };
  };

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   handleClose(); // Close the modal after submission
  // };

  // const [postText, setPostText]: any = useState("");
  const [promptText, setPromptText]: any = useState("");
  const [generatedPost, setGeneratedPost] = useState("");
  const [, setShowEmojiPicker] = useState(false);
  const [postHtml] = useState("");
  const [selectedTone, setSelectedTone] = useState(Tone.NEUTRAL); // Initialize with a default tone
  const [selectedPostType, setSelectedPostType] = useState(PostType.GENERAL);
  const postRef = useRef(null);
  const generatedPostRef = useRef(null);

  const generateChatGptPost = async (e: Event) => {
    e.preventDefault();
    setLoading(true);
    try {
      let res = await Axios.post(
        `${process.env.REACT_APP_FUNCTIONS_BASE_URL}chatgpt-generatePost`,
        {
          message: promptText,
          tone: selectedTone,
          postType: selectedPostType,
          wordLength: 200,
        }
      );
      setGeneratedPost(res.data.post);
    } catch (error) {
      // Handle error if needed
      console.error("Error generating post:", error);
    } finally {
      setLoading(false);
    }
  };

  const savePost = () => {
    setPostText(generatedPost);
    setTypedData(generatedPost);
    handleClose();
    handleOptionClickCreate(1);
    setOpen(true);
  };

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };
  const formatDateTime = (date) => {
    const options = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };

    return date.toLocaleDateString("en-US", options);
  };

  const handleOptionClick = (option: number) => {
    setSelectedOption(option);
    setOpenAi(false);
    setOpen(true);
    handleClose(); //
  };
  return (
    <Modal
      className={classes.modal}
      open={openAi}
      onClose={handleClose}
      aria-labelledby="chatgpt-post-generator-title"
      aria-describedby="chatgpt-post-generator-description"
    >
      <div className={classes.formContainer}>
        <form
          className={classes.form}
          onSubmit={(e: any) => generateChatGptPost(e)}
        >
          <IconButton className={classes.closeIcon} onClick={handleClose}>
            <CloseIcon />
          </IconButton>
          <Typography
            variant="h6"
            gutterBottom
            style={{
              fontFamily: "Avenir",
              fontSize: "30px",
              fontWeight: 900,
              lineHeight: "41px",
              letterSpacing: "0em",
              textAlign: "left",
              padding: "10px",
            }}
          >
            New post
          </Typography>
          <Grid
            container
            spacing={2}
            style={{
              padding: "10px",
            }}
          >
            <Grid item xs={3} md={1}>
              <span
                onClick={() => handleOptionClick(1)}
                style={getOptionStyle(1)}
              >
                Create
              </span>
            </Grid>
            <Grid item xs={6} md={3}>
              <span
                // onClick={() => handleOptionClick(2)}
                style={getOptionStyle(2)}
              >
                AI Generator
              </span>
            </Grid>
          </Grid>
          <br />
          <Grid container>
            <Grid
              xs={12}
              md={6}
              item
              style={{
                padding: "10px",
              }}
            >
              <div>
                <FieldTitle>What do you need to generate</FieldTitle>

                <Select
                  id="postTypeSelect"
                  variant="outlined"
                  value={selectedPostType}
                  className={classes.label}
                  onChange={(event) =>
                    setSelectedPostType(event.target.value as PostType)
                  }
                  style={{
                    width: "100%",
                    border: "1px solid #E8E8E8",
                    borderRadius: "15px",
                  }}
                >
                  {Object.values(PostType).map((postType) => (
                    <MenuItem key={postType} value={postType}>
                      {capitalizeFirstLetter(postType)}
                    </MenuItem>
                  ))}
                </Select>
              </div>

              <div style={{ marginTop: "10px" }}>
                <FieldTitle>Tone</FieldTitle>
                <Select
                  className={classes.label}
                  id="toneSelect"
                  variant="outlined"
                  value={selectedTone}
                  onChange={(event) =>
                    setSelectedTone(event.target.value as Tone)
                  }
                  style={{
                    width: "100%",
                    border: "1px solid #E8E8E8",
                    borderRadius: "15px",
                  }}
                >
                  {Object.values(Tone).map((tone) => (
                    <MenuItem key={tone} value={tone}>
                      {capitalizeFirstLetter(tone)}
                    </MenuItem>
                  ))}
                </Select>
              </div>

              <div style={{ marginTop: "10px" }}>
                <FieldTitle>Write Your Prompt Here</FieldTitle>
                <div
                  className={classes.choose}
                  style={{ border: "none" }}
                  //   ref={postTitleErrorRef}
                >
                  <Editable
                    setContent={setPromptText}
                    setShowEmojiPicker={setShowEmojiPicker}
                    postHtml={postHtml}
                    input={postRef}
                    placeholder="Start writing..."
                  />
                </div>
              </div>
              <button
                type="submit"
                style={{
                  marginTop: "10px",
                  width: "150px",
                  height: "45px",
                  padding: "11.5px 8px 11.5px 8px",
                  borderRadius: "15px",
                  border: "1px solid",
                  backgroundColor: "white",
                  cursor: "pointer",
                  position: "relative", // Add position relative for proper positioning of loader
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                disabled={loading || !promptText}
              >
                {loading && (
                  <CircularProgress size={24} style={{ marginRight: "8px" }} />
                )}
                {!loading ? "Generate" : "Generating..."}
              </button>
            </Grid>
            <Grid
              xs={12}
              md={6}
              item
              style={{
                padding: "10px",
              }}
            >
              {!generatedPost && (
                <Grid container className={classes.chatgpticonContainer}>
                  <ChatGptIcon />
                </Grid>
              )}
              {generatedPost && (
                <>
                  <Grid container>
                    <Grid xs={12} md={3} item></Grid>
                    <Grid
                      xs={12}
                      md={9}
                      item
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        color: "rgba(0, 0, 0, 0.54)",
                        fontSize: "15px",
                        fontFamily: "Avenir",
                        fontWeight: 350,
                      }}
                    >
                      {formatDateTime(new Date())}
                    </Grid>
                  </Grid>
                  <FieldTitle>You</FieldTitle>
                  <p>{promptText}</p>

                  <br />
                  <Grid container style={{ marginBottom: "20px" }}>
                    <Grid xs={6} md={6} item>
                      {" "}
                      <FieldTitle style={{ marginTop: "20px" }}>
                        {" "}
                        <span>
                          <StarIcon />
                        </span>
                        <span style={{ padding: "10px" }}>AI Generator</span>
                      </FieldTitle>
                    </Grid>
                    <Grid
                      xs={6}
                      md={6}
                      item
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                    >
                      <button
                        type="submit"
                        className={classes.savebtn}
                        disabled={loading || !generatedPost}
                        onClick={savePost}
                      >
                        Add to Post
                      </button>
                    </Grid>
                  </Grid>

                  {loading && (
                    <Grid
                      container
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "140px",
                      }}
                    >
                      <div className={classes.loadingIconContainer}>
                        <StarIcon className={classes.loadingIcon} />
                      </div>
                      <span className={classes.loadingText}>
                        Generating Post...
                      </span>
                    </Grid>
                  )}

                  {!loading && (
                    <Editable
                      setContent={setGeneratedPost}
                      setShowEmojiPicker={setShowEmojiPicker}
                      postHtml={generatedPost}
                      input={generatedPostRef}
                      placeholder="Start writing..."
                      maxHeight={250}
                    />
                  )}
                </>
              )}
            </Grid>
          </Grid>
        </form>
      </div>
    </Modal>
  );
};

export default ChatGptPostGenerator;
