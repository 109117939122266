import { Grid } from "@material-ui/core";
import Chart from "react-apexcharts";
import { IndicatorProps } from "components/BeMeasured/MetricsIndicatorV2";
import { useStylesV2 } from "components/BeMeasured/stylesV2";
import moment from "moment";
import React, { FunctionComponent, useState, useEffect } from "react";
import { numberWithCommas } from "utils/numberFormat";
import CharTitle from "./ChartTitle";
import ArrowUpwardOutlinedIcon from "@material-ui/icons/ArrowUpwardOutlined";
import { area } from "components/BeMeasured/ChartV2/chartOptions";
import { formatDate, formatDateNew } from "utils/dateFormat";

interface ChartProps extends IndicatorProps {
  prevData: any;
  prevLoading: boolean;
  dateGraph: number[];
  metricsData?: any;
}
const TikTokGraph: FunctionComponent<ChartProps> = ({
  metrics: fullMetrics,
  icon,
  role,
  name,
  url,
  prevData,
  prevLoading,
  dateRange: dateRangeData,
  dateGraph,
  metricsData,
}) => {
  const midpoint = Math.ceil(fullMetrics.series[0].data.length / 2);

  const seriesArrayBeforeDate = fullMetrics.series[0].data.slice(0, midpoint);

  const seriesArrayCurrentDate = fullMetrics.series[0].data.slice(midpoint);

  let metrics = fullMetrics;
  metrics.series[0].data = seriesArrayCurrentDate;
  const dataLength = metrics.series[0].data.length;
  const [growth, setGrowth]: any = useState(0);
  const [dateRange, setDateRange] = useState("") as any;
  const [comparedDateRange, setComparedDateRange] = useState("") as any;
  const [diffData, setDiffData] = useState("") as any;

  let currentValue;
  let countValue;
  const data = metrics.series[0].data;
  const nameList = [
    "Follower Count",
    "Subscriber Count",
    "Total Likes",
    "Total Videos",
    "Listeners",
    "Streams",
    "Streams Per Followers",
  ];

  if (name) {
    currentValue = data.reduce((a, b) => a + b, 0);

    if (nameList.includes(name)) {
      countValue = data[data.length - 1];
    }
  }

  let previousValue;

  const periodStart =
    seriesArrayBeforeDate && seriesArrayBeforeDate[0] < 1
      ? seriesArrayBeforeDate[0]
      : parseFloat(seriesArrayBeforeDate && seriesArrayBeforeDate[0]);
  const periodEnd =
    data && data[dataLength - 1] < 1
      ? data[dataLength - 1]
      : parseFloat(data && data[dataLength - 1]);
  let change = periodEnd - periodStart ?? 0;

  const classes = useStylesV2({
    color:
      (name && nameList.includes(name) && (change > 0 || change === 0)) ||
      (currentValue - previousValue) / previousValue > 0 ||
      currentValue - previousValue === 0
        ? "#76EC97"
        : "red",
  });

  const getLegendContent = () => {
    if (name && ["Follower Count"].includes(name)) return "Total Followers";
    if (name && ["Total Likes"].includes(name)) return "Total Likes";
    if (name && ["Total Videos"].includes(name)) return "Total Videos";
    return "";
  };

  const formatDateString = (dateString, dateString2) => {
    const options = { day: "numeric", month: "short" } as any;
    const date = new Date(dateString);
    const date2 = new Date(dateString2);
    let val1 = date.toLocaleDateString("en-US", options);
    let val2 = date2.toLocaleDateString("en-US", options);
    return `Compared to ${val1} - ${val2}`;
  };

  const formatDateRange = (startDateString: any, endDateString: any) => {
    const startDate = new Date(startDateString);
    const endDate = new Date(endDateString);
    const options: any = { day: "numeric", month: "short" };

    const formattedStartDate = startDate.toLocaleDateString("en-US", options);
    const formattedEndDate = endDate.toLocaleDateString("en-US", options);

    return `${formattedStartDate} ${startDate.getFullYear()} - ${formattedEndDate} ${endDate.getFullYear()}`;
  };

  const calculatePercentageGrowth = (
    oldValue: number,
    newValue: number
  ): number => {
    if (oldValue === 0) {
      // Handle the case where the old value is 0 to avoid division by zero
      return newValue === 0 ? 0 : Infinity;
    }

    const percentageGrowth = ((newValue - oldValue) / Math.abs(oldValue)) * 100;
    return percentageGrowth;
  };

  const midpoint2 = Math.ceil(metricsData.dates.length / 2);

  let metricsDates = metricsData.dates.slice(midpoint2);

  const dateLength = metricsDates?.length;

  const areaOptions = {
    ...area.options,
    markers:
      dateLength > 30
        ? {}
        : {
            size: 5,
            colors: ["white"],
            strokeColor: "#5187E0",
            strokeWidth: 2,
          },
    xaxis: {
      categories:
        dateLength > 30
          ? formatDateNew(metricsData.dates)
          : formatDate(metricsData.dates),
      type: dateLength > 30 ? "datetime" : "date",
      labels: {
        datetimeUTC: false,
      },
    },
  };

  // update growth rate
  useEffect(() => {
    if (!!Object.keys(prevData).length) {
      if (
        name &&
        ["Follower Count", "Total Likes", "Total Videos"].includes(name)
      ) {
        const firstCount = seriesArrayCurrentDate[0];
        const currentCount =
          seriesArrayCurrentDate[seriesArrayCurrentDate.length - 1];

        let diffVal = currentCount - firstCount;

        setDiffData(diffVal);
        const growthRate = calculatePercentageGrowth(firstCount, currentCount);
        setGrowth(growthRate);

        setDateRange(
          formatDateRange(dateRangeData.startDate, dateRangeData.endDate)
        );

        // const olderFrom = moment(from).su
        const newStartDate = moment(dateRangeData.startDate);
        const newEndDate = moment(dateRangeData.endDate);

        setComparedDateRange(formatDateString(newStartDate, newEndDate));
      }
    }

    // eslint-disable-next-line
  }, [prevData]);

  return (
    <div id={`${role}-${name}`}>
      <div className={classes.chartContainer}>
        <CharTitle
          icon={icon}
          role={role}
          name={name}
          url={url}
          dateRange={dateRange}
        />
        <Grid container className={classes.uispacing}>
          <Grid xs={2} md={2} className={classes.innerChartText} item>
            {name &&
              nameList.includes(name) &&
              numberWithCommas(Number(countValue))}
          </Grid>
          <Grid xs={1} md={1} item></Grid>

          <Grid
            xs={2}
            md={2}
            item
            className={classes.innerChartText}
            style={{
              minWidth: 100,
            }}
          >
            {Number(diffData) > 0 && "+"}
            {numberWithCommas(Number(diffData))}
          </Grid>
          <Grid
            xs={2}
            md={2}
            item
            style={{
              marginRight: 10,
              marginLeft: 10,
              color: "#782121",
            }}
          >
            <span
              style={{
                backgroundColor: Number(growth) > 0 ? "#EBFAEB" : "#FAEBEB",
                color: Number(growth) > 0 ? "#237821" : "#782121",
                fontWeight: 900,
                fontFamily: "Avenir",
                padding: "2px 4px",
                borderRadius: 4,
              }}
            >
              {Number(growth) > 0 && (
                <ArrowUpwardOutlinedIcon
                  style={{
                    width: 7.92,
                    height: 7.92,
                  }}
                />
              )}
              {name && (
                <span
                  style={{
                    fontSize: 12,
                  }}
                >
                  {growth.toLocaleString("en", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }) + "%"}
                </span>
              )}
            </span>
          </Grid>
        </Grid>
        <Grid container className={classes.areaChartLegend2}>
          <Grid xs={3} md={3} item>
            {getLegendContent()}
          </Grid>

          <Grid xs={4} md={4} item>
            {" "}
            {![
              "Follower Count",
              "Followers",
              "Total Likes",
              "Total Videos",
            ].includes(name as any) && comparedDateRange}
          </Grid>
        </Grid>

        {seriesArrayCurrentDate.length && (
          <Chart
            options={{
              ...areaOptions,
            }}
            series={metrics.series ?? metrics.series}
            type="area"
            height={250}
          />
        )}
      </div>
    </div>
  );
};

const areEqual = (prevProps, nextProps): any => {
  if (prevProps.metrics === nextProps.metrics) {
    return true;
  }
};

export default React.memo(TikTokGraph, areEqual);
