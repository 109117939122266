import Axios from "axios";
import { DB } from "firebase-tools/db";

export const fetchFbIGMetrics = async (
  collection: string,
  username: string,
  from,
  to
) => {
  if (collection === "instagram-metrics") {
    // ("fetching ig metrics");
    try {
      const res = await Axios.post(
        `${process.env.REACT_APP_FUNCTIONS_BASE_URL}instagram-getMetrics`,
        {
          username: username,
          startDate: from,
          endDate: to,
        }
      );

      const results = res.data.data;
      return results;
    } catch (e) {
      // (e);
      return [];
    }
  } else if (collection === "facebook-metrics") {
    try {
      const res = await Axios.post(
        `${process.env.REACT_APP_FUNCTIONS_BASE_URL}facebook-getMetrics`,
        {
          username: username,
          startDate: from,
          endDate: to,
        }
      );

      let results = res.data.data;

      return results;
    } catch (e) {
      // (e);
      return [];
    }
  }

  const db = new DB();
  const results = await db.metricsByDate(
    collection,
    username,
    from,
    to,
    "status",
    "complete"
  );
  return results;
};

export const fetchMetrics = async (
  collection: string,
  username: string,
  from,
  to
) => {
  if (collection === "spotify-metrics") {
    try {
      let res = await Axios.post(
        `${process.env.REACT_APP_FUNCTIONS_BASE_URL}spotify-getMetrics`,
        {
          username: username,
          startDate: from,
          endDate: to,
        }
      );
      let results = res.data.data;
      return results;
    } catch (error) {
      // (error);
      return [];
    }
  } else if (collection === "youtube-metrics") {
    try {
      const data = await Axios.post(
        `${process.env.REACT_APP_FUNCTIONS_BASE_URL}youtube-dbMetric`,
        {
          username: username,
          from,
          to,
        }
      );
      const results = data.data;
      return results;
    } catch (error) {
      // (error);
      return [];
    }
  }
  const db = new DB();
  const results = await db.metricsByDate(collection, username, from, to);
  return results;
};
export const fetchTiktokMetrics = async (
  username: string,
  id: any,
  from: any,
  to: any
) => {
  let res = await Axios.post(
    `${process.env.REACT_APP_FUNCTIONS_BASE_URL}tiktok-getMetrics`,
    {
      username,
      userId: id,
      startDate: from,
      endDate: to,
    }
  );
  let results = res.data.data;

  return results;
};
export const fetchTwitterMetrics = async (
  username: string,
  from: any,
  to: any
) => {
  let res = await Axios.post(
    `${process.env.REACT_APP_FUNCTIONS_BASE_URL}twitter-getMetric`,
    {
      username,
      startDate: from,
      endDate: to,
    }
  );
  let results = res.data.data;

  return results;
};
