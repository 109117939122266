/**
 * @prettier
 */

import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import "firebase/functions";

(window as any).firebase = firebase;

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECTID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDERID,
  appId: process.env.REACT_APP_APPID,
  measurementId: process.env.REACT_APP_MEASUREMENTID,
};

// Initialize Firebase app
let firebaseApp;
if (!firebase.apps.length) {
  console.log("Firebase initialized");
  firebaseApp = firebase.initializeApp(firebaseConfig);
}

const auth = firebaseApp.auth();
const firestore = firebaseApp.firestore();
const storage = firebaseApp.storage();

// Optionally configure Cloud Functions
const functions = firebaseApp.functions();

// Enable offline persistence
firestore.enablePersistence({ synchronizeTabs: true }).catch((err) => {
  if (err.code === "failed-precondition") {
    console.error("Persistence failed: Multiple tabs open.");
  } else if (err.code === "unimplemented") {
    console.error("Persistence is not available in this browser.");
  }
});

export { firebase, auth, firestore, storage, functions };
