import React, { FC, useState } from "react";
import Autosuggest from "react-autosuggest";

import { theme } from "./theme";

const escapeRegexCharacters = (str) =>
  str.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");

interface SuggestProps {
  options: Array<string>;
  setValue: (value) => void;
  value: string;
  placeholder: string;
  addChannel?: boolean;
  setAddChannel?: (value) => void;
  otherChannels?: Array<string>;
  selectedChannels?: Array<string>;
  setSelectedChannels?: (value) => void;
}

export const AutoSuggest: FC<SuggestProps> = ({
  options,
  setValue,
  value,
  placeholder,
  addChannel,
  setAddChannel,
  otherChannels,
  selectedChannels,
  setSelectedChannels,
}) => {
  const [suggestions, setSuggestions]: any = useState([]);

  const getSuggestions = (value) => {
    const escapedValue = escapeRegexCharacters(value.trim());

    if (escapedValue === "") {
      return [];
    }

    const regex = new RegExp("^" + escapedValue, "i");
    const suggestions = options.filter((option) => regex.test(option));

    if (suggestions.length === 0) {
      return [{ isAddNew: true }];
    }

    return suggestions;
  };

  const onChange = (event, { newValue, method }) => {
    setValue(newValue.toLowerCase());
  };

  const getSuggestionValue = (suggestion) => {
    if (suggestion.isAddNew) {
      return value;
    }

    return suggestion;
  };

  const renderSuggestion = (suggestion) => {
    if (suggestion.isAddNew) {
      return (
        <span>
          [+] Add new: <strong>{value}</strong>
        </span>
      );
    }

    return suggestion;
  };

  const onSuggestionsFetchRequested = ({ value }) => {
    setSuggestions(getSuggestions(value));
  };

  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const onSuggestionSelected = (event, { suggestion }) => {
    if (suggestion.isAddNew) {
      // ("Add new:", value);
      if (otherChannels && selectedChannels) {
        const channels = [...otherChannels];
        channels.push(value);

        const channelsSelected = [...selectedChannels];
        channelsSelected.push(value);

        setSelectedChannels && setSelectedChannels(channelsSelected);
      }
    } else {
      if (otherChannels && selectedChannels) {
        const channels = [...otherChannels];
        channels.push(suggestion);

        const channelsSelected = [...selectedChannels];
        channelsSelected.push(suggestion);

        setSelectedChannels && setSelectedChannels(channelsSelected);
      }
    }

    setAddChannel && setAddChannel(!addChannel);
    setValue("");
  };

  const inputProps = {
    placeholder,
    value,
    onChange: onChange,
  };

  return (
    <Autosuggest
      suggestions={suggestions}
      onSuggestionsFetchRequested={onSuggestionsFetchRequested}
      onSuggestionsClearRequested={onSuggestionsClearRequested}
      getSuggestionValue={getSuggestionValue}
      renderSuggestion={renderSuggestion}
      onSuggestionSelected={onSuggestionSelected}
      inputProps={inputProps}
      theme={theme}
    />
  );
};
