/**
 * @prettier
 */
import React, {
  FunctionComponent,
  useState,
  useEffect,
  useCallback,
  useRef,
} from "react";
import {
  Paper,
  TableContainer,
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableRow,
  Grid,
  Box,
  Tooltip,
} from "@material-ui/core";
import moment from "moment";
import { DateRange } from "materialui-daterange-picker";
import styled from "styled-components";
import history from "history/browser";
import InfoIcon from "@material-ui/icons/Info";

import { useStyles } from "./styles";
import { HealthCheck } from "components/HealthCheck/HealthCheck";
import { PageType } from "types/PageType";
import DateRangePicker from "components/Common/DateRange";
// import { DB } from "firebase-tools/db";
import CustomLoader from "../../components/CustomLoader";
import { IUser } from "interfaces/IUser";
import { platforms, metricsData } from "./MetaData";
import { columns } from "./Columns";
import { urlDates } from "utils/dateFormat";
import useStickyHeader from "components/Common/UseStickyHeader";
import HealthCheckStyled from "components/styled/HealthCheck";
import authService from "helpers/user/auth.service";

const Title = styled.div`
  @media screen and (max-width: 500px) {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    /* padding: 70px 0; */
  }
`;

export const HealthCheckPage: FunctionComponent<PageType> = ({ location }) => {
  const classes = useStyles({});
  const [dateRange, setDateRange] = React.useState<DateRange>({
    startDate: moment().subtract(7, "days").startOf("day").toDate(),
    endDate: moment().subtract(1, "days").startOf("day").toDate(),
  });
  const [users, fetchUsers] = useState([] as Array<IUser>);
  const [loading, setLoading] = useState(false);
  const [checkedPlatform, setChecked] = useState({
    Facebook: true,
    Instagram: true,
    Twitter: true,
    TikTok: true,
    YouTube: true,
    "Apple Music": true,
    Spotify: true,
  });
  const [metrics, setMetrics] = useState({
    Posts: true,
    Growth: true,
    Engagement: true,
    Reels: true,
    Stories: true,
    Listeners: true,
  });
  const [paramsChange, setParamsChange]: any = useState("");
  // const [lastUpdated, setLastUpdated] = useState<{ [x: string]: any }>({});

  const unmounted = useRef(false);
  const { tableRef, isSticky } = useStickyHeader();

  const getUsers = useCallback(async () => {
    // const db = new DB();
    setLoading(true);
    // await db
    //   .get("users", "firstName", "asc")
    await authService
      .fetchAllCustomers()
      .then((users) => {
        const clients: any = [];
        users.map((user) => {
          if (
            ((user.facebook?.username && user.facebook.username > "") ||
              (user.instagram?.username && user.instagram.username > "") ||
              (user.twitter?.username && user.twitter.username > "")) &&
            !user.isArchived &&
            !user.isExternal
          ) {
            return clients.push(user);
          }
          return false;
        });
        fetchUsers(clients);
        setLoading(false);
      })
      .catch((error) => {
        // (error);
      });
  }, []);

  const handleChange = (checked, platform) => {
    setChecked({ ...checkedPlatform, [platform]: checked });
  };

  const handleChangeMetrics = (checked, metric) => {
    setMetrics({ ...metrics, [metric]: checked });
  };

  useEffect(() => {
    urlDates(dateRange, history, paramsChange, setDateRange, location);
    getUsers();
    // eslint-disable-next-line

    return () => {
      unmounted.current = true;
    };
  }, [dateRange, getUsers, paramsChange, location]);

  // const getLastUpdated = async () => {
  //   const db = new DB();
  //   try {
  //     const [
  //       instagram,
  //       facebook,
  //       twitter,
  //       tiktok,
  //       youtube,
  //       spotify,
  //     ] = await Promise.all([
  //       db.getLastUpdated("instagram-metrics"),
  //       db.getLastUpdated("facebook-metrics"),
  //       db.getLastUpdated("twitter-metrics"),
  //       db.getLastUpdated("tiktok-metrics"),
  //       db.getLastUpdated("youtube-metrics"),
  //       db.getLastUpdated("spotify-metrics"),
  //     ]);

  //     setLastUpdated({
  //       instagram: instagram ? instagram.toLocaleString() : "N/A",
  //       facebook: facebook ? facebook.toLocaleString() : "N/A",
  //       twitter: twitter ? twitter.toLocaleString() : "N/A",
  //       tiktok: tiktok ? tiktok.toLocaleString() : "N/A",
  //       youtube: youtube ? youtube.toLocaleString() : "N/A",
  //       spotify: spotify ? spotify.toLocaleString() : "N/A",
  //     });
  //   } catch (e) {
  //     console.error(e);
  //   }
  // };

  // useEffect(() => {
  //   getLastUpdated();
  // }, []);

  return (
    <div
      id="root-healthcheck"
      className={classes.root}
      style={{ paddingBottom: "50px" }}
    >
      <HealthCheckStyled id="health-check">
        <Title className={classes.pageTitle}>
          <span style={{ marginTop: 32, fontWeight: 900 }}>Health Check</span>
          <div style={{ display: "flex", marginTop: 15 }} id="selector">
            <DateRangePicker
              disableFuture
              dateRange={dateRange}
              setDateRange={setDateRange}
              setParamsChange={setParamsChange}
            />
          </div>
        </Title>
        <Grid container item xs={12} lg={12}>
          <Grid item className="choose-platform">
            <Box
              className={classes.actionLabel}
              style={{ display: "flex", alignItems: "center" }}
            >
              <p style={{ margin: "0 6px 0 0" }}>Choose platform</p>
              {/* <Tooltip
                title={
                  <div>
                    <h3>Last Updated</h3>
                    <div>
                      <strong>Instagram: </strong> {lastUpdated.instagram}
                    </div>
                    <br />
                    <div>
                      <strong>Facebook: </strong> {lastUpdated.facebook}
                    </div>
                    <br />
                    <div>
                      <strong>Twitter: </strong> {lastUpdated.twitter}
                    </div>
                    <br />
                    <div>
                      <strong>Tiktok: </strong> {lastUpdated.tiktok}
                    </div>
                    <br />
                    <div>
                      <strong>Youtube: </strong> {lastUpdated.youtube}
                    </div>
                    <br />
                    <div>
                      <strong>Spotify: </strong> {lastUpdated.Spotify}
                    </div>
                    <br />
                  </div>
                }
                arrow
                interactive
                classes={{ tooltip: classes.info, arrow: classes.arrow }}
              >
                <InfoIcon
                  style={{
                    cursor: "pointer",
                    color: "slategray",
                  }}
                />
              </Tooltip> */}
            </Box>
            <Box className={`${classes.bar} ${classes.barPlatform}`}>
              {platforms.map((platform, index) => (
                <Box key={index} className="platform-checkbox">
                  {platform.component(
                    checkedPlatform[platform.title],
                    platform.title,
                    handleChange
                  )}
                  {platform.title}
                </Box>
              ))}
            </Box>
          </Grid>
          <Grid item>
            <Box
              className={classes.actionLabel}
              style={{ display: "flex", alignItems: "center" }}
            >
              <p style={{ margin: "0 6px 0 0" }}>Choose metrics</p>
              <Tooltip
                title={
                  <div>
                    <div>
                      <strong>Followers Growth:</strong> This is the change in
                      followers over the selected period.
                    </div>
                    <br />
                    <div>
                      <strong>Engagement Rate:</strong> This is the average rate
                      of engagement of the daily values over the selected
                      period.
                    </div>
                    <br />

                    <div>
                      <strong>Number of Posts:</strong> This is the total number
                      of posts over the selected period.
                    </div>
                    <br />
                    <p>
                      The numbers in parenthesis on the second line are the
                      growth rate compared to the previous period of equal
                      duration.
                    </p>
                  </div>
                }
                arrow
                interactive
                classes={{ tooltip: classes.info, arrow: classes.arrow }}
              >
                <InfoIcon
                  style={{
                    cursor: "pointer",
                    color: "slategray",
                  }}
                />
              </Tooltip>
            </Box>
            <Box className={classes.bar}>
              {metricsData.map((metric, index) => (
                <Box
                  key={index}
                  style={{ marginRight: "40px", marginLeft: "-9px" }}
                >
                  {metric.component(
                    metrics[metric.metric],
                    metric.metric,
                    handleChangeMetrics
                  )}
                  {metric.title}
                </Box>
              ))}
            </Box>
          </Grid>
        </Grid>

        {!loading ? (
          <>
            <TableContainer
              component={Paper}
              classes={{
                root: classes.root,
              }}
            >
              <Table
                aria-label="sticky table"
                className={classes.table}
                stickyHeader={isSticky ? true : false}
                ref={tableRef}
              >
                <TableHead>
                  <TableRow className={classes.row} style={{ height: 120 }}>
                    {columns
                      .filter(
                        (column: any) =>
                          column.title === "Client" ||
                          (Object.keys(checkedPlatform)
                            .filter((item) => checkedPlatform[item])
                            .indexOf(column.columnType) > -1 &&
                            Object.keys(metrics)
                              .filter((item) => metrics[item])
                              .indexOf(column.metrics) > -1)
                      )
                      .map((column, index) => (
                        <TableCell
                          key={index}
                          align="center"
                          style={{
                            width: column.title === "Client" ? 230 : "inherit",
                            left:
                              column.title === "Client" || isSticky === true
                                ? 0
                                : "initial",
                            zIndex:
                              column.title === "Client"
                                ? 3
                                : isSticky === true
                                ? 2
                                : "initial",
                            position:
                              column.title === "Client" || isSticky === true
                                ? "sticky"
                                : "relative",
                            background: "#fff",
                          }}
                        >
                          <div className={classes.tableCell}>
                            {column.icon}
                            <p className={classes.title}>{column.title}</p>
                          </div>
                        </TableCell>
                      ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {users.map((client: IUser) => (
                    <HealthCheck
                      key={client.id}
                      user={client}
                      selectedDateFrom={dateRange.startDate}
                      selectedDateTo={dateRange.endDate}
                      platform={checkedPlatform}
                      metricsType={metrics}
                    />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        ) : (
          <CustomLoader />
        )}
      </HealthCheckStyled>
    </div>
  );
};
