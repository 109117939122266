/**
 * @prettier
 */
import React, { useState, FunctionComponent } from "react";
import { Modal, Backdrop, Fade, CircularProgress } from "@material-ui/core";
import { Close } from "@material-ui/icons";

import { useStyles } from "./styles";

import { SendButton, CancelButton } from "components/styled/Common";
import { ReactComponent as TrashCan } from "../../assets/trashcan.svg";
import { EventService } from "services/EventService";

interface IDelete {
  id: any;
  role: string;
}

export const DeleteEvent: FunctionComponent<IDelete> = ({ id, role }) => {
  const classes = useStyles({});

  const [isOpen, setIsOpen] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState("");

  const handleDelete = () => {
    // ("handleDelete", "id", id, "role", role);

    let Event = new EventService(id);
    setSubmitting(true);

    Event.deleteEvent()
      .then(() => {
        setSubmitting(false);
        setIsOpen(!isOpen);
      })
      .catch((e) => {
        setSubmitting(false);
        setIsOpen(!isOpen);
        return setError(e);
      });
  };

  const handleClose = (e) => {
    if (e.key === "Escape") {
      setIsOpen(false);
    }
  };

  return (
    <>
      <TrashCan
        onClick={() => setIsOpen(!isOpen)}
        style={{
          cursor: "pointer",
          marginTop: "10px",
        }}
      />

      <Modal
        open={isOpen}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={isOpen}>
          <div className={classes.container}>
            <div className={classes.paper}>
              <div className={classes.top}>
                <div className={classes.print}>Confirmation Needed</div>
                <Close
                  className={classes.icon}
                  onClick={() => setIsOpen(!isOpen)}
                />
              </div>
              <div style={{ marginTop: "30px", marginBottom: "30px" }}>
                You are about to delete an event. Are you sure?
              </div>
            </div>
            <span className={classes.error}>{error}</span>

            <div className={classes.line} />
            <div
              className={classes.bottom}
              style={{ justifyContent: "flex-between" }}
            >
              <CancelButton disableRipple onClick={() => setIsOpen(!isOpen)}>
                Cancel
              </CancelButton>
              <SendButton onClick={() => handleDelete()} disableRipple>
                {submitting ? (
                  <CircularProgress size={30} color="inherit" />
                ) : (
                  <span>Delete Event</span>
                )}
              </SendButton>
            </div>
          </div>
        </Fade>
      </Modal>
    </>
  );
};
