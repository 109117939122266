import { deleteMedia } from "components/BePlanned/helpers/posts";
import { UserService } from "services/UserService";
import { firebase, storage } from "firebase-tools/firebase";
import { extractFileName } from "../FileLink";

export const deleteEvents = async (file, user) => {
  const User = new UserService(user.id);
  await User.updateUser({
    events: firebase.firestore.FieldValue.arrayRemove(file),
  });
  const app = firebase.app();
  const functions = app.functions("europe-west2");
  const deleteDocs = functions.httpsCallable("events-delete");
  await deleteDocs({
    url: file,
    userEmail: user.email,
  });
  const storageRef = storage.ref(`events/${user.id}`);
  const filename = `${extractFileName(file).replace(".csv", "")}_deleted.csv`;
  fetch(file)
    .then((res) => {
      return res.blob();
    })
    .then((blob) => {
      storageRef
        .child(filename)
        .put(blob)
        .then(function (snapshot) {
          return snapshot.ref.getDownloadURL();
        })
        .then(async (url) => {
          // ("Firebase storage file uploaded : ", url);
          await deleteMedia(file);
        });
    })
    .catch((error) => {
      console.error(error);
    });
};
