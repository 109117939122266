import React, { FC, useCallback, useEffect, useState } from "react";
import { ReactComponent as Download } from "../../../assets/download.svg";
import { CircularProgress } from "@material-ui/core";

interface IDownloadActions {
  generateScreenshot: () => void;
  color?: string;
  margin?: string;
}

const DownloadActionsV2: FC<IDownloadActions> = ({
  generateScreenshot,
  color = "",
  margin = "",
}) => {
  const [, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const toggleOpen = useCallback(() => {
    setOpen((prevOpen) => !prevOpen);
  }, []);

  const handleDownload = useCallback(async () => {
    toggleOpen();
    setLoading(true);
    try {
      await generateScreenshot();
    } finally {
      setLoading(false);
    }
  }, [toggleOpen, generateScreenshot]);

  useEffect(() => {
    return () => {
      // Cleanup logic if needed
    };
  }, []);

  return (
    <div
      style={{
        padding: "10px 15px",
        margin: margin,
      }}
    >
      {loading ? (
        <CircularProgress size={16} />
      ) : (
        <Download
          height={19}
          width={16}
          style={{
            cursor: "pointer",
          }}
          onClick={handleDownload}
        />
      )}
      {/* <span> Screenshot</span> */}
    </div>
  );
};

export default DownloadActionsV2;
