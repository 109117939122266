import React, { FunctionComponent } from "react";
import { Grid } from "@material-ui/core";

import { useStyles } from "./styles";
import { IndicatorProps } from "./MetricsIndicator";
import { area } from "./ChartV2/chartOptions";
import Media from "./Media";
import CustomLoader from "../CustomLoader";
import { formatDate } from "utils/dateFormat";
import Fade from "./Fade/Fade";
import YouTubeTop10 from "./Media/YoutubeTop10";
import { capitalize } from "utils/stringFormat";
import YoutubeChart from "./ChartV2/YoutubeChart";

interface MetricProps extends IndicatorProps {
  igType?: string;
  igHandler?: (e: any) => void;
  mediaLoading?: boolean;
  posts: Array<any>;
  posts2?: Array<any>;
  igMetadata?: any;
  fbMetadata?: any;
  prevLoading: boolean;
  prevMetrics: any;
  hiddenSubscriber?: boolean;
  dateRange?: {
    startDate: any;
    endDate: any;
  };
}

const getFollowerChartTitle = (platform: string) => {
  switch (platform) {
    case "YouTube":
      return "Subscriber Count";
    case "Apple Music":
      return "Listeners";
    default:
      return "Follower Count";
  }
};

const fetchingMessage = (platform: string) => {
  switch (platform) {
    case "YouTube":
      return "Fetching top 10 videos...";
    case "Apple Music":
      return "Fetching top 10 songs...";
    default:
      return "Fetching top 3 posts...";
  }
};

const YouTubeMetrics: FunctionComponent<MetricProps> = ({
  role,
  metrics: metricsData,
  icon,
  mediaLoading,
  posts,
  posts2,
  igMetadata,
  fbMetadata,
  url,
  prevLoading,
  prevMetrics,
  hiddenSubscriber,
  dateRange,
}) => {
  let metrics = metricsData;
  const midpoint = Math.ceil(metricsData.dates.length / 2);
  let metricsDates = metricsData.dates.slice(midpoint);
  metrics.dates = metricsDates;

  metrics.dates = metricsDates;

  const followers = {
    series: [
      {
        name: `${role === "Apple Music" ? "Listeners" : "Followers"}`,
        data: metrics.followers,
      },
    ],
  };

  const videoViews = {
    series: [
      {
        name: "Video Views",
        data: metrics.videoViews || [],
      },
    ],
  };
  const totalLikes = {
    series: [
      {
        name: "Total Likes",
        data: metrics.likes || [],
      },
    ],
  };

  const totalDislikes = {
    series: [
      {
        name: "Total Dislikes",
        data: metrics.dislikes || [],
      },
    ],
  };

  const dateLength = metricsDates?.length;

  const classes = useStyles({});
  // const [screenWidth] = useState(
  //   window.matchMedia("(min-width: 1320px)").matches
  // );

  let videoPost: any[] = [];
  let imagePost: any[] = [];

  if (posts?.length) {
    for (let item of posts) {
      if (["IMAGE", "CAROUSEL_ALBUM"].includes(item?.metadata?.media_type)) {
        imagePost.push(item);
      } else if (["VIDEO"].includes(item?.metadata?.media_type)) {
        videoPost.push(item);
      }
    }
  }

  const areaOptions = {
    ...area.options,
    markers:
      dateLength > 30
        ? {}
        : {
            size: 5,
            colors: ["white"],
            strokeColor: "#5187E0",
            strokeWidth: 2,
          },
    xaxis: {
      categories: dateLength > 30 ? metricsDates : formatDate(metrics.dates),
      type: dateLength > 30 ? "datetime" : "date",
      labels: {
        datetimeUTC: false,
      },
    },
  };

  const mediaCheck = (posts) =>
    posts?.length === 0 ? null : (
      <Media
        posts={posts}
        role={role}
        fbMetadata={fbMetadata}
        igMetadata={igMetadata}
      />
    );

  return (
    <div style={{ marginBottom: "45px" }}>
      {(posts?.length !== 0 || metrics.dates.length > 0 || mediaLoading) && (
        <div className={classes.socialLabel}>
          <a href={url} target="_blank" rel="noopener noreferrer">
            <img className={classes.icon} src={icon} alt={role} />
          </a>
          <span className={classes.statsName}>
            {" "}
            {role === "twitter" ? "X" : capitalize(role)}
          </span>
        </div>
      )}

      {!mediaLoading && ["YouTube"].includes(role) ? mediaCheck(posts2) : ""}

      {!mediaLoading && role === "YouTube"
        ? !!posts?.length && <YouTubeTop10 posts={posts} />
        : ""}

      <br />
      <Fade show={!!mediaLoading}>
        <div style={{ marginTop: "-100px", paddingBottom: "100px" }}>
          <CustomLoader width="15%" />
          <div className={classes.mediaLoader}>{fetchingMessage(role)}</div>
        </div>
      </Fade>

      {metrics.dates.length > 0 && (
        <>
          <Grid className={classes.metrics} id="metrics">
            {dateRange &&
              !!videoViews?.series[0]?.data?.length &&
              role === "YouTube" && (
                <YoutubeChart
                  metrics={{ ...videoViews, areaOptions }}
                  dateGraph={metricsDates}
                  key="video-views"
                  icon={icon}
                  role={role}
                  name={"Video Views"}
                  url={url}
                  prevLoading={prevLoading}
                  prevData={prevMetrics}
                  dateRange={dateRange}
                  metricsData={metricsData}
                />
              )}

            {dateRange &&
              metricsDates &&
              role === "YouTube" &&
              !hiddenSubscriber && (
                <YoutubeChart
                  metrics={{ ...followers, areaOptions }}
                  dateGraph={metricsDates}
                  key="follower-count"
                  icon={icon}
                  role={role}
                  name={getFollowerChartTitle(role)}
                  url={url}
                  prevLoading={prevLoading}
                  prevData={prevMetrics}
                  dateRange={dateRange}
                  metricsData={metricsData}
                />
              )}

            {dateRange &&
              !!totalLikes?.series[0]?.data?.length &&
              totalLikes?.series[0]?.data[0] > 0 &&
              role === "YouTube" && (
                <YoutubeChart
                  metrics={{ ...totalLikes, areaOptions }}
                  dateGraph={metricsDates}
                  key="total-likes"
                  icon={icon}
                  role={role}
                  name={"Total Likes"}
                  url={url}
                  prevLoading={prevLoading}
                  prevData={prevMetrics}
                  dateRange={dateRange}
                  metricsData={metricsData}
                />
              )}

            {dateRange &&
              !!totalDislikes?.series[0]?.data?.length &&
              totalDislikes?.series[0]?.data[0] > 0 &&
              role === "YouTube" && (
                <YoutubeChart
                  metrics={{ ...totalDislikes, areaOptions }}
                  dateGraph={metricsDates}
                  key="total-dislikes"
                  icon={icon}
                  role={role}
                  name={"Total Dislikes"}
                  url={url}
                  prevLoading={prevLoading}
                  prevData={prevMetrics}
                  dateRange={dateRange}
                  metricsData={metricsData}
                />
              )}
          </Grid>
        </>
      )}
    </div>
  );
};

const areEqual = (prevProps, nextProps): any => {
  if (prevProps.metrics === nextProps.metrics) {
    return true;
  }
};

export default React.memo(YouTubeMetrics, areEqual);
