import { BePlannedList } from "components/BePlanned/BePlannedList";
import React, { FC } from "react";

interface IBePlanned {
  visiblePosts: any;
  channels: any;
  monthlyCategory: any;
  weeklyCategory: any;
  client: any;
  postIds: any[];
  setPostIds: (date: any) => void;
  eventIds?: any[];
  setEventIds?: (date: any) => void;
  checkAll: Boolean;
  uncheckAll: Boolean;
  sortDirection: string;
  events?: any[];
}
const SearchList: FC<IBePlanned> = ({
  visiblePosts,
  channels,
  monthlyCategory,
  weeklyCategory,
  postIds,
  setPostIds,
  client,
  checkAll,
  uncheckAll,
  sortDirection,
  events,
  eventIds,
  setEventIds,
}) => {
  return (
    <>
      <BePlannedList
        // posts={() =>
        //   postsFilter(
        //     channels,
        //     visiblePosts,
        //     monthlyCategory,
        //     weeklyCategory,
        //     sortDirection
        //   )
        posts={() => visiblePosts}
        events={() => events as any}
        user={client}
        postIds={postIds}
        setPostIds={setPostIds}
        eventIds={eventIds as any}
        setEventIds={setEventIds as any}
        checkAll={checkAll}
        uncheckAll={uncheckAll}
      />
    </>
  );
};

export default SearchList;
