/**
 * @prettier
 */

import React, { useState, FunctionComponent, Fragment } from "react";
import { Modal, Button, CircularProgress } from "@material-ui/core";
import {
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Typography,
} from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import CloseIcon from "@material-ui/icons/Close";
import { IUser } from "interfaces/IUser";
import { UserService } from "services/UserService";
import { useStyles } from "./styles";
import { useUser } from "UserContext";
import useCollectionSubscriber from "../../hooks/useCollectionSubscriber";
import { firestore } from "../../firebase-tools/firebase";
import {
  SendButton,
  CancelButton,
  ArchivePaper,
  ArchiveContainer,
} from "components/styled/Common";

export const ArchivedClients: FunctionComponent<{}> = () => {
  const classes = useStyles({});
  const [error, setError] = useState("");
  const [isOpen, handleOpen] = useState(false);
  const [authUser] = useUser() as Array<IUser>;
  const queries = [
    firestore
      .collection("users")
      .where("role", "==", "user")
      .where("isArchived", "==", true),
  ];
  const users = useCollectionSubscriber<IUser>({ queries });
  if (users.error !== "") {
    setError("An error occurred!");
  }

  const initialState = users.documents.map((user) => {
    return {
      [user.id]: false,
    };
  });
  const [state, setState] = useState(initialState);

  const [isLoading, setIsLoading] = useState(false);
  const rootRef = React.useRef(null);

  const handleClose = (): void => {
    setState(initialState);
    return handleOpen(false);
  };

  const handleClick = (): void => {
    return handleOpen(true);
  };

  const handleSubmit = async () => {
    await Object.keys(state).forEach((key, index) => {
      if (state[key] === true) {
        let User = new UserService(key);
        setIsLoading(true);
        return User.updateUser({
          isArchived: false,
        })
          .then(() => {
            setIsLoading(false);
            setState(initialState);
            handleClose();
          })
          .catch((error) => {
            // (error);
            setError("An error occurred updating, try again");
          });
      }
    });
    handleClose();
  };

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  return (
    <div>
      <Button
        disableRipple
        type="button"
        classes={{
          label: classes.archiveLabel,
        }}
        onClick={handleClick}
      >
        Archived Clients
      </Button>
      <Modal
        open={isOpen}
        container={() => rootRef.current}
        className={classes.modal}
        onClose={(e: any) => {
          if (e.key === "Escape") {
            handleOpen(false);
          }
        }}
      >
        <ArchiveContainer>
          <ArchivePaper>
            <div className={classes.top}>
              <h2 className={classes.title}>Un-archive Clients</h2>
              <CloseIcon className={classes.icon} onClick={handleClose} />
            </div>
            <p className={classes.subTitle}>
              From this list of archived clients, select the ones that you want
              to un-archive, and save your changes. Those clients will start to
              show in the admin pages.
            </p>
            <FormControl component="fieldset" className={classes.formControl}>
              {users.documents.map((user: IUser) =>
                user.isArchived ? (
                  <Fragment key={user.id}>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={state[user.id] ? state[user?.id] : false}
                            onChange={handleChange}
                            name={user?.id}
                            className={classes.checkbox}
                          />
                        }
                        label={
                          <Typography className={classes.formControlLabel}>
                            {user?.firstName} {user?.lastName}
                          </Typography>
                        }
                      />
                    </FormGroup>
                    {error && <FormHelperText>{error}</FormHelperText>}
                  </Fragment>
                ) : null
              )}
            </FormControl>
          </ArchivePaper>
          <div className={classes.line} />

          <div className={classes.buttonContainer}>
            <CancelButton disableRipple type="button" onClick={handleClose}>
              Cancel
            </CancelButton>
            {authUser.role !== "viewerAdmin" && (
              <SendButton
                disableRipple
                onClick={handleSubmit}
                type="submit"
                disabled={isLoading}
              >
                {!isLoading && "Save Changes"}
                {isLoading && <CircularProgress size={30} color="inherit" />}
              </SendButton>
            )}
          </div>
        </ArchiveContainer>
      </Modal>
    </div>
  );
};
