import React from "react";
import IconButton from "@material-ui/core/IconButton";
import ArrowLeftIcon from "@material-ui/icons/ArrowLeft";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { Grid } from "@material-ui/core";
import { useStyles } from "./styles";

const Pagination = ({
  currentPage,
  totalPages,
  onPageChange,
  pageSizeOptions,
  pageSize,
  setPageSize,
}) => {
  //   const [pageSize, setPageSize] = useState(defaultPageSize);
  const classes = useStyles({});

  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages) {
      onPageChange(page);
    }
  };

  const handlePageSizeChange = (event) => {
    const newSize = event.target.value;
    setPageSize(newSize);
    // If needed, you can also reset the current page to 1 when the page size changes
    onPageChange(1, newSize);
  };

  const renderPagination = () => {
    const visiblePages = 3; // Number of visible pages in the pagination
    const pages = Array.from({ length: totalPages }, (_, i) => i + 1);
    let pageButtons: any[] = [];

    // Display numbers for a small number of pages
    if (totalPages <= visiblePages) {
      pageButtons = pages.map((page) => (
        <button
          key={page}
          onClick={() => handlePageChange(page)}
          style={{
            margin: "5px",
            padding: "8px 12px",
            backgroundColor: currentPage === page ? "#000000" : "#FFFFFF",
            color: currentPage === page ? "#FFFFFF" : "#000000",
            borderRadius: "8px",
            border: "1px solid #ccc",
            cursor: "pointer",
            width: "32px",
            height: "32px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {page}
        </button>
      ));
    } else {
      // Display ellipsis for a larger number of pages
      const startPage = Math.max(1, currentPage - Math.floor(visiblePages / 2));
      const endPage = Math.min(totalPages, startPage + visiblePages - 1);

      if (startPage > 1) {
        pageButtons.push(
          <button
            key={1}
            onClick={() => handlePageChange(1)}
            style={{
              margin: "5px",
              padding: "8px 12px",
              backgroundColor: currentPage === 1 ? "#000000" : "#FFFFFF",
              color: currentPage === 1 ? "#FFFFFF" : "#000000",
              borderRadius: "8px",
              border: "1px solid #ccc",
              cursor: "pointer",
              width: "32px",
              height: "32px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            1
          </button>,
          <span key="ellipsis1" style={{ margin: "5px" }}>
            ...
          </span>
        );
      }

      for (let page = startPage; page <= endPage; page++) {
        pageButtons.push(
          <button
            key={page}
            onClick={() => handlePageChange(page)}
            style={{
              margin: "5px",
              padding: "8px 12px",
              backgroundColor: currentPage === page ? "#000000" : "#FFFFFF",
              color: currentPage === page ? "#FFFFFF" : "#000000",
              borderRadius: "8px",
              border: "1px solid #ccc",
              cursor: "pointer",
              width: "32px",
              height: "32px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {page}
          </button>
        );
      }

      if (endPage < totalPages) {
        pageButtons.push(
          <span key="ellipsis2" style={{ margin: "5px" }}>
            ...
          </span>,
          <button
            key={totalPages}
            onClick={() => handlePageChange(totalPages)}
            style={{
              margin: "5px",
              padding: "8px 12px",
              backgroundColor:
                currentPage === totalPages ? "#000000" : "#FFFFFF",
              color: currentPage === totalPages ? "#FFFFFF" : "#000000",
              borderRadius: "8px",
              border: "1px solid #ccc",
              cursor: "pointer",
              width: "32px",
              height: "32px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {totalPages}
          </button>
        );
      }
    }

    return pageButtons;
  };

  const calculateRange = () => {
    const start = (currentPage - 1) * pageSize + 1;
    const end = Math.min(currentPage * pageSize, totalPages);
    return `${start}-${end}`;
  };

  return (
    <Grid container>
      <Grid xs={8} md={8} item>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            marginTop: "20px",
          }}
        >
          <IconButton
            disabled={currentPage === 1}
            onClick={() => handlePageChange(currentPage - 1)}
            style={{
              margin: "5px",
              marginLeft: "0px",
              cursor: "pointer",
              width: "32px",
              height: "32px",
              padding: "10px",
              borderRadius: "8px",
              border: "1px solid #ccc",
              backgroundColor: "#FFFFFF",
            }}
          >
            <ArrowLeftIcon />
          </IconButton>

          {renderPagination()}

          <IconButton
            disabled={currentPage === totalPages}
            onClick={() => handlePageChange(currentPage + 1)}
            style={{
              margin: "5px",
              cursor: "pointer",
              width: "32px",
              height: "32px",
              padding: "10px",
              borderRadius: "8px",
              border: "1px solid #ccc",
              marginLeft: "10px",
              backgroundColor: "#FFFFFF",
            }}
          >
            <ArrowRightIcon />
          </IconButton>
        </div>
      </Grid>

      <Grid
        xs={4}
        md={4}
        item
        className={` ${classes.paginationPageInfo} ${classes.smHide}`}
      >
        <span
          style={{
            marginTop: "15px",
            fontFamily: "Avenir",
            fontSize: "14px",
            fontWeight: 350,
            lineHeight: "19px",
            letterSpacing: "0em",
            textAlign: "left",
          }}
        >
          Items per page
        </span>
        <Select
          variant="outlined"
          value={pageSize}
          onChange={handlePageSizeChange}
          style={{
            margin: "5px",
            marginLeft: "10px",
            width: "70px",
            height: "42px",
            borderRadius: "15px",
            border: "1px solid #ccc",
            backgroundColor: "#FFFFFF",
          }}
        >
          {pageSizeOptions.map((size) => (
            <MenuItem key={size} value={size}>
              {size}
            </MenuItem>
          ))}
        </Select>
        <span
          style={{
            marginTop: "15px",
            fontFamily: "Avenir",
            fontSize: "14px",
            fontWeight: 350,
            lineHeight: "19px",
            letterSpacing: "0em",
            textAlign: "left",
          }}
        >
          {calculateRange()} of {totalPages} items
        </span>
      </Grid>
    </Grid>
  );
};

export default Pagination;
