/**
 * @prettier
 */
import { DB } from "firebase-tools/db";
import { fetchAllCustomers } from "helpers/user/fetchAllCustomers";
import { IUser, IUserArray } from "interfaces/IUser";

export class UserService extends DB {
  private collectionName: string = "users";
  private _doc: string = "";
  constructor(doc?: string) {
    super();
    if (doc) {
      this.doc = doc;
    }
  }
  set doc(value: string) {
    this._doc = value;
  }

  get doc() {
    return this._doc;
  }
  async getUserByDoc(): Promise<IUser> {
    return this.getDoc(this.collectionName, this.doc);
  }

  async getUserByField(
    fieldName: string,
    value: any,
    fieldName2?: string,
    value2?: any,
    orderField: string = "createdAt",
    operation: firebase.firestore.WhereFilterOp = "=="
  ): Promise<Array<IUser>> {
    return this.getByField(
      this.collectionName,
      fieldName,
      value,
      fieldName2,
      value2,
      operation,
      orderField
    );
  }

  async getActiveUsers(
    fieldName: string,
    value: any,
    orderField2?: any,
    orderField: string = "createdAt",
    operation: firebase.firestore.WhereFilterOp = "=="
  ): Promise<Array<IUser>> {
    return this.getByActiveUser(
      this.collectionName,
      fieldName,
      value,
      operation,
      orderField2,
      orderField
    );
  }

  async getUsers(
    orderField: string = "createdAt",
    orderDirection: firebase.firestore.OrderByDirection = "desc"
  ): Promise<Array<IUser>> {
    return this.get(this.collectionName, orderField, orderDirection);
  }

  async getUsersData(
    orderField: string = "createdAt",
    orderDirection: firebase.firestore.OrderByDirection = "desc"
  ): Promise<IUserArray> {
    // ("UserService:getUsersData:start");
    const users = await fetchAllCustomers();
    // (`UserService:getUsersData:users=${JSON.stringify(users)}`);
    return {
      documents: users,
      loading: false,
      error: null,
    };
  }

  async addUser(user: IUser): Promise<boolean> {
    return this.set(this.collectionName, this.doc, user);
  }

  async updateUser(user: any): Promise<boolean> {
    return this.update(this.collectionName, this.doc, user);
  }

  async deleteUser(): Promise<boolean> {
    return this.delete(this.collectionName, this.doc);
  }

  getStorage(folderName: string) {
    return DB.getStorage(`${folderName}/${this.doc}`);
  }
  async uploadFile(filename: string, folderName): Promise<string> {
    return new Promise((resolve, reject) => {
      this.getStorage(folderName)
        .child(filename)
        .getDownloadURL()
        .then(resolve, reject);
    });
  }
}
