import React, { FunctionComponent, useRef, useEffect } from "react";
import Button from "@material-ui/core/Button";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { navigate } from "@reach/router";
import { UserService } from "services/UserService";
import { IUser } from "interfaces/IUser";
import { useStyles } from "./styles";
import { useUser } from "UserContext";
// import authService from "helpers/user/auth.service";

type ActionsType = {
  user: any;
};

export const Actions: FunctionComponent<ActionsType> = ({ user }) => {
  const classes = useStyles({});
  const [open, setOpen] = React.useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);
  const [authUser] = useUser() as Array<IUser>;

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleListKeyDown = (event) => {
    if (event.key === "tab") {
      event.preventDefault();
      setOpen(false);
    }
  };

  const archiveClient = async (userId: string) => {
    const User = new UserService(userId);
    return await User.updateUser({
      isArchived: true,
    } as IUser);
  };

  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      if (anchorRef.current) {
        anchorRef.current.focus(); // Call the focus method here
      }
    }
  }, [open]);

  // const setClientNew = async (e: any) => {
  //   try {
  //     await authService.updateUser(authUser.id, {
  //       lastClientSelection: e,
  //     });
  //   } catch (err) {}
  // };

  const viewNavigation = async (user: any, path: string) => {
    // await setClientNew(user).catch((err) =>
    //   ("lastSelection Error", err)
    // );

    const newPath = `${path}?client=${user.id}`;
    navigate(newPath);
    // const User = new UserService(authUser.id);
    // User.updateUser({
    //   lastClientSelection: user,
    // }).catch((err) => console.log("lastSelection Error", err));
  };

  return (
    <div className={classes.root}>
      <Button
        disableRipple
        ref={anchorRef}
        variant="outlined"
        aria-controls={open ? "menu-list-grow" : undefined}
        aria-haspopup="true"
        classes={{
          label: classes.label,
          root: classes.button,
        }}
        style={
          open
            ? {
                background: "#E8E8E8",
                borderBottomLeftRadius: "initial",
                borderBottomRightRadius: "initial",
              }
            : { background: "#fffff" }
        }
        onClick={handleToggle}
      >
        Actions <ArrowDropDownIcon />
      </Button>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        className={classes.popper}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id="menu-list-grow"
                  onKeyDown={handleListKeyDown}
                  style={{ padding: "0px" }}
                >
                  <MenuItem
                    className={classes.li}
                    onClick={() => viewNavigation(user, "/be-measured")}
                  >
                    View Be-Measured
                  </MenuItem>
                  <div className={classes.line}></div>
                  <MenuItem
                    className={classes.li}
                    onClick={() => viewNavigation(user, "/content-plan-feed")}
                  >
                    View Be-Planned
                  </MenuItem>
                  <div className={classes.line}></div>
                  <MenuItem
                    className={classes.li}
                    onClick={() => viewNavigation(user, "/campaigns/organic")}
                  >
                    View Be-Amplified
                  </MenuItem>
                  <div className={classes.line}></div>
                  <MenuItem
                    className={classes.li}
                    onClick={() => navigate(`/client-info/${user.id}`)}
                  >
                    {authUser.role === "viewerAdmin"
                      ? "View Client"
                      : "Edit Client"}
                  </MenuItem>
                  {authUser.role !== "viewerAdmin" && (
                    <div>
                      <div className={classes.line}></div>
                      <MenuItem
                        className={classes.li}
                        onClick={() => archiveClient(user.id)}
                      >
                        Archive Client
                      </MenuItem>
                    </div>
                  )}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
};
