import React, { FunctionComponent, useState, useEffect } from "react";
import { Box, Avatar, Tooltip } from "@material-ui/core";
import cuid from "cuid";

import { PostService } from "services/PostService";
import { UserService } from "services/UserService";
import FileUploader from "react-firebase-file-uploader";
import { useStyles } from "./styles";
import uploadImg from "static/media/uploadImg.svg";
import { ReactComponent as AvatarIcon } from "static/media/avatarIcon.svg";
import { IUser } from "interfaces/IUser";
import { useUser } from "UserContext";

import attachFile from "assets/attach.svg";
import gifUploadFile from "assets/gif-file.svg";

type FileUploadType = {
  image: string;
  setImage: (image: string) => void;
  avatar?: any;
  isUploading?: boolean;
  setIsUploading?: (isUploading: boolean) => void;
  imgUpload?: boolean;
  gifUpload?: boolean;
  setProgress: (progress) => void;
  content?: string;
};

const UploadImage: FunctionComponent<FileUploadType> = ({
  image,
  setImage,
  isUploading,
  setIsUploading = () => {},
  avatar,
  imgUpload,
  gifUpload,
  setProgress,
  content,
}) => {
  const classes = useStyles({});
  const [storage, setStorage] = useState() as any;
  const fileUid: string = cuid();
  const [authUser] = useUser() as Array<IUser>;
  const User = new UserService();
  const Post = new PostService();

  const handleUploadStart = () => {
    setProgress(1);
    setIsUploading(true);
  };
  const handleProgress = (progress: number) => setProgress(progress);
  const handleUploadError = (error: boolean) => {
    setIsUploading(false);
    // ("error");
  };
  const handleUploadSuccess = async (filename: string) => {
    setImage(filename);
    if (content === "Avatar") {
      await User.uploadFile(filename, "images")
        .then((url) => {
          // ("uploaded image", url);
          setImage(url);
          setProgress(100);
          setIsUploading(false);
        })
        .catch((error) => {
          // (error);
          setIsUploading(false);
        });
    } else {
      await Post.uploadImage(filename)
        .then((url) => {
          // ("uploaded image", url);
          setImage(url);
          setProgress(100);
          setIsUploading(false);
        })
        .catch((error) => {
          // (error);
          setIsUploading(false);
        });
    }
  };

  useEffect(() => {
    let storage;
    if (content === "Avatar") {
      storage = User.getStorage("images");
    } else {
      storage = Post.getImageStorage();
    }
    setStorage(storage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderImage = () => {
    return image === "" ? (
      avatar ? (
        <AvatarIcon />
      ) : (
        <div className={classes.uploadImg}>
          <img src={uploadImg} alt="" style={{ fill: "#000" }} />
        </div>
      )
    ) : avatar ? (
      <Avatar className={classes.avatar} src={image} />
    ) : (
      <div
        className={classes.uploadImg}
        style={{ background: image === "" ? "#C5C6C6" : "#fff" }}
      >
        <img
          src={image}
          alt=""
          style={{ height: "100%", width: "100%", objectFit: "contain" }}
        />
      </div>
    );
  };

  return (
    <Box className={classes.box}>
      {!imgUpload && !gifUpload && renderImage()}
      {authUser.role !== "viewerAdmin" && (
        <label className={imgUpload || gifUpload ? "" : classes.button}>
          {!imgUpload && !gifUpload && <span>Upload {content}</span>}
          {imgUpload && (
            <Tooltip title="Add Photo" arrow>
              <img
                src={attachFile}
                alt="attachFile"
                style={{ cursor: "pointer" }}
              />
            </Tooltip>
          )}
          {gifUpload && (
            <Tooltip title="Add GIF" arrow>
              <img
                src={gifUploadFile}
                alt="gifUpload"
                style={{ cursor: "pointer", width: 35, height: 35 }}
              />
            </Tooltip>
          )}
          <FileUploader
            disabled={isUploading}
            name="imageUrls"
            hidden
            accept="image/*"
            storageRef={storage}
            onUploadStart={handleUploadStart}
            onUploadError={handleUploadError}
            onUploadSuccess={handleUploadSuccess}
            onProgress={handleProgress}
            filename={(fileObj) => {
              const fileName = fileObj.name.split(".").slice(0, -1).join(".");

              return fileName + ` (${fileUid})`;
            }}
            multiple // Enable multiple file selection
          />
        </label>
      )}
    </Box>
  );
};

export default UploadImage;
