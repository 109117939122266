import React, { FunctionComponent } from "react";
import { Grid } from "@material-ui/core";

import ClientInfo from "components/ClientInfo";
import { firestore } from "../../firebase-tools/firebase";
// import useCollectionSubscriber2 from "hooks/useCollectionSubscriber2";
// import authService from "helpers/user/auth.service";
// import { IUser } from "interfaces/IUser";
import { ClientInfoCreate } from "components/ClientInfo/ClientInfoCreate";
import useDocumentSubscriber from "hooks/useDocumentSubscriber";

type ClientInfoType = {
  uid?: string;
  path: string;
};

export const ClientInfoPage: FunctionComponent<ClientInfoType> = ({ uid }) => {
  const create = uid === "create";

  let userRef = firestore.collection("users").doc(uid);

  let userInfo = useDocumentSubscriber({ ref: userRef }).document;
  // const create = uid === "create";
  // const [userInfo, setUserInfo] = useState<IUser | null>(null);

  // useEffect(() => {
  //   if (uid && !create) {
  //     const fetchUser = async () => {
  //       try {
  //         const user = await authService.fetchUserById(uid);
  //         setUserInfo(user);
  //       } catch (error) {
  //         console.error(`Error fetching user with id ${uid}:`, error);
  //       }
  //     };
  //     fetchUser();
  //   } else {
  //     // authService.creatUser({
  //     //   email: "",
  //     //   name: "",
  //     //   phone: "",
  //     //   role: "client",
  //     //   status: "active",
  //     // }).then((res) => {
  //     //   console.log("User created successfully!");
  //     //   setUserInfo(res);
  //     // });
  //   }
  // }, [uid, create]);

  return (
    <Grid container id="client-info">
      {userInfo?.id && !create && (
        <ClientInfo userInfo={userInfo} create={create} />
      )}
      {userInfo?.id && create && (
        <ClientInfoCreate userInfo={userInfo as any} create={create} />
      )}
    </Grid>
  );
};
