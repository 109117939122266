import React, { FC, useState, useEffect, useRef, memo } from "react";
import {
  Button,
  Modal,
  Backdrop,
  Fade,
  CircularProgress,
  Tooltip,
  Chip,
  ClickAwayListener,
  TextField,
} from "@material-ui/core";
import { Close, Create, Cancel as CancelIcon } from "@material-ui/icons";

import { v4 as uuidv4 } from "uuid";
import { DateTime } from "luxon";
import { Picker } from "emoji-mart";

import firebase from "firebase/app";

import { useStyles } from "./styles";
import Name from "components/Common/Form/Name";

import UploadImage from "../Common/UploadImage/UploadImage";
import VideoUpload from "../Common/VideoUpload";
import { PostService } from "services/PostService";
import { IPost } from "../../interfaces/IPost";
import Autosuggest from "../Common/Autosuggest";
import AutoTags from "../Common/AutoTags";
import useCollectionSubscriber from "../../hooks/useCollectionSubscriber";
import { firestore } from "../../firebase-tools/firebase";
import PostContent from "../ContentEditable";
import { highlightDoc, unHighlight } from "../ContentEditable/Editable";
import { ReactComponent as HighlightIcon } from "../../static/media/highlight.svg";
import { ReactComponent as AddEmoji } from "../../assets/add-emoji.svg";

import "emoji-mart/css/emoji-mart.css";
import { ReactComponent as UnHighlightIcon } from "../../static/media/unhighlight.svg";
import { handleDeleteVideo, updateSuggestions } from "./helpers/posts";
import {
  SendButton,
  CancelButton,
  PostPaper,
  EmojiPicker,
  DateInput,
} from "components/styled/Common";
import { FieldTitle } from "components/styled/CreateEditPost";
import Status from "./Status";
import Channels from "./Channels";
import PostCategory, { allSelectedCategories } from "./PostCategory";
import { errorChecker } from "./helpers/errorChecker";
import { success } from "utils/notification";
import { Delete } from "components/BePlannedMonthly/Delete";
import Carousel from "components/Common/Carousel";
import { capitalize } from "utils/stringFormat";
import preventScroll from "utils/preventScroll";

interface ICreateEditPost {
  edit?: string;
  create?: string;
  role: string;
  user?: any;
  monthly?: string;
  post?: any;
  externalOpen?: boolean;
  setExternalOpen?: (open: boolean) => void;
  externalDate?: any;
  setExternalDate?: (date: any) => void;
}

const stripHtml = (html) => {
  let tmp = document.createElement("DIV");
  tmp.innerHTML = html;
  return tmp.textContent || tmp.innerText || "";
};

export const deleteMedia = async (media) => {
  const mediaRef = await firebase.storage().refFromURL(media);
  await mediaRef.delete();
};

const topMedia = [
  "Instagram",
  "Facebook",
  "Twitter",
  "TikTok",
  "Triller",
  "YouTube Short",
  "Snapchat",
  "Pinterest",
  "Tumblr",
];
export const mainMedia = ["instagram", "facebook", "twitter"];
export const channelsList = [
  "instagram",
  "facebook",
  "twitter",
  "tiktok",
  "youtubeshort",
  "youtube",
  "discord",
  "snapchat",
  "threads",
];

const getPostDate = (dateInSeconds) => {
  return DateTime.fromSeconds(dateInSeconds).toFormat("yyyy-MM-dd'T'HH:mm");
};

const CreateEditPost: FC<ICreateEditPost> = ({
  edit = "",
  create = "",
  role,
  user,
  post,
  externalOpen,
  setExternalOpen,
  externalDate,
  setExternalDate,
  monthly,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [image, setImage] = useState("");
  const [images, setImages]: any = useState([]);
  const [video, setVideo]: any = useState("");
  const [link, setLink] = useState("");
  const [videoLink, setVideoLink] = useState("");
  const [videoUpload, setVideoUpload] = useState("");
  const [linkInput, setLinkInput] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [isGifUploading, setIsGifUploading] = useState(false);
  const [isVideoUploading, setIsVideoUploading] = useState(false);
  const [selectedDate, setSelectedDate]: any = useState(
    DateTime.fromJSDate(new Date()).toFormat("yyyy-MM-dd'T'HH:mm")
  );
  const [postText, setPostText]: any = useState("");
  const [graphicsBrief, setGraphicsBrief]: any = useState("");
  const [additionalInfo, setInfo]: any = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [toEdit, setToEdit] = useState("");
  const [toCreate, setToCreate] = useState("");
  const [error, setError] = useState({
    textError: "",
    linkErr: "",
    platformError: "",
    instagramError: "",
    titleError: "",
    channelsError: "",
  });
  const [selectMedia, setSelectMedia] = useState(false);
  const [platform, setPlatform] = useState("");
  const [channel, setChannel] = useState("");
  const [, setAutocomplete] = useState(false);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [postUpdate, setPostUpdate] = useState(0);
  const [videoProgress, setVideoProgress] = useState(0);
  const [imageProgress, setImageProgress] = useState(0);
  const [gifProgress, setGifProgress] = useState(0);
  const [tags, setTags]: Array<any> = useState([]);
  const [addChannel, setAddChannel] = useState(false);
  const [selectedChannels, setSelectedChannels]: any = useState([]);
  const [otherChannels, setOtherChannels] = useState([]);
  const [internalStatusMonthly, setInternalStatusMonthly] = useState("Draft");
  const [internalStatusWeekly, setInternalStatusWeekly] = useState("Draft");
  const [category, setCategory] = React.useState({
    instagram: ["Instagram Feed"],
    facebook: ["Facebook Feed"],
    twitter: ["Twitter Feed"],
    tiktok: ["TikTok Feed"],
    youtube: ["Youtube Feed"],
    youtubeshort: ["YouTubeShort Feed"],
    custom: ["Custom Post"],
  });

  const [categoryError, setCategoryError] = useState({
    facebook: "",
    instagram: "",
    twitter: "",
    tiktok: "",
    youtube: "",
    custom: "",
  });
  const [linkType, setLinkType] = useState("");
  const classes = useStyles({ dropdown: selectMedia });
  const [postHtml, setHtml] = useState("");
  const [title, setTitle] = useState("");
  const [graphicsHtml, setGraphicsHtml] = useState("");
  const [curUser, setUser]: any = useState(user);
  const [infoHtml, setInfoHtml] = useState("");
  const [windowOffset, setWindowOffset] = useState(window.scrollY);

  const postId = post?.id;
  const uuid = uuidv4();

  const platformQueries = [
    firestore.collection("social-media").orderBy("created_at", "asc"),
  ];
  const documents: any = useCollectionSubscriber({ queries: platformQueries })
    .documents[0];
  const platforms = documents ? documents.platforms : topMedia;
  const oldTags = documents?.tags || [];

  const vimeoRegex = /^(http:\/\/|https:\/\/)(vimeo\.com)\/([\w]+)?$/;
  const youtubeRegex = /(http:|https:)?\/\/(www\.)?(youtube.com|youtu.be)\/(watch)?(\?v=)?(\S+)?/;

  useEffect(() => {
    preventScroll(isOpen, windowOffset, setWindowOffset);

    // eslint-disable-next-line
  }, [isOpen, externalOpen]);

  //set initial post state
  useEffect(() => {
    const isVideoLink = (link) => {
      if (vimeoRegex.test(link)) {
        setLinkInput(true);
        setLink(post.video_url);
        setLinkType("vimeo");
        setVideoLink(post.video_url);
      } else if (youtubeRegex.test(link)) {
        setLinkInput(true);
        setLink(post.video_url);
        setLinkType("youtube");
        setVideoLink(post.video_url);
      } else if (post?.video_url?.includes("dropbox")) {
        setLinkInput(true);
        setLink(post.video_url);
        setLinkType("dropbox");
        setVideoLink(post.video_url);
      } else {
        setVideo(post.video_url);
        setVideoUpload(post.video_url);
      }
    };

    setPlatform(capitalize(role || ""));
    if (post) {
      if (post.channels) {
        setSelectedChannels(post.channels);
      } else {
        const channel: any = [];
        channel.push(role);

        setSelectedChannels(channel);
      }
      if (post.category) {
        const categoryUpdate = { ...category };

        Object.keys(post.category).forEach((element) => {
          if (post.category[element].length) {
            categoryUpdate[element] = post.category[element];
          }
        });
        setCategory(categoryUpdate);
      }
      const date = getPostDate(post?.date?.seconds);

      setPostText(post.body);
      setSelectedDate(date);
      setTags(post.tags || []);
      setInternalStatusMonthly(post.internal_status_monthly || "Draft");
      setInternalStatusWeekly(post.internal_status_weekly || "Draft");

      if (typeof post.image_url === "string") {
        setImage(post.image_url);
        setImages(post.image_url !== "" ? [post.image_url] : []);
      } else {
        setImages(post.image_url);
      }
      setHtml(post.body);
      isVideoLink(post.video_url);
      setPostUpdate(1);
      if (post.title) {
        setTitle(post.title);
      } else {
        setTitle(capitalize(role || "") + " Post");
      }
      if (post.graphicsBrief) {
        setGraphicsHtml(post.graphicsBrief);
        setGraphicsBrief(post.graphicsBrief);
      } else {
        setGraphicsHtml(`
        <div>TITLE:</div><div>DUE:</div><div>POSTED:</div><div>Asset type:</div><div>Asset purpose:</div><div>Format:</div><div>Brief:</div><div>Asset:</div><div>Text:</div><div>Timecodes:</div><div>Links:</div><div>Reference:</div>`);
      }
      if (post.additional_info) {
        setInfo(post.additional_info);
        setInfoHtml(post.additional_info);
      } else {
        setInfo(post?.additional_ifo || "");
      }
    } else {
      setGraphicsHtml(`
          <div>TITLE:</div><div>DUE:</div><div>POSTED:</div><div>Asset type:</div><div>Asset purpose:</div><div>Format:</div><div>Brief:</div><div>Asset:</div><div>Text:</div><div>Timecodes:</div><div>Links:</div><div>Reference:</div>`);
    }

    // eslint-disable-next-line
  }, [post, role]);

  const setEditCreate = () => {
    setToCreate(create ? create : "");
    setToEdit(edit ? edit : "");
  };

  useEffect(() => {
    setEditCreate();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (monthly?.includes("monthly")) setUser({ email: user.email });
    // eslint-disable-next-line
  }, [monthly, user.email]);

  const handleDateChange = (date: any) => {
    setSelectedDate(date);
  };

  const toggleEmojiPicker = () => {
    setShowEmojiPicker(!showEmojiPicker);
  };

  const addEmoji = (emoji) => {
    document.execCommand("insertText", false, emoji.native);
    setShowEmojiPicker(!showEmojiPicker);
  };

  //return post to save
  const postSave = (id) => {
    let Post;
    let post;
    const { finalCategories } = allSelectedCategories(
      category,
      selectedChannels
    );

    Post = new PostService("multichannel-posts", id);
    post = {
      created_at: new Date(),
      date: new Date(selectedDate),
      body: postText,
      image_url: images,
      video_url: video && !link ? video : link ? link : "",
      user_email: curUser.email,
      channels: selectedChannels,
      title,
      graphicsBrief,
      additional_info: additionalInfo,
      tags,
      internal_status_monthly: internalStatusMonthly,
      internal_status_weekly: internalStatusWeekly,
      category: finalCategories,
    };

    return { Post, post };
  };

  const deleteVid = async (linkUpdate) => {
    await handleDeleteVideo(
      video,
      post,
      toEdit,
      setVideo,
      setLink,
      setLinkInput,
      false
    );
    setLink(linkUpdate);
    setLinkInput(true);
  };

  const customCheck = [...selectedChannels].filter(
    (ch) => !channelsList.includes(ch)
  );

  const isPlatform = (link, linkType) => {
    if (
      (link.includes("vimeo") && linkType === "vimeo") ||
      (link.includes("vimeo") && linkType === "")
    ) {
      return "vimeo";
    } else if (
      (linkType === "youtube" && link.includes("youtube")) ||
      (linkType === "" && link.includes("youtube"))
    ) {
      return "youtube";
    } else if (
      (linkType === "dropbox" && !link.includes("dropbox")) ||
      (linkType === "" && link.includes("dropbox"))
    ) {
      return "dropbox";
    } else {
      return "";
    }
  };

  //save popup
  const handleSubmit = async () => {
    if (selectedChannels.length === 0) {
      return setError({
        ...error,
        channelsError: "At least one channel is required!",
      });
    } else {
      setError({ ...error, channelsError: "" });
    }

    const { errorCheck, errors } = errorChecker(
      selectedChannels,
      category,
      customCheck
    );
    setCategoryError(errors);
    if (errorCheck) return;

    if (title === "") {
      return setError({ ...error, titleError: "Post title is required!" });
    } else {
      setError({ ...error, titleError: "" });
    }

    if (linkInput) {
      if (link === "") {
        return setError({ ...error, linkErr: "Video link is required!" });
      } else {
        if (
          !vimeoRegex.test(link) &&
          !youtubeRegex.test(link) &&
          !link.includes("dropbox") &&
          !link.includes("firebasestorage")
        ) {
          const platform = isPlatform(link, linkType);

          return setError({
            ...error,
            linkErr: `Enter a valid ${platform} link!`,
          });
        } else {
          setError({
            ...error,
            linkErr: "",
          });
        }
      }
    }

    if (
      toEdit &&
      video &&
      (vimeoRegex.test(link) || youtubeRegex.test(link)) &&
      postUpdate === 1
    ) {
      deleteVid(link);
      setVideo("");
      await new Promise((resolve) => setTimeout(resolve, 1000));
    }
    setSubmitting(true);
    if (toCreate) {
      const { Post, post } = await postSave(uuid);
      Post.addPost(post as IPost)
        .then(async () => {
          setSubmitting(false);
          setExternalOpen ? setExternalOpen(false) : setIsOpen(false);
          setAutocomplete(false);
          setSelectMedia(false);
          updateSuggestions(oldTags, tags, platforms, platform, documents.id);
          setImage("");
          setImages([]);
          setVideo("");
          setPostText("");
          setTitle("");
          setTags([]);
          setSelectedChannels([]);
          setOtherChannels([]);
          setInternalStatusMonthly("Draft");
          setInternalStatusWeekly("Draft");
          setGraphicsBrief("");
          setInfo("");
          setLink("");
          setLinkInput(false);
          setCategoryError({
            facebook: "",
            instagram: "",
            twitter: "",
            tiktok: "",
            youtube: "",
            custom: "",
          });
          setError({
            textError: "",
            linkErr: "",
            platformError: "",
            instagramError: "",
            titleError: "",
            channelsError: "",
          });
          setExternalDate && setExternalDate(undefined);
        })
        .catch((e) => {
          // ("ERROR:", e);
          setSubmitting(false);
          return setError(e);
        });
    }

    if (toEdit) {
      const { Post, post } = await postSave(postId);

      Post.updatePost(post)
        .then(async () => {
          setSubmitting(false);
          setExternalOpen ? setExternalOpen(false) : setIsOpen(false);
          setAutocomplete(false);
          setSelectMedia(false);
          updateSuggestions(oldTags, tags, platforms, platform, documents.id);
          setExternalDate && setExternalDate(undefined);
        })
        .catch((e) => {
          // ("ERROR:", e);
          setSubmitting(false);
          return setError(e);
        });
    }

    setUser({});
    setEditCreate();
  };

  //close popup
  const close = async (event?: any) => {
    if (event.key === "Escape") {
      setExternalOpen ? setExternalOpen(false) : setIsOpen(false);
    }

    setExternalOpen ? setExternalOpen(false) : setIsOpen(false);
    if (create) {
      if (!!images) {
        await Promise.all(
          images.map(async (image) => {
            if (image.includes("firebasestorage")) {
              await deleteMedia(image);
            }
          })
        );
        setImages([]);
        setImage("");
      }
      if (!!video) {
        if (video.includes("firebasestorage")) {
          await deleteMedia(video);
        }
        setVideo("");
      }
      setLink("");
    }

    if (toEdit) {
      if (video) {
        setVideo("");
      }
      if (videoLink) {
        setLink(videoLink);
        setLinkInput(true);
      } else if (videoUpload) {
        setVideo(videoUpload);
      }
    }
    setUser({});
    setEditCreate();
    setExternalDate && setExternalDate(undefined);
  };

  const deleteTags = (tagToDelete) => () => {
    setTags(tags.filter((tag) => tag !== tagToDelete));
  };

  const postRef = useRef(null);
  const graphicsRef = useRef(null);
  const infoRef = useRef(null);
  useEffect(() => {
    if (image.includes("http")) {
      if (image === "") {
        setImages([]);
      } else {
        if (!images.includes(image)) {
          const updatedImages = [...images, image];
          setImages(updatedImages);
        }
      }
    }
    //eslint-disable-next-line
  }, [image]);

  useEffect(() => {
    if (
      (!toEdit && link && video !== "") ||
      (toEdit && link && video !== "" && postUpdate === 1)
    ) {
      setLink("");
    }

    //eslint-disable-next-line
  }, [video]);

  useEffect(() => {
    const linkUpdate = link;

    if (
      !toEdit &&
      video &&
      (vimeoRegex.test(link) || youtubeRegex.test(link))
    ) {
      deleteVid(linkUpdate);
    }
    //eslint-disable-next-line
  }, [link]);

  useEffect(() => {
    externalDate &&
      setSelectedDate(
        DateTime.fromJSDate(externalDate).toFormat("yyyy-MM-dd'T'HH:mm")
      );
  }, [externalDate]);

  const getVideoPlaceholder = (linkType: string) => {
    switch (linkType) {
      case "vimeo":
        return "Paste your Vimeo link here...";
      case "youtube":
        return "Paste your Youtube link here...";
      case "dropbox":
        return "Paste your Dropbox link here...";
      default:
        return "";
    }
  };

  return (
    <div>
      {typeof externalOpen !== "boolean" && (toEdit || !!edit) && (
        <Button
          disableRipple
          type="button"
          className={classes.btn}
          id="action-btn"
          // endIcon={<Create className={classes.createIcon} style={{ marginBottom: "5px" }} />}
          startIcon={
            <Create
              className={classes.createIcon}
              style={{ marginBottom: "5px" }}
            />
          }
          onClick={() => {
            setIsOpen(!isOpen);
            setUser(user);
          }}
        >
          Edit Post
        </Button>
      )}

      {typeof externalOpen !== "boolean" && toCreate && !!create && (
        <Button
          disableRipple
          type="button"
          className={`${classes.btn} ${classes.edit}`}
          id="create-btn"
          onClick={() => {
            setIsOpen(!isOpen);
            setUser(user);
          }}
        >
          New Post
        </Button>
      )}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={typeof externalOpen === "boolean" ? externalOpen : isOpen}
        onClose={close}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={typeof externalOpen === "boolean" ? externalOpen : isOpen}>
          <div className={classes.container} id="edit-create">
            <PostPaper>
              <div className={classes.top}>
                <div className={classes.print}>
                  {toCreate && <span>New </span>}
                  {toEdit && <span>Edit </span>}
                  Post
                </div>
                <Close className={classes.icon} onClick={close} />
              </div>
              <div className={classes.postSection} id="post-section">
                <div className={classes.field}>
                  <FieldTitle>Channels</FieldTitle>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "baseline",
                      width: "95%",
                      flexWrap: "wrap",
                    }}
                  >
                    <Channels
                      addChannel={addChannel}
                      setAddChannel={setAddChannel}
                      selectedChannels={selectedChannels}
                      setSelectedChannels={setSelectedChannels}
                    />
                    {toEdit && (
                      <SendButton
                        disableRipple
                        type="button"
                        className={`${classes.btn} ${classes.edit}`}
                        id="create-btn"
                        onClick={() => {
                          success("Post duplicated successfully!");
                          setToCreate("create");
                          setToEdit("");
                        }}
                      >
                        Duplicate Post
                      </SendButton>
                    )}
                  </div>
                </div>
                <span className={classes.error}>{error.channelsError}</span>

                {addChannel && (
                  <div className={classes.field}>
                    <FieldTitle>Custom Channel</FieldTitle>
                    <Autosuggest
                      options={platforms}
                      value={channel}
                      setValue={setChannel}
                      addChannel={addChannel}
                      setAddChannel={setAddChannel}
                      placeholder="Enter custom channel"
                      otherChannels={otherChannels}
                      selectedChannels={selectedChannels}
                      setSelectedChannels={setSelectedChannels}
                    />
                  </div>
                )}

                {!!selectedChannels.length && (
                  <div className={classes.field}>
                    <FieldTitle>Post Category</FieldTitle>
                    <PostCategory
                      selectedChannels={selectedChannels}
                      category={category}
                      setCategory={setCategory}
                      categoryError={categoryError}
                      customCheck={!!customCheck.length}
                      setCategoryError={setCategoryError}
                    />
                  </div>
                )}

                <div className={classes.field}>
                  <FieldTitle>Publish Date/Time</FieldTitle>
                  <div>
                    <DateInput>
                      <TextField
                        id="datetime-local"
                        type="datetime-local"
                        value={selectedDate}
                        InputLabelProps={{
                          shrink: false,
                        }}
                        onChange={(e) => handleDateChange(e.target.value)}
                      />
                    </DateInput>
                  </div>
                </div>
                <div className={classes.field}>
                  <FieldTitle>Post Title</FieldTitle>
                  <div className={classes.choose} style={{ border: "none" }}>
                    <input
                      type="text"
                      className={classes.input}
                      placeholder="Start writing..."
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                      style={{ width: "100%" }}
                    />
                  </div>
                </div>
                <span className={classes.error}>{error.titleError}</span>

                <div className={classes.field}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "94%",
                      marginBottom: "5px",
                    }}
                  >
                    <FieldTitle>Post Copy</FieldTitle>
                    {selectedChannels.length === 1 &&
                      selectedChannels.includes("twitter") && (
                        <div
                          style={
                            stripHtml(postText).length < 241
                              ? { color: "green" }
                              : postText.length > 240 &&
                                stripHtml(postText).length < 281
                              ? { color: "#CCCC00" }
                              : { color: "red" }
                          }
                        >
                          {stripHtml(postText).length}/280
                        </div>
                      )}
                  </div>
                  <PostContent
                    setContent={setPostText}
                    setShowEmojiPicker={setShowEmojiPicker}
                    postHtml={postHtml}
                    input={postRef}
                    placeholder="Start writing..."
                  />
                  <div></div>
                </div>
                <span className={classes.error}>{error.textError}</span>
                <div className={classes.uploadIcons}>
                  {isUploading ? (
                    <CircularProgress
                      size={30}
                      color="inherit"
                      variant="determinate"
                      value={imageProgress}
                    />
                  ) : (
                    <UploadImage
                      image={image}
                      setImage={setImage}
                      isUploading={isUploading}
                      setIsUploading={setIsUploading}
                      imgUpload={true}
                      setProgress={setImageProgress}
                    />
                  )}
                  {isGifUploading ? (
                    <CircularProgress
                      size={30}
                      color="inherit"
                      variant="determinate"
                      value={gifProgress}
                    />
                  ) : (
                    <UploadImage
                      image={image}
                      setImage={setImage}
                      isUploading={isGifUploading}
                      setIsUploading={setIsGifUploading}
                      gifUpload={true}
                      setProgress={setGifProgress}
                    />
                  )}
                  {isVideoUploading ? (
                    <CircularProgress
                      size={30}
                      color="inherit"
                      variant="determinate"
                      value={videoProgress}
                    />
                  ) : (
                    <VideoUpload
                      video={video}
                      setVideo={setVideo}
                      isUploading={isVideoUploading}
                      setIsUploading={setIsVideoUploading}
                      setLinkInput={setLinkInput}
                      linkInput={linkInput}
                      setVideoProgress={setVideoProgress}
                      setLinkType={setLinkType}
                    />
                  )}
                  <button className={classes.highlight} onClick={highlightDoc}>
                    <Tooltip title="Highlighted text" arrow>
                      <HighlightIcon />
                    </Tooltip>
                  </button>
                  <button className={classes.highlight} onClick={unHighlight}>
                    <Tooltip title="Un-highlight text" arrow>
                      <UnHighlightIcon />
                    </Tooltip>
                  </button>
                  <div style={{ position: "relative" }}>
                    <Tooltip title="Add Emoji" arrow>
                      <button
                        className={classes.highlight}
                        onClick={toggleEmojiPicker}
                      >
                        <AddEmoji />
                      </button>
                    </Tooltip>

                    {showEmojiPicker ? (
                      <ClickAwayListener onClickAway={toggleEmojiPicker}>
                        <EmojiPicker>
                          <Picker
                            set="twitter"
                            onSelect={addEmoji}
                            style={{
                              position: "absolute",
                              right: "-10px",
                              top: "-326px",
                              height: 320,
                              overflow: "hidden",
                            }}
                          />
                        </EmojiPicker>
                      </ClickAwayListener>
                    ) : null}
                  </div>
                </div>
                <span className={classes.error}>{error.instagramError}</span>
                <div className={classes.uploadImgContainer}>
                  {linkInput && (
                    <div style={{ display: "block", width: "100%" }}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          width: "94%",
                          paddingTop: 10,
                        }}
                      >
                        <Name
                          name="video_link"
                          label=""
                          variant="outlined"
                          marginTop="unset"
                          width="100%"
                          icon={false}
                          spanMargin="0"
                          placeholder={getVideoPlaceholder(linkType)}
                          value={link}
                          onChangeHandler={(e) => setLink(e.target.value)}
                          error={!!error.linkErr}
                          borderColor={error.linkErr ? "#f44336" : "#1D4E89"}
                        />

                        <Button
                          disableRipple
                          style={{
                            color: "#FF5269",
                            minWidth: "20px",
                            marginLeft: "12px",
                          }}
                          onClick={() => {
                            setError({ ...error, linkErr: "" });
                            handleDeleteVideo(
                              video,
                              post,
                              toEdit,
                              setVideo,
                              setLink,
                              setLinkInput,
                              true
                            );
                          }}
                        >
                          <CancelIcon />
                        </Button>
                      </div>
                      <span className={classes.error}>{error.linkErr}</span>
                    </div>
                  )}
                </div>
                <div
                  style={{
                    padding: images.length ? "10px 0px 20px 0px" : "0 0 16px 0",
                    width: "94%",
                  }}
                >
                  <Carousel
                    images={images}
                    video={video !== "" && !linkInput ? video : link}
                    setLink={setLink}
                    setVideo={setVideo}
                    setLinkInput={setLinkInput}
                    iconClass={classes.icon}
                    setImages={setImages}
                    post={post}
                    platform={platform}
                    edit={toEdit}
                  />
                </div>
                <div className={classes.field} style={{ marginTop: "0px" }}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "94%",
                      marginBottom: "5px",
                    }}
                  >
                    <FieldTitle>Creative Content Brief</FieldTitle>
                  </div>
                  <PostContent
                    setContent={setGraphicsBrief}
                    setShowEmojiPicker={setShowEmojiPicker}
                    postHtml={graphicsHtml}
                    input={graphicsRef}
                    placeholder="Start writing..."
                  />
                </div>
                <div className={classes.field}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "94%",
                      marginBottom: "5px",
                    }}
                  >
                    <FieldTitle>Actions</FieldTitle>
                  </div>
                  <PostContent
                    setContent={setInfo}
                    setShowEmojiPicker={setShowEmojiPicker}
                    postHtml={infoHtml}
                    input={infoRef}
                    placeholder="Start writing..."
                  />
                </div>
                <div style={{ width: "94%", marginTop: "26px" }}>
                  <div className={classes.field} style={{ marginTop: 0 }}>
                    <FieldTitle>Category Tags</FieldTitle>
                    <AutoTags
                      suggestions={oldTags}
                      tags={tags}
                      setTags={setTags}
                    />
                    <div style={{ display: "flex", flexWrap: "wrap" }}>
                      {tags.map((tag, index) => {
                        return (
                          <li key={index} style={{ maxWidth: "100%" }}>
                            <Chip
                              label={`#${tag}`}
                              onDelete={deleteTags(tag)}
                              className={classes.chip}
                            />
                          </li>
                        );
                      })}
                    </div>
                  </div>
                </div>
                <div
                  className={classes.field}
                  style={{
                    width: "94%",
                  }}
                >
                  <Status
                    internalStatusMonthly={internalStatusMonthly}
                    setInternalStatusMonthly={setInternalStatusMonthly}
                    internalStatusWeekly={internalStatusWeekly}
                    setInternalStatusWeekly={setInternalStatusWeekly}
                  />
                </div>
              </div>
            </PostPaper>

            <div className={classes.line} />
            <div className={`${classes.bottom} ${classes.bottomRespCommon}`}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <CancelButton disableRipple onClick={close}>
                  cancel
                </CancelButton>

                {toEdit && (
                  <Delete
                    id={postId || "id"}
                    type="post"
                    setExternalOpen={setExternalOpen}
                    setOpen={setIsOpen}
                  />
                )}
              </div>
              <SendButton
                onClick={handleSubmit}
                disabled={isUploading || isGifUploading || isVideoUploading}
                disableRipple
              >
                {submitting ? (
                  <CircularProgress size={30} color="inherit" />
                ) : (
                  <span>Save Post</span>
                )}
              </SendButton>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

export default memo(CreateEditPost);
