import React, { FC, useState, useEffect } from "react";
import { Close } from "@material-ui/icons";
import { Modal, Backdrop, Fade, CircularProgress } from "@material-ui/core";
import { DateTime } from "luxon";
import cuid from "cuid";
import { DateRange } from "materialui-daterange-picker";
import moment from "moment";
import { ReactComponent as EditIcon } from "../../assets/Edit.svg";

import { SendButton, CancelButton, PostPaper } from "components/styled/Common";
import { useStyles } from "../BePlanned/styles";
import { FieldTitle } from "components/styled/CreateEditPost";
import SingleSelect from "components/Common/SingleSelect";
import Notes from "./Notes";
import { IUser } from "interfaces/IUser";
import { useUser } from "UserContext";
import { EventService } from "services/EventService";
import { IEvent } from "../../interfaces/IEvent";
import { saveNote } from "components/BePlanned/helpers/notes";
import useCollectionSubscriber from "../../hooks/useCollectionSubscriber";
import { firestore } from "../../firebase-tools/firebase";
import { Delete } from "./Delete";
import { cancelReloadTabClose } from "utils/navigate";

import DateRangePicker from "components/Common/DateRange";
import preventScroll from "utils/preventScroll";
import { addEventNotification } from "helpers/notification/addNotification";
import { NotificationActions } from "interfaces/INotification";

interface ICreateEditEvent {
  edit?: string;
  create?: string;
  user?: any;
  event?: any;
  externalOpen: boolean;
  setExternalOpen: (open: boolean) => void;
  externalDate?: any;
  setExternalDate?: (date: any) => void;
  client?: any;
}

const selectOptions = [
  { id: 1, name: "Tentpole Idea" },
  { id: 2, name: "Viral Events" },
  { id: 3, name: "Release" },
  { id: 4, name: "Rehearsal/Recording" },
  { id: 5, name: "Performance" },
  { id: 6, name: "TV/Radio Promo" },
  { id: 7, name: "Community Management" },
  { id: 8, name: "Custom Event" },
];

const EditEvent: FC<ICreateEditEvent> = ({
  edit,
  create,
  user,
  event,
  externalOpen,
  setExternalOpen,
  externalDate,
  setExternalDate,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [, setSelectedDate]: any = useState(new Date());
  const [category, setCategory] = useState("");
  const [note, setNote] = useState("");
  const [tempNotes, setTempNotes]: Array<any> = useState([]);
  const [admin] = useUser() as Array<IUser>;
  const [error, setError] = useState({
    titleError: "",
    saveError: "",
    categoryError: "",
  });
  const [submitting, setSubmitting] = useState(false);
  const [windowOffset, setWindowOffset] = useState(window.scrollY);

  const [dateRange, setDateRange] = useState<DateRange>({
    startDate: moment().startOf("day").toDate(),
    endDate: moment().startOf("day").toDate(),
  });

  const classes = useStyles({});

  const eventId = event?.id;
  const notesQueries = [
    firestore
      .collection("events")
      .doc(event?.id || "no-Id")
      .collection("notes")
      .orderBy("created_at", "desc"),
  ];
  const notes: any = useCollectionSubscriber({
    queries: notesQueries,
    deps: [event?.id],
  }).documents;

  useEffect(() => {
    preventScroll(isOpen, windowOffset, setWindowOffset);

    // eslint-disable-next-line
  }, [isOpen, externalOpen]);

  //close popup
  const close = async (event?: any) => {
    if (event.key === "Escape") {
      setExternalOpen ? setExternalOpen(false) : setIsOpen(false);
    }
    setExternalOpen ? setExternalOpen(false) : setIsOpen(false);
    if (create) {
      setTitle("");
      setCategory("");
      setNote("");
    }
    setError({ titleError: "", saveError: "", categoryError: "" });
    setExternalDate && setExternalDate(undefined);
  };

  //save event
  const handleSubmit = async () => {
    if (title === "") {
      return setError({ ...error, titleError: "Event title is required!" });
    } else {
      setError({ ...error, titleError: "" });
    }
    if (title.length > 200) {
      return setError({
        ...error,
        titleError: "Title must be less or equal to 200 chars long",
      });
    }
    if (category === "") {
      return setError({
        ...error,
        categoryError: "Event category is required!",
      });
    } else {
      setError({ ...error, categoryError: "" });
    }
    const noteObject = {
      user: admin,
      message: note,
      created_at: new Date(),
    };

    setSubmitting(true);

    const startDate: any = dateRange.startDate;
    const endDate: any = moment(dateRange.endDate).endOf("day");

    let clientName = user.firstName || "";
    if (user.lastName) {
      clientName = user.firstName + " " + user.lastName;
    }
    if (create) {
      const uuid = cuid();
      const event = {
        id: uuid,
        created_at: new Date(),
        start_date: new Date(startDate),
        start_time: "",
        end_date: new Date(endDate),
        end_time: "",
        subject: title,
        description: "",
        event_type: category,
        user_email: user.email,
        location: "",
      };

      const Event = new EventService(uuid);
      Event.addEvent(event as IEvent)
        .then(async (res) => {
          for (const note of tempNotes) {
            await saveNote(uuid, note, "events");
          }
          setSubmitting(false);
          setExternalOpen ? setExternalOpen(false) : setIsOpen(false);
          setTitle("");
          setNote("");
          setExternalDate && setExternalDate(undefined);
        })
        .catch((e) => {
          setSubmitting(false);
          return setError(e);
        });

      setDateRange({
        startDate: moment().startOf("day").toDate(),
        endDate: moment().startOf("day").toDate(),
      });

      addEventNotification(
        NotificationActions.create_event,
        title,
        clientName,
        uuid,
        user,
        admin,
        startDate
      )
        .then((res) => {
          // ("creating notification", res);
        })
        .catch((err) => {
          // ("error creating notification", err);
        });
    }
    if (edit) {
      const eventEdit = {
        start_date: new Date(startDate),
        start_time: "",
        end_date: new Date(endDate),
        end_time: "",
        subject: title,
        description: "",
        event_type: category,
        user_email: user.email,
        location: "",
      };
      const Event = new EventService(eventId);
      Event.updateEvent(eventEdit)
        .then(async () => {
          if (note.trim() !== "") {
            await saveNote(eventId, noteObject, "events");
          }
          setSubmitting(false);
          setExternalOpen ? setExternalOpen(false) : setIsOpen(false);
          setNote("");
          setExternalDate && setExternalDate(undefined);
        })
        .catch((e) => {
          // ("error", e);
          setSubmitting(false);
          return setError({
            ...error,
            saveError: "An error occurred, please retry!",
          });
        });

      addEventNotification(
        NotificationActions.update_event,
        title,
        clientName,
        eventId,
        user,
        admin,
        startDate
      )
        .then((res) => {
          // ("creating notification", res);
        })
        .catch((err) => {
          // ("error creating notification", err);
        });
    }
  };

  useEffect(() => {
    if (event) {
      const date = DateTime.fromSeconds(event?.start_date?.seconds).toFormat(
        "dd LLL yyyy"
      );
      setTitle(event.subject);
      setSelectedDate(date);
      setCategory(event.event_type);
      setDateRange({
        startDate: DateTime.fromSeconds(event?.start_date?.seconds).toJSDate(),
        endDate: DateTime.fromSeconds(
          event.end_date ? event.end_date?.seconds : event.start_date?.seconds
        ).toJSDate(),
      });
    }
  }, [event]);

  useEffect(() => {
    cancelReloadTabClose(note.length > 0);
  }, [note]);

  useEffect(() => {
    externalDate && setSelectedDate(externalDate);
    externalDate &&
      setDateRange({
        startDate: externalDate,
        endDate: externalDate,
      });
  }, [externalDate]);

  return (
    <div>
      {edit && (
        <EditIcon
          style={{
            marginTop: "8px",
            cursor: "pointer",
          }}
          onClick={() => setExternalOpen(!externalOpen)}
        />
      )}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={externalOpen}
        closeAfterTransition
        onClose={close}
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={typeof externalOpen === "boolean" ? externalOpen : isOpen}>
          <div className={classes.container} id="edit-create">
            <PostPaper>
              <div className={classes.top}>
                <div className={classes.print}>
                  {create && <span>New </span>}
                  {edit && <span>Edit </span>}
                  Event
                </div>
                <Close className={classes.icon} onClick={close} />
              </div>
              <div className={classes.postSection} id="post-section">
                <div className={classes.field}>
                  <FieldTitle>Event Title</FieldTitle>
                  <div className={classes.choose} style={{ border: "none" }}>
                    <input
                      type="text"
                      className={classes.input}
                      placeholder="Start writing..."
                      value={title}
                      onChange={(e) => {
                        setTitle(e.target.value);
                        if (e.target.value.length > 200) {
                          setError({
                            ...error,
                            titleError:
                              "Title must be less or equal to 200 chars long",
                          });
                        } else {
                          setError({
                            ...error,
                            titleError: "",
                          });
                        }
                      }}
                      style={{ width: "100%" }}
                    />
                  </div>
                </div>
                <span className={classes.error}>{error.titleError}</span>
                <div className={classes.field}>
                  <FieldTitle>Event Date{create && "(s)"}</FieldTitle>
                  <div
                    className={classes.datePicker}
                    style={{ marginBottom: "-20px" }}
                  >
                    <DateRangePicker
                      dateRange={dateRange}
                      setDateRange={setDateRange}
                      hidePeriod={true}
                    />
                  </div>
                </div>
                <div className={classes.field}>
                  <FieldTitle>Event Category</FieldTitle>
                  <div style={{ width: "94%" }}>
                    <SingleSelect
                      selected={category}
                      setSelected={setCategory}
                      options={selectOptions}
                      applyColors={true}
                    />
                  </div>
                </div>
                {error.categoryError && (
                  <span className={classes.error}>{error.categoryError}</span>
                )}
                <Notes
                  user={admin}
                  note={note}
                  setNote={setNote}
                  notes={create ? tempNotes : notes}
                  event={event}
                  setTempNotes={setTempNotes}
                  client={user}
                />
                <span className={classes.error}>{error.saveError}</span>
              </div>
            </PostPaper>
            <div className={classes.line} />
            <div className={`${classes.bottom} ${classes.bottomResp}`}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <CancelButton disableRipple onClick={close}>
                  cancel
                </CancelButton>
                {edit && <Delete id={event?.id || "id"} type="event" />}
              </div>
              <SendButton
                onClick={handleSubmit}
                disabled={submitting}
                disableRipple
                style={{ marginBottom: 0 }}
              >
                {submitting ? (
                  <CircularProgress size={30} color="inherit" />
                ) : (
                  <>
                    {create && <span>Add Event</span>}
                    {edit && <span>Save Event</span>}
                  </>
                )}
              </SendButton>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

export default EditEvent;
