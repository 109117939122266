import React, { FunctionComponent, useState, useEffect, useRef } from "react";
import {
  Box,
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
  MenuItem,
  MenuList,
  Tooltip,
} from "@material-ui/core";
import cuid from "cuid";
import FileUploader from "react-firebase-file-uploader";

import { PostService } from "services/PostService";
import { useStyles } from "./styles";
import { IUser } from "interfaces/IUser";
import { useUser } from "UserContext";

import videoUploadFile from "assets/attach-video.svg";

type FileUploadType = {
  video: string;
  setVideo: (video: string) => void;
  avatar?: any;
  isUploading?: boolean;
  setIsUploading?: (isUploading: boolean) => void;
  linkInput?: boolean;
  setLinkInput?: (linkInput: boolean) => void;
  imgUpload?: boolean;
  gifUpload?: boolean;
  setVideoProgress: (progress) => void;
  setLinkType?: (type: string) => void;
  setLink?: (link: string) => void;
};

const VideoUpload: FunctionComponent<FileUploadType> = ({
  setVideo,
  isUploading,
  setIsUploading = () => {},
  setLinkInput = () => {},
  setVideoProgress,
  setLinkType = () => {},
  setLink = () => {},
}) => {
  const classes = useStyles({});
  const [open, setOpen] = useState(false);
  const [videoUploading, setVideoUploading] = useState(false);
  const anchorRef = useRef<HTMLDivElement>(null);

  const [storage, setStorage] = useState() as any;
  const fileUid: string = cuid();
  const Post = new PostService();
  const [authUser] = useUser() as Array<IUser>;

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    setOpen(false);
  };

  const handleListKeyDown = (event) => {
    if (event.key === "tab") {
      event.preventDefault();
      setOpen(false);
    }
  };

  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      if (anchorRef.current) {
        anchorRef.current.focus();
      }
    }

    prevOpen.current = open;
  }, [open]);

  const handleUploadStart = () => {
    setIsUploading(true);
  };
  const handleProgress = (progress: number) => setVideoProgress(progress);
  const handleUploadError = (error: boolean) => {
    setIsUploading(false);
    // ("error");
  };
  const handleUploadSuccess = async (filename: string) => {
    await setVideo(filename);
    await Post.uploadVideo(filename)
      .then((url) => {
        setVideo(url);
        setVideoProgress(100);
        setIsUploading(false);
        setLink("");
      })
      .catch((error) => {
        // (error);
      });
  };

  useEffect(() => {
    const storage = Post.getVideoStorage();
    setStorage(storage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box className={classes.box}>
      {authUser.role !== "viewerAdmin" && (
        <div ref={anchorRef}>
          <label>
            <Tooltip title="Add Video" arrow>
              <img
                src={videoUploadFile}
                alt="videoUpload"
                style={{ cursor: "pointer" }}
                onClick={handleToggle}
              />
            </Tooltip>

            <Popper
              className={classes.popper}
              anchorEl={anchorRef.current}
              open={open}
              role={undefined}
              transition
              disablePortal
              style={{
                position: "relative",
              }}
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin:
                      placement === "bottom" ? "center top" : "center bottom",
                  }}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={handleClose}>
                      <MenuList
                        autoFocusItem={open}
                        id="menu-list-grow"
                        onKeyDown={handleListKeyDown}
                        style={{ padding: "0px" }}
                      >
                        <MenuItem
                          className={classes.li}
                          onClick={() => {
                            setVideoUploading(true);
                            setLinkInput(false);
                            handleToggle();
                          }}
                        >
                          Upload Video (.mp4)
                          {videoUploading && (
                            <FileUploader
                              disabled={isUploading}
                              name="videoUrl"
                              hidden
                              accept="video/*"
                              storageRef={storage}
                              onUploadStart={handleUploadStart}
                              onUploadError={handleUploadError}
                              onUploadSuccess={handleUploadSuccess}
                              onProgress={handleProgress}
                              filename={(fileObj) => {
                                const fileName = fileObj.name
                                  .split(".")
                                  .slice(0, -1)
                                  .join(".");

                                return fileName + ` (${fileUid})`;
                              }}
                            />
                          )}
                        </MenuItem>
                        <div className={classes.line}></div>
                        <MenuItem
                          className={classes.li}
                          onClick={() => {
                            setVideoUploading(false);
                            setLinkInput(true);
                            handleToggle();
                            setLinkType("vimeo");
                          }}
                        >
                          Add Vimeo Link
                        </MenuItem>
                        <MenuItem
                          className={classes.li}
                          onClick={() => {
                            setVideoUploading(false);
                            setLinkInput(true);
                            handleToggle();
                            setLinkType("youtube");
                          }}
                        >
                          Add Youtube Link
                        </MenuItem>
                        <MenuItem
                          className={classes.li}
                          onClick={() => {
                            setVideoUploading(false);
                            setLinkInput(true);
                            handleToggle();
                            setLinkType("dropbox");
                          }}
                        >
                          Add Dropbox Link
                        </MenuItem>
                        <div className={classes.line}></div>
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </label>
        </div>
      )}
    </Box>
  );
};

export default VideoUpload;
