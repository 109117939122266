import {
  Button,
  CircularProgress,
  Grid,
  IconButton,
  MenuItem,
  Modal,
  Select,
  makeStyles,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { IUser } from "interfaces/IUser";
import Image from "components/Common/Image/Image";
// import moment from "moment";
// import { historicalData } from "../requests/historicalData";
import { failureAsync, success } from "utils/notification";
import axios from "axios";
import CloseIcon from "@material-ui/icons/Close";
import { navigate } from "@reach/router";

const useStyles = makeStyles((theme) => ({
  // Add any additional styles you may need
  formContainer: {
    backgroundColor: "white",
    padding: theme.spacing(2),
    borderRadius: "8px",
    boxShadow: theme.shadows[5],
    outline: "none",
  },
  // Add any additional styles you may need for the form
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    overflowY: "auto",
  },
  form: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(3),
    height: "100%",
    minHeight: "109px", // Assuming "hug" means auto
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    outline: "none",
    overflowY: "auto",
    // add media query for mobile
    width: 500,
    [theme.breakpoints.down("sm")]: {
      width: "90%",
      height: "100%",
      margin: "auto",
    },
  },
  closeIcon: {
    position: "absolute",
    top: theme.spacing(1),
    right: theme.spacing(1),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 320,
  },
  chip: {
    margin: "4px 4px 0px 0px",
    maxWidth: "100%",
    fontFamily: "'Avenir Book', sans-serif",
  },
  choose: {
    display: "flex",
    alignItems: "center",
    border: "2px solid #ABADAD",
    // border: (props: any) =>
    //   props.dropdown ? "2px solid #000" : "2px solid #ABADAD",
    width: "100%",
    borderRadius: "10px",
    marginTop: "5px",
    "&:hover": {
      border: "2px solid #000",
    },
  },

  radioLabel: {
    color: "black" /* Set the color of the checked radio button */,
  },
  input: {
    width: "94%",
    padding: "12.5px",
    border: "2px solid #ABADAD",
    boxSizing: "border-box",
    borderRadius: "10px",
    cursor: "pointer",
    fontFamily: "'Avenir Book', sans-serif",
    lineHeight: "20px",
    fontSize: "15px",
    "&:focus": {
      border: "2px solid #000",
      outline: "none",
    },
  },
  field: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    // marginTop: "26px",
    transition: "all 0.3s ease",
  },
  uploadIcons: {
    display: "flex",
    // paddingTop: 5,
    alignItems: "end",
    // justifyContent: "flex-end",
    width: "94%",
    "& > *": {
      cursor: "pointer",
    },
  },
  error: {
    color: "#f44336",
    fontSize: "15px",
    textAlign: "center",
    width: "75%",
  },
  highlight: {
    border: "none",
    background: "none",
    padding: "0px",
    "&:active, &:focus": {
      border: "none",
      outline: "none",
      padding: "0px",
    },
  },

  uploadImgContainer: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    // marginTop: 12,
    width: "94%",
  },

  icon: {
    color: "#000",
    cursor: "pointer",
    fontSize: "30px",
    fontWeight: "bold",
    transition: "all .3s ease",
  },
  loadingIconContainer: {
    display: "inline-block",
    animation: "$pulse 1.5s infinite alternate", // Reference the pulse animation
  },

  loadingIcon: {
    width: "30px", // Adjust the initial size of the icon
    height: "30px", // Adjust the initial size of the icon
    transition: "all 0.3s ease-in-out", // Smooth transition for size change
  },

  loadingText: {
    marginLeft: "8px", // Add some spacing between the icon and text
  },

  "@keyframes pulse": {
    to: {
      transform: "scale(1.2)", // Adjust the scale factor for the pulse effect
    },
  },
  savebtn: {
    marginTop: "10px",
    width: "150px",
    height: "45px",
    padding: "11.5px 8px 11.5px 8px",
    borderRadius: "15px",
    border: "1px solid",
    backgroundColor: "#000",
    cursor: "pointer",
    position: "relative",
    display: "flex",
    justifyContent: "center",
    color: "white",
    fontSize: "15px",
    fontWeight: 900,
    fontFamily: "Avenir",
    alignItems: "center",
    "&:disabled": {
      backgroundColor: "#888",
      cursor: "not-allowed",
      color: "#666",
    },
  },
  chatgpticonContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%", // Optional: Set height for full-height centering
  },
  "@media (max-width: 600px)": {
    chatgpticonContainer: {
      height: "50vh", // Adjust the height for smaller screens
    },
  },
  label: {
    marginTop: "5px",
  },
  btnstyle: {
    // width: "200px",
    height: "45px",
    padding: "11.5px 50px 11.5px 50px",
    borderRadius: "15px",
    backgroundColor: "#000000",
    color: "white",
    fontFamily: "Avenir",
    fontSize: "13px",
    fontWeight: 900,
    lineHeight: "22px",
    letterSpacing: "0.429px",
    textAlign: "center",
  },
  btnDisableStyle: {
    opacity: 0.5,
    cursor: "not-allowed",
    // Add any other styles you want for the disabled state
  },
  btn2: {
    height: "45px",
    background: "#000000",
    borderRadius: "15px",
    textTransform: "capitalize",
    fontFamily: "'Avenir Black', sans-serif",
    fontSize: "15px",
    fontWeight: 900,
    fontStyle: "normal",
    lineHeight: "22px",
    color: "#FFFFFF",
    marginBottom: "5px",
    justifyContent: "center",
    // float: "right",
    alignItems: "center",

    "&:hover": {
      backgroundColor: "#000000",
      opacity: 0.85,
    },

    "&:disabled": {
      color: "#fff !important",
      opacity: 0.5,
      cursor: "not-allowed !important",
    },

    "@media screen and (max-width: 750px)": {
      float: "right",
    },
  },
  img: {
    width: "40px",
    left: "35.67%",
    right: "60.36%",
    top: "15.64%",
    bottom: "18.79%",
    marginRight: "15px",
  },
}));

const InstagramUserPages = ({
  userInfo,
  mediaIcon,
}: {
  userInfo: IUser | any;
  mediaIcon: any;
}) => {
  const { instagram } = userInfo;
  const { pages } = instagram;
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [selectedPage, setSelectedPage] = useState(
    userInfo?.instagram?.pages[0]?.id || ""
  );
  // const [, setError] = useState({ submit: "", platform: "" });

  const handleChange = (event) => {
    const newValue = event.target.value;
    // ("Selected value:", newValue); // Log the new value
    setSelectedPage(newValue);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const checkUrlForParams = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const displayIgPages = urlParams.get("displayigpages");
    const igError = urlParams.get("igerror");
    if (igError === "nobusiness") {
      failureAsync("No Business Account Found for Instagram", 10000).then(
        () => {
          const url = new URL(window.location.href);
          url.searchParams.delete("igerror");
          window.location.href = url.href;
        }
      );
      return;
    } else if (displayIgPages) {
      setOpenModal(true);
    }
  };

  // const fetchHistoricData = async (username) => {
  //   try {
  //     const startDate = moment().subtract(13, "days").toISOString();
  //     const endDate = moment().subtract(1, "days").toISOString();
  //     const channel = "instagram";
  //     const res = await historicalData(startDate, endDate, channel, username);
  //     setError({ ...error, submit: "" });
  //     if (res.message) {
  //       success("Historical data added successfully!");
  //     } else {
  //       if (res?.error?.includes("last 28 days excluding")) {
  //         setError({ ...error, submit: res.error });
  //         return false;
  //       }
  //       failure("Unable to add historical data at the moment, please retry!");
  //     }
  //   } catch (e) {
  //     setError({
  //       ...error,
  //       submit: "unable to add historical data at the moment, please retry!",
  //     });
  //   }
  // };

  const handleSetUsername = async () => {
    if (!userInfo || !userInfo.instagram || !selectedPage) {
      return failureAsync("Invalid userInfo or selectedPage");
    }

    try {
      setIsLoading(true);
      await axios.get(
        `${process.env.REACT_APP_FUNCTIONS_BASE_URL}instagram-setusername?email=${userInfo.email}&fbId=${selectedPage}`
      );
      success("Instagram business linked successfully");
      if (userInfo?.instagram?.username) {
        const url = new URL(window.location.href);
        url.searchParams.delete("displayigpages");
        window.location.href = url.href;
        // fetchHistoricData(userInfo.instagram.username).then(() => {
        // });
      }
    } catch (error) {
      console.error("Error setting Instagram username:", error);
      failureAsync("Only Instagram business accounts can be linked", 10000);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    checkUrlForParams();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Modal
        className={classes.modal}
        open={openModal}
        aria-labelledby="chatgpt-post-generator-title"
        aria-describedby="chatgpt-post-generator-description"
      >
        <div className={classes.formContainer}>
          <form className={classes.form}>
            <IconButton
              className={classes.closeIcon}
              onClick={(e) => {
                e.preventDefault();
                const url = new URL(window.location.href);
                url.searchParams.delete("displayigpages");
                setOpenModal(false);
                navigate(url.href);
              }}
            >
              <CloseIcon />
            </IconButton>
            <Image
              className={classes.img}
              src={mediaIcon}
              alt={`facebook-icon`}
            />

            <br />
            <Grid container>
              <Grid
                xs={12}
                md={12}
                style={{
                  padding: "10px",
                }}
              >
                <div>
                  <p style={{ fontWeight: "bold" }}>Please choose a page</p>
                  <Select
                    id="postTypeSelect"
                    variant="outlined"
                    value={selectedPage}
                    onChange={handleChange}
                    disabled={isLoading}
                    style={{
                      width: "100%",
                      border: "1px solid #E8E8E8",
                      borderRadius: "15px",
                      height: "300px !important", // Adjust the height value as needed
                    }}
                  >
                    {pages &&
                      pages.map((page) => (
                        <MenuItem key={page?.id} value={page?.id}>
                          {page?.name}
                        </MenuItem>
                      ))}
                  </Select>
                </div>
                <br />

                <Grid container>
                  <Grid xs={12} md={6}></Grid>
                  <Grid
                    xs={12}
                    md={3}
                    style={{
                      paddingRight: "5px",
                    }}
                  ></Grid>
                  <Grid xs={12} md={6}>
                    <Button
                      className={classes.btn2}
                      disabled={!selectedPage || isLoading}
                      onClick={handleSetUsername}
                      disableRipple
                      type="button"
                      style={{
                        width: "100%",
                      }}
                    >
                      {isLoading && (
                        <CircularProgress
                          size={24}
                          style={{ marginRight: "8px" }}
                        />
                      )}
                      {!isLoading ? "Continue" : "Loading..."}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </div>
      </Modal>
    </>
  );
};

export default InstagramUserPages;
