/**
 * @prettier
 */
import React, { useState, useEffect } from "react";
import TableBody from "@material-ui/core/TableBody";
import Avatar from "@material-ui/core/Avatar";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import FacebookIcon from "static/media/fb-vector.png";
import TwitterIcon from "static/media/twitter-vector.png";
import InstagramIcon from "static/media/instagram-vector.png";
import TiktokIcon from "static/media/tiktok.png";
import YoutubeIcon from "static/media/youtube.png";
import SpotifyIcon from "static/media/spotify-logo.png";
import { ReactComponent as AvatarIcon } from "static/media/avatarIcon.svg";
import ToggleActivate from "components/ToggleActivate";
import { IUser } from "interfaces/IUser";
import { Chip } from "@material-ui/core";
import Actions from "../ActionsDropdown";
import Loader from "../Loader";
import { useStyles } from "./styles";
import Image from "components/Common/Image/Image";
import "./styles.css";

export enum SortBy {
  Name = "firstName",
  Email = "email",
  Date = "createdAt",
}
interface SearchUserManagementProps {
  usersList: any;
  allInvited: any;
  usersForCurrentPage: any;
}

export const UserManagement = ({
  usersList,
  allInvited,
  usersForCurrentPage,
}: SearchUserManagementProps) => {
  const classes = useStyles({});
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    // if (usersList.loading) {
    //   console.log("kdjskdjsjkdjsjkdjksjkdjksdkjsdjksdsjks", usersList);
    // }

    setIsLoading(usersList.loading);
    // eslint-disable-next-line
  }, [usersList.loading]);

  return !isLoading ? (
    <TableBody>
      {usersForCurrentPage.map((client: IUser) =>
        !client.isArchived ? (
          <TableRow key={client.id} className={classes.row}>
            <TableCell align="center" className={classes.tableCell}>
              {client.avatarUrl && client.avatarUrl.length ? (
                <Avatar
                  alt="avatar"
                  src={client.avatarUrl}
                  className={classes.avatar}
                />
              ) : (
                <AvatarIcon style={{ width: "50px", height: "50px" }} />
              )}
            </TableCell>
            <TableCell align="center">
              <div className={`${classes.client} ${classes.tableCell}`}>
                <p
                  className={classes.clientName}
                >{`${client.firstName} ${client.lastName}`}</p>
                {!client.isSignedUp && !allInvited(client) ? (
                  <Chip
                    classes={{
                      root: classes.chipRoot,
                      label: classes.chipLabel,
                    }}
                    label={"Pending invites"}
                  />
                ) : null}
              </div>
            </TableCell>
            <TableCell align="center">
              <div
                className={`${classes.tableCell}`}
                style={{ display: "flex" }}
              >
                {client.instagram && client.instagram.username ? (
                  <a
                    href={client.instagram.url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Image
                      className={classes.img}
                      src={InstagramIcon}
                      alt="instagram"
                    />
                  </a>
                ) : null}
                {client.facebook && client.facebook.username ? (
                  <a
                    href={client.facebook.url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Image
                      className={classes.img}
                      src={FacebookIcon}
                      alt="facebook"
                    />
                  </a>
                ) : null}
                {client.twitter && client.twitter.username ? (
                  <a
                    href={client.twitter.url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Image
                      className={classes.img}
                      src={TwitterIcon}
                      alt="twitter"
                    />
                  </a>
                ) : null}
                {client.tiktok && client.tiktok.username ? (
                  <a
                    href={client.tiktok.url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Image
                      className={classes.img}
                      src={TiktokIcon}
                      alt="tiktok"
                    />
                  </a>
                ) : null}
                {client.youtube && client.youtube.username ? (
                  <a
                    href={client.youtube.url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Image
                      className={classes.img}
                      src={YoutubeIcon}
                      alt="youtube"
                    />
                  </a>
                ) : null}
                {client.spotify && client.spotify.username ? (
                  <a
                    href={client.spotify.url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Image
                      className={classes.img}
                      src={SpotifyIcon}
                      alt="spotify"
                    />
                  </a>
                ) : null}

                {/* Hide for now */}
                {/* {client.appleMusic && client.appleMusic.username ? (
                    <a
                      href={client.appleMusic.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Image
                        className={classes.img}
                        src={AppleMusicIcon}
                        alt="appleMusic"
                      />
                    </a>
                  ) : null} */}
              </div>
            </TableCell>
            <TableCell align="center" className={classes.tableCell}>
              <ToggleActivate
                user={client}
                isActivated={client.isActivated}
                type="client"
              />
            </TableCell>
            <TableCell align="center" className={classes.tableCell}>
              <Actions user={client} />
            </TableCell>
          </TableRow>
        ) : null
      )}
    </TableBody>
  ) : (
    <Loader />
  );
};
