import axios from "axios";
import { IUser, IUserArray } from "interfaces/IUser";

class AuthService {
  private baseUrl = process.env.REACT_APP_FUNCTIONS_BASE_URL;

  private validateBaseUrl(): void {
    if (!this.baseUrl) {
      throw new Error("Invalid base URL");
    }
  }

  fetchAllCustomers = async (): Promise<IUser[]> => {
    this.validateBaseUrl();
    try {
      const { data } = await axios.get(
        `${this.baseUrl}authentication-getUsers`
      );
      return data.users;
    } catch (error) {
      console.error("Error fetching customers:", error);
      throw error;
    }
  };

  fetchUserById = async (id: string): Promise<IUser> => {
    this.validateBaseUrl();
    try {
      const { data } = await axios.get(
        `${this.baseUrl}authentication-getUsers`,
        {
          params: { id },
        }
      );
      return data.user;
    } catch (error) {
      console.error(`Error fetching user with ID ${id}:`, error);
      throw error;
    }
  };

  createUser = async (data: any): Promise<IUser> => {
    this.validateBaseUrl();
    try {
      const { data: responseData } = await axios.post(
        `${this.baseUrl}authentication-createUser`,
        data
      );
      return responseData.user;
    } catch (error) {
      console.error("Error creating user:", error);
      throw error;
    }
  };

  getUsersData = async (showArchived = false): Promise<IUserArray> => {
    // ("UserService:getUsersData:start");
    try {
      let users = await this.fetchAllCustomers();

      if (!showArchived) {
        users = users.filter((user: IUser) => !user.isArchived);
      }

      // (`UserService:getUsersData:users=${JSON.stringify(users)}`);
      return {
        documents: users,
        loading: false,
        error: "",
      };
    } catch (error) {
      console.error("Error getting users data:", error);
      return {
        documents: [],
        loading: false,
        error: error.message,
      };
    }
  };

  updateUser = async (id: string, data: any): Promise<IUser | null> => {
    this.validateBaseUrl();
    try {
      if (!data) return null;
      const { data: responseData } = await axios.post(
        `${this.baseUrl}authentication-updateUser`,
        {
          id,
          data,
        }
      );
      return responseData.user;
    } catch (error) {
      console.error("Error updating user:", error);
      throw error;
    }
  };
}

export default new AuthService();
