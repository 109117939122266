import { Grid } from "@material-ui/core";
import React, { FC, useEffect, useState } from "react";
import { PageType } from "types/PageType";
import SearchIcon from "@material-ui/icons/Search";
import { ClientListV2 } from "components/BeMeasured/ClientListV2";
import moment from "moment";
import { DateRange } from "materialui-daterange-picker";
import { firestore } from "../../firebase-tools/firebase";
import useCollectionSubscriber from "hooks/useCollectionSubscriber";
import useDocumentSubscriber from "hooks/useDocumentSubscriber";
import { IPost } from "interfaces/IPost";
import { useUser } from "UserContext";
import { IUser } from "interfaces/IUser";
// import { UserService } from "services/UserService";
import Select from "components/Common/Select";
import DateRangePicker from "components/Common/DateRangePicker";
import PostList from "./PostList";
import { urlDates } from "utils/dateFormat";
import history from "history/browser";
import authService from "helpers/user/auth.service";

const channelOptions = ["Instagram", "Facebook", "All"];
const statusOptions = ["Failed", "Posted", "Pending", "All"];

const inputStyle = {
  display: "flex",
  width: "100%",
  padding: "4px 19.5px 4px 21.5px",
  justifyContent: "space-between",
  alignItems: "center",
  borderRadius: "15px",
  border: "1px solid #E8E8E8",
  background: "#FFF",
  outline: "none", // Remove outline on focus
};
const labelStyle = {
  color: "#000000",
  fontWeight: 900,
  fontSize: "15px",
  fontFamily: "Avenir",
  paddingBottom: "10px",
};
const toolbarSpacing = { padding: "5px" };

const SchedulePost: FC<PageType> = ({ location }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [dateRange, setDateRange] = React.useState<DateRange>({
    startDate: moment().subtract(6, "years").toDate(),
    endDate: moment().add(6, "days").endOf("years").toDate(),
  });
  const [authUser] = useUser() as Array<IUser>;
  const [loading, setLoading] = useState(true);
  const [, setClient]: any = useState("");
  const [channel, setChannel]: any = useState("All");
  const [status, setStatus]: any = useState("All");
  const [paramsChange, setParamsChange]: any = useState("");

  const handleSearchChange = (event) => {
    const newSearchTerm = event.target.value;
    setSearchTerm(newSearchTerm);
  };

  const from = new Date(dateRange.startDate as Date);
  let toDate = new Date(dateRange.endDate as Date);
  const to = moment(toDate).add(1, "days").toDate();

  let userRef = firestore.collection("users").doc(authUser.id);
  let client =
    useDocumentSubscriber({
      ref: userRef,
    }).document?.lastClientSelection || "";

  let postsQueries: any = [];
  if (client !== "") {
    if (status === "All") {
      postsQueries = [
        firestore
          .collection("scheduled-posts")
          .where("user_email", "==", client.email)
          .where("date", ">=", from)
          .where("date", "<=", to)
          // .where("status", "==", statuses[0] === "All" ? "" : statuses[0])
          .orderBy("date", "desc"),
      ];
    } else if (status !== "All") {
      postsQueries = [
        firestore
          .collection("scheduled-posts")
          .where("user_email", "==", client.email)
          .where("date", ">=", from)
          .where("date", "<=", to)
          .where("status", "==", status)
          .orderBy("date", "desc"),
      ];
    }
  } else if (!client) {
    if (status === "All") {
      postsQueries = [
        firestore
          .collection("scheduled-posts")
          .where("date", ">=", from)
          .where("date", "<=", to)
          // .where("status", "==", statuses[0] === "All" ? "" : statuses[0])
          .orderBy("date", "desc"),
      ];
    } else if (status !== "All") {
      postsQueries = [
        firestore
          .collection("scheduled-posts")
          .where("date", ">=", from)
          .where("date", "<=", to)
          .where("status", "==", status)
          .orderBy("date", "desc"),
      ];
    }
  }

  let posts;
  posts = useCollectionSubscriber<IPost>({
    queries: postsQueries,
    deps: [dateRange, client],
  });

  if (searchTerm) {
    let found = posts?.documents?.filter((val) =>
      val?.title?.toLowerCase()?.includes(searchTerm?.toLowerCase())
    );
    posts.documents = found || [];
  }

  if (status && status !== "All") {
    let found = posts?.documents?.filter((val) =>
      val?.status?.toLowerCase()?.includes(status?.toLowerCase())
    );
    posts.documents = found || [];
  }
  if (channel && channel !== "All") {
    let found = posts?.documents?.filter((val) =>
      val?.platform?.toLowerCase()?.includes(channel?.toLowerCase())
    );
    posts.documents = found || [];
  }

  // const settClient = (e) => {
  //   const User = new UserService(authUser.id);

  //   return User.updateUser({
  //     lastClientSelection: e,
  //   })
  //     .then((res) => {
  //       setClient(e);
  //     })
  //     .catch((err) => {});
  // };

  const setClientNew = (e) => {
    // ("setClientNew", e);
    return authService
      .updateUser(authUser.id, {
        lastClientSelection: e,
      })
      .then((res) => {
        setClient(e);
        // setAuthUser((user) => ({ ...user, lastClientSelection: e }));
      })
      .catch((err) => {});
  };

  useEffect(() => {
    urlDates(dateRange, history, paramsChange, setDateRange, location);

    if (client !== "") {
      // setLoading(true);
    }
    if (posts) {
      setLoading(false);
    }
  }, [client, dateRange, location, paramsChange, status, channel, posts]);

  return (
    <div>
      <Grid container>
        <h1 style={toolbarSpacing}>Post Auto Scheduler</h1>

        <Grid container>
          <Grid xs={12} md={5}>
            <Grid container>
              <Grid xs={12} md={7} style={toolbarSpacing}>
                <div
                  style={{
                    color: "#000000",
                    fontWeight: 900,
                    fontSize: "15px",
                    fontFamily: "Avenir",
                    paddingBottom: "10px",
                  }}
                >
                  Search
                </div>
                <div style={inputStyle}>
                  <input
                    type="text"
                    placeholder="Search a post"
                    value={searchTerm}
                    onChange={handleSearchChange}
                    style={{
                      border: "none", // Remove border
                      width: "100%", // Full width
                      padding: "8px", // Adjust padding if needed
                      fontSize: "15px", // Adjust font size
                      fontFamily: "Avenir",
                      fontWeight: 350,
                    }}
                  />
                  <SearchIcon
                    style={{ height: "24px", width: "24px", color: "#ABADAD" }}
                  />
                </div>
              </Grid>
              <Grid xs={12} md={5} style={toolbarSpacing}>
                <ClientListV2
                  client={client}
                  setClient={setClientNew}
                  setLoading={setLoading}
                  align="left"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid xs={12} md={7}>
            <Grid container>
              <Grid xs={12} md={4} style={toolbarSpacing}>
                <Select
                  value={channel}
                  setItems={setChannel}
                  options={channelOptions}
                  label="Choose Platform"
                  multiple={false}
                  width="100%"
                  labelStyle={labelStyle}
                />
              </Grid>
              <Grid xs={12} md={4} style={toolbarSpacing}>
                <DateRangePicker
                  dateRange={dateRange}
                  setDateRange={setDateRange}
                  setParamsChange={setParamsChange}
                  minDate={new Date("2020-06-06")}
                  align={"left"}
                  label="Choose Period"
                  labelStyle={labelStyle}
                />
              </Grid>
              <Grid xs={12} md={4} style={toolbarSpacing}>
                <Select
                  value={status}
                  setItems={setStatus}
                  options={statusOptions}
                  label="Status"
                  multiple={false}
                  width="100%"
                  labelStyle={labelStyle}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid container style={toolbarSpacing}>
          {posts?.documents && (
            <PostList data={posts?.documents} isLoading={loading} />
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default SchedulePost;
