/**
 * @prettierdesc
 */

import React, { FC, useEffect, useState } from "react";
import { Box, Tooltip, CircularProgress } from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import firebase from "firebase";
import history from "history/browser";
import { useLocation } from "@reach/router";

import CreateCampaignStyles from "components/styled/CreateCampaign";
import Image from "components/Common/Image/Image";

import { SendButton } from "components/styled/Common";
import { launchSearchAgent } from "helpers/twitterAutoFollow/launchSearchAgent";
import { PageType } from "types/PageType";
import { firestore } from "firebase-tools/firebase";
import useDocumentSubscriber from "hooks/useDocumentSubscriber";
import GetCookies from "components/Common/GetCookies";
import CampaignName from "components/Common/CampaignName";
import { useUser } from "UserContext";
import { SocialMediaPlatform } from "../../../../../../containers/BeAmplified/Campaigns/Organic/SelectCampaign";
import { getPlatformImage } from "components/BeAmplified/Campaigns/Organic/Components/SelectCampaignBox";
import { launchInstagramAgent } from "helpers/instagramAutoFollow/launchAutoFollowAgent";
import { editInstagramSearchAgent } from "helpers/instagramAutoFollow/editSearchAgent";
import { toStartCase } from "helpers/instagramAutoFollow/toStartCase";
import showToast from "utils/toast";

interface CreateCampaignProps extends PageType {
  uid?: string;
  toggleCreate?: Function;
  platform?: SocialMediaPlatform;
}

export const CreateCampaign: FC<CreateCampaignProps> = ({
  toggleCreate,
  uid,
  platform = SocialMediaPlatform.Twitter,
}) => {
  let campaignRef = firestore
    .collection(
      platform === SocialMediaPlatform.Instagram
        ? "instagram-autofollow-campaigns"
        : "twitter-campaigns"
    )
    .doc(uid || "create");

  let campaignInfo = useDocumentSubscriber({ ref: campaignRef }).document;
  const edit = !!uid;

  const [campaignName, setCampaignName] = useState("");
  const [sessionCookie, setSessionCookie] = useState("");
  const [hash, setHash] = useState([""]);
  const [exact, setExact] = useState([""]);
  const [exclude, setExclude] = useState([""]);
  const [followPerDay] = useState(
    platform === SocialMediaPlatform.Instagram ? "5000" : "50"
  );
  const [loading, setLoading] = useState(false);
  const [authUser] = useUser() as any;
  const [errors, setErrors] = useState({
    campaignName: "",
    hash: "",
  });

  const isScrapper = useLocation().pathname.includes("scrappers");

  useEffect(() => {
    if (edit) {
      setCampaignName(campaignInfo?.campaignName);
      campaignInfo?.sessionCookie &&
        setSessionCookie(campaignInfo?.sessionCookie);
      if (platform === SocialMediaPlatform.Instagram) {
        campaignInfo?.hashtags && setHash(campaignInfo?.hashtags);
      } else {
        campaignInfo?.hash && setHash(campaignInfo?.hash);
      }
      campaignInfo?.exact && setExact(campaignInfo?.exact);
      campaignInfo?.exclude && setExclude(campaignInfo?.exclude);
    }
  }, [edit, campaignInfo, platform]);

  const encodeSearchTerms = () => {
    const queryString = `"${exact.join(" ")}" -${exclude.join(
      " -"
    )} (${hash.join(" OR ")})`;

    return `https://twitter.com/search?lang=en&q=${encodeURIComponent(
      queryString
    )}&src=typed_query&f=live`;
  };

  const createCampaign = async () => {
    try {
      if (sessionCookie === "") {
        showToast("Error: Add a session Cookie to proceed");

        return;
      }
      if (!authUser) {
        showToast("Auth User is required");

        return;
      }
      if (!campaignName)
        return setErrors((errors: any) => ({
          ...errors,
          campaignName: "Campaign name is required!",
        }));
      if (hash.length === 1 && hash[0] === "")
        return setErrors((errors: any) => ({
          ...errors,
          hash: "Please add one or more hashtags",
        }));

      setLoading(true);
      if (platform === SocialMediaPlatform.Instagram) {
        if (edit) {
          await editInstagramSearchAgent(campaignInfo.id, {
            sessionCookie,
            campaignName,
            hashtags: hash,
            postsToExtract: followPerDay,
          });
        } else {
          await launchInstagramAgent({
            sessionCookie,
            campaignName,
            client: authUser.lastClientSelection.id,
            hashtags: hash,
            postsToExtract: followPerDay,
          });
        }
      } else {
        await launchSearchAgent({
          spreadsheetUrl: encodeSearchTerms(),
          sessionCookie,
          campaignName,
          exclude,
          exact,
          hash,
          uid,
          createdAt: firebase.firestore.Timestamp.now(),
          username: authUser.lastClientSelection.twitter.username,
        });
      }
      setCampaignName("");
      setSessionCookie("");
      setExclude([""]);
      setHash([""]);
      setExact([""]);
      setLoading(false);
    } catch (e) {
      // (e);
      setLoading(false);
      showToast("An error occurred!");
    }
  };

  const campaignType = isScrapper ? "Scrapper" : "Campaing";

  return (
    <CreateCampaignStyles>
      <ToastContainer />
      <div>
        <span className="title__main">{uid ? "Edit" : "Create"} Campaign</span>

        <Box marginTop="50px">
          <div className="items-center">
            <Image
              className="img__main"
              src={getPlatformImage(platform)}
              alt=""
            />
            <span className="title__sub">{`${toStartCase(platform)} ${
              window.location.href.includes("scrappers")
                ? "Search"
                : "Auto Follower"
            }`}</span>
          </div>
        </Box>
        <p className="description__main">
          {`Follow ${toStartCase(
            platform
          )} users who post particular hashtags and keywords. Some
            of them will check you out and follow you back.`}
        </p>
      </div>
      <div
        style={{
          margin: "40px 0px -20px 0px",
          display: `${isScrapper ? "none" : "flex"}`,
        }}
        className="campaign__container"
      >
        <span className="title__basic" style={{ marginBottom: 10 }}>
          Client name
        </span>
        <input
          type="text"
          value={`${authUser.lastClientSelection.firstName} ${authUser.lastClientSelection.lastName}`}
          style={{ color: "#606060" }}
          className="campaign__input description__sub campaign__input--disabled"
        />
      </div>
      <CampaignName
        campaignName={campaignName}
        setCampaignName={setCampaignName}
        error={errors.campaignName}
      />

      <GetCookies
        setSessionCookie={setSessionCookie}
        sessionCookie={sessionCookie}
        platform={platform}
        title={`Your ${toStartCase(platform)} Session Cookie`}
        platformIcon={getPlatformImage(platform)}
      />

      {/* More info */}
      <Box marginTop="40px" className="connection-container">
        <div className="campaign__container">
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <span className="title__basic">
              List of hashtags{" "}
              {`${
                platform === SocialMediaPlatform.Instagram ? "or locations" : ""
              }`}
            </span>
            <Tooltip
              title={`List of hashtags ${
                platform === SocialMediaPlatform.Instagram ? "or locations" : ""
              }`}
              arrow
              interactive
            >
              <InfoIcon style={{ color: "#C4C4C4" }} />
            </Tooltip>
          </Box>
          <span className="description__sub">
            Add list of hashtags{" "}
            {`${
              platform === SocialMediaPlatform.Instagram ? "or locations" : ""
            }`}{" "}
            comma separated.
          </span>
          <textarea
            value={hash}
            onChange={(e) =>
              setHash(e.target.value.replace(/(\r\n|\n|\r)/gm, "").split(","))
            }
            className="campaign__textarea"
          ></textarea>
          {errors.hash && (
            <span style={{ color: "red", fontSize: 14, paddingLeft: 6 }}>
              {errors.hash}
            </span>
          )}
        </div>
        {platform !== SocialMediaPlatform.Instagram && (
          <div className="campaign__container">
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <span className="title__basic">
                List of keywords (exact matches)
              </span>
              <Tooltip
                title="List of keywords (exact matches)"
                arrow
                interactive
              >
                <InfoIcon style={{ color: "#C4C4C4" }} />
              </Tooltip>
            </Box>
            <span className="description__sub">
              Add list of keywords comma separated.
            </span>
            <textarea
              value={exact}
              onChange={(e) =>
                setExact(
                  e.target.value.replace(/(\r\n|\n|\r)/gm, "").split(",")
                )
              }
              className="campaign__textarea"
            ></textarea>
          </div>
        )}

        {platform !== SocialMediaPlatform.Instagram && (
          <div className="campaign__container">
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <span className="title__basic">
                List of keywords (to exclude)
              </span>
              <Tooltip title="List of keywords (to exclude)" arrow interactive>
                <InfoIcon style={{ color: "#C4C4C4" }} />
              </Tooltip>
            </Box>
            <span className="description__sub">
              Add list of keywords comma separated.
            </span>
            <textarea
              value={exclude}
              onChange={(e) =>
                setExclude(
                  e.target.value.replace(/(\r\n|\n|\r)/gm, "").split(",")
                )
              }
              className="campaign__textarea"
            ></textarea>
          </div>
        )}

        {platform === SocialMediaPlatform.Instagram && (
          <div className="campaign__container">
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <span className="title__basic">
                Number of posts to extract per hashtag
              </span>
              <Tooltip
                title="Number of posts to extract per hashtag"
                arrow
                interactive
              >
                <InfoIcon style={{ color: "#C4C4C4" }} />
              </Tooltip>
            </Box>
            <span className="description__sub">
              Default is set to {`${followPerDay}`}
            </span>
            <input
              type="text"
              value={followPerDay}
              className="campaign__input campaign__input--disabled"
              disabled
            />
          </div>
        )}
      </Box>

      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        marginTop="40px"
      >
        <div
          className="title__sub link"
          onClick={() => {
            toggleCreate && toggleCreate();

            !toggleCreate && history.back();
          }}
        >
          {"<"} Back
        </div>
        <SendButton
          onClick={(e) => {
            e.preventDefault();
            !loading && createCampaign();
          }}
          className="btn"
          disabled={loading}
        >
          {uid ? "Update" : "Launch"} {campaignType}
          {loading && <CircularProgress className="btn-progress" size={20} />}
        </SendButton>
      </Box>
    </CreateCampaignStyles>
  );
};
