import React, { FunctionComponent } from "react";
import { Grid, Box, Input } from "@material-ui/core";

// import { useStylesV2 } from "./stylesV2";
// import { ReactComponent as EditIcon } from "static/media/edit.svg";
import { ControlLabel } from "components/styled/Common";
// import label from "material-ui/svg-icons/action/label";
// import { theme } from "./DateSelector";
import { useStylesSelect } from "components/BePlanned/stylesSelect";

interface ClientListProps {
  client: any;
  setClient: (e: any) => void;
  setLoading?: (loading: boolean) => void;
  styleProps?: any;
  label: string;
  error?: string;
  setErrors?: any;
  multiple?: boolean;
  defaultValue?: string;
  selectedClients: any[];
  setSelectedClients: (e: any) => void;
  setError: (e: any) => void;
}

// function getStyles(name, channels, theme) {
//   return {
//     fontWeight:
//       channels.indexOf(name) === -1
//         ? theme.typography.fontWeightRegular
//         : theme.typography.fontWeightMedium,
//   };
// }

// const ITEM_HEIGHT = 48;
// const ITEM_PADDING_TOP = 8;

export const ClientBeplanned: FunctionComponent<ClientListProps> = ({
  client,
  setLoading,
  setErrors,
  label,
  setSelectedClients,
  setError,
}) => {
  // const queries = [
  //   firestore
  //     .collection("users")
  //     .where("role", "==", "user")
  //     .orderBy("firstName", "asc"),
  // ];
  // let users = useCollectionSubscriber<IUser>({ queries }).documents;
  // users = users.filter((user) => !user.isArchived && !user.isExternal);
  // const [selectedClients, setSelectedClients] = useState([]);
  // const classes = useStylesV2({
  //   listLength: users.length > 11 ? "400px" : "auto",
  //   ...styleProps,
  // });

  const classes = useStylesSelect();

  const handleChange = (event) => {
    setSelectedClients(event.target.value);
    setError({
      textError: "",
      linkErr: "",
      platformError: "",
      instagramError: "",
      titleError: "",
      channelsError: "",
    });

    if (setLoading && event.target.value.length > 0) {
      setLoading(true);
    }

    if (setErrors) {
      setErrors((errors) => ({
        ...errors,
        client: "",
      }));
    }
  };

  return (
    <Grid
      id="client-list"
      style={{
        // display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <ControlLabel component="span">{label}</ControlLabel>
      <Box>
        <Input
          disableUnderline
          id="multi-select"
          value={client.firstName + " " + client.lastName}
          onChange={handleChange}
          readOnly
          className={classes.select}
          style={{
            width: "100% !important",
          }}
          inputProps={{
            className: classes.selectInput,
            classes: {
              icon: classes.selectIcon,
              iconOpen: classes.selectIconOpen,
              selectMenu: classes.selectOverrideChannels,
            },
          }}
        />
        {/* <Select
          disableUnderline
          autoWidth={true}
          id="multi-select"
          multiple={false}
          value={selectedClients}
          displayEmpty={true}
          renderValue={() => client.firstName + " " + client.lastName}
          onChange={handleChange}
          className={classes.select}
          style={{
            width: "100% !important",
          }}
          inputProps={{
            className: classes.selectInput,
            classes: {
              icon: classes.selectIcon,
              iconOpen: classes.selectIconOpen,
              selectMenu: classes.selectOverrideChannels,
            },
          }}
          MenuProps={{
            classes: { paper: classes.selectOverrideChannels },
            PaperProps: {
              style: {
                maxHeight: ITEM_HEIGHT * 5.2 + ITEM_PADDING_TOP,
                width: 200,
                marginTop: "8px",
                borderRadius: 15,
              },
            },
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "center",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "center",
            },
            getContentAnchorEl: null,
          }}
        ></Select> */}
      </Box>
    </Grid>
  );
};
