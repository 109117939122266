import Axios from "axios";
import axios from "axios";
// import { fetchFbIGMetrics, fetchMetrics } from "helpers/fetchMetrics";
import { fetchMetrics } from "helpers/fetchMetrics";

export const getMetrics = async (
  user: any,
  from: any,
  to: any,
  dateRange: any,
  previousDateRange: any,
  previousClient: any,
  setMetrics: Function,
  setFbMeta: Function,
  setIgMeta: Function,
  setLoading: Function,
  setPreviousDateRange: Function,
  setPreviousClient: Function
) => {
  if (
    previousDateRange.startDate?.toLocaleDateString() ===
      dateRange.startDate?.toLocaleDateString() &&
    previousDateRange.endDate?.toLocaleDateString() ===
      dateRange.endDate?.toLocaleDateString() &&
    previousClient.email === user.email
  ) {
    return;
  }

  const promises: any[] = [];

  if (user?.facebook?.username) {
    promises.push(
      Axios.post(
        `${process.env.REACT_APP_FUNCTIONS_BASE_URL}facebook-getMetrics`,
        {
          username: user.facebook.username,
          startDate: from,
          endDate: to,
        }
      ).then((res) => {
        const results = res.data.data;
        if (!!results.length) {
          setMetrics((metrics) => ({ ...metrics, fbMetrics: results }));
          const lastMetric = results[results.length - 1];
          const name = lastMetric?.name ? lastMetric.name : "";
          const avatar = lastMetric?.picture ? lastMetric.picture : "";
          setFbMeta({ name, avatar });
        }
      })
    );
  } else {
    setMetrics((metrics) => ({ ...metrics, fbMetrics: [] }));
  }

  if (user?.instagram?.username) {
    promises.push(
      Axios.post(
        `${process.env.REACT_APP_FUNCTIONS_BASE_URL}instagram-getMetrics`,
        {
          username: user.instagram.username,
          startDate: from,
          endDate: to,
        }
      ).then((res) => {
        const results = res.data.data;
        if (!!results.length) {
          const lastMetric = results[results.length - 1];
          const name = lastMetric?.metadata.name;
          const avatar = lastMetric?.metadata.profile_picture_url;
          setIgMeta({ name, avatar });
          setMetrics((metrics) => ({
            ...metrics,
            igStoriesMetrics: [],
            igMetrics: results,
          }));
        }
      })
    );
  } else {
    setMetrics((metrics) => ({
      ...metrics,
      igMetrics: [],
      igStoriesMetrics: [],
    }));
  }

  if (user?.twitter?.username && user?.twitter?.accessToken) {
    promises.push(
      Axios.post(
        `${process.env.REACT_APP_FUNCTIONS_BASE_URL}twitter-getMetric`,
        {
          username: user.twitter.username,
          startDate: from,
          endDate: to,
        }
      ).then((res) => {
        const results = res.data.data;
        if (!!results.length) {
          setMetrics((metrics) => ({ ...metrics, twitterMetrics: results }));
        }
      })
    );
  } else {
    setMetrics((metrics) => ({ ...metrics, twitterMetrics: [] }));
  }

  if (user?.tiktok?.username && user?.tiktok?.accessToken) {
    promises.push(
      Axios.post(
        `${process.env.REACT_APP_FUNCTIONS_BASE_URL}tiktok-getMetrics`,
        {
          username: user.tiktok.username,
          userId: user.id,
          startDate: from,
          endDate: to,
        }
      ).then((res) => {
        const results = res.data.data;
        if (!!results.length) {
          setMetrics((metrics) => ({ ...metrics, tiktokMetrics: results }));
        }
      })
    );
  } else {
    setMetrics((metrics) => ({ ...metrics, tiktokMetrics: [] }));
  }

  if (user?.youtube?.username) {
    promises.push(
      axios
        .post(`${process.env.REACT_APP_FUNCTIONS_BASE_URL}youtube-dbMetric`, {
          username: user.youtube.username,
          from,
          to,
        })
        .then((res) => {
          const results = res.data;
          if (!!results.length) {
            setMetrics((metrics) => ({ ...metrics, youtubeMetrics: results }));
          }
        })
    );
  } else {
    setMetrics((metrics) => ({ ...metrics, youtubeMetrics: [] }));
  }

  if (user?.appleMusic?.username && 2 + 2 === 6) {
    promises.push(
      fetchMetrics(
        "apple-music-metrics",
        user.appleMusic.username,
        from,
        to
      ).then((results) => {
        if (!!results.length) {
          setMetrics((metrics) => ({ ...metrics, appleMusicMetrics: results }));
        }
      })
    );
  } else {
    setMetrics((metrics) => ({ ...metrics, appleMusicMetrics: [] }));
  }

  if (user?.spotify?.username) {
    promises.push(
      Axios.post(
        `${process.env.REACT_APP_FUNCTIONS_BASE_URL}spotify-getMetrics`,
        {
          username: user.spotify.username,
          startDate: from,
          endDate: to,
        }
      ).then((res) => {
        const results = res.data.data;
        if (!!results.length) {
          setMetrics((metrics) => ({ ...metrics, spotifyMetrics: results }));
        }
      })
    );
  } else {
    setMetrics((metrics) => ({ ...metrics, spotifyMetrics: [] }));
  }

  setLoading((loading) => ({ ...loading, pageLoading: true }));
  await Promise.all(promises);
  setLoading((loading) => ({ ...loading, pageLoading: false }));
  setPreviousDateRange(dateRange);
  setPreviousClient(user);
};
