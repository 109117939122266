/* eslint-disable jsx-a11y/alt-text */
import {
  Grid,
  IconButton,
  Modal,
  Typography,
  makeStyles,
} from "@material-ui/core";
import React, { useState } from "react";
import CloseIcon from "@material-ui/icons/Close";
// import { DateTime } from "luxon";
import { ReactComponent as EyeIcon } from "../../assets/eye.svg";
import { ReactComponent as WorldIcon } from "../../assets/worldIcon.svg";
import Avatar from "../../assets/testavatar.png";
import facebook from "../../assets/facebook.png";
import Carousel from "components/Common/CarouselV2";

interface IProp {
  post: any;
}

const useStyles = makeStyles((theme) => ({
  // Add any additional styles you may need
  formContainer: {
    backgroundColor: "white",
    padding: theme.spacing(2),
    borderRadius: "8px",
    boxShadow: theme.shadows[5],
    outline: "none",
  },
  // Add any additional styles you may need for the form
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    overflowY: "auto",
  },
  form: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(3),
    width: "581px",
    height: "100%",
    minHeight: "109px", // Assuming "hug" means auto
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    outline: "none",
    overflowY: "auto",
    // media queries for mobile
    "@media screen and (max-width: 400px)": {
      width: "100%",
    },
  },
  closeIcon: {
    position: "absolute",
    top: theme.spacing(1),
    right: theme.spacing(1),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  chip: {
    margin: "4px 4px 0px 0px",
    maxWidth: "100%",
    fontFamily: "'Avenir Book', sans-serif",
  },
  choose: {
    display: "flex",
    alignItems: "center",
    border: "2px solid #ABADAD",
    // border: (props: any) =>
    //   props.dropdown ? "2px solid #000" : "2px solid #ABADAD",
    width: "100%",
    borderRadius: "10px",
    marginTop: "5px",
    "&:hover": {
      border: "2px solid #000",
    },
  },

  input: {
    width: "94%",
    padding: "12.5px",
    border: "2px solid #ABADAD",
    boxSizing: "border-box",
    borderRadius: "10px",
    cursor: "pointer",
    fontFamily: "'Avenir Book', sans-serif",
    lineHeight: "20px",
    fontSize: "15px",
    "&:focus": {
      border: "2px solid #000",
      outline: "none",
    },
  },
  field: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    // marginTop: "26px",
    transition: "all 0.3s ease",
  },
  uploadIcons: {
    display: "flex",
    // paddingTop: 5,
    alignItems: "end",
    // justifyContent: "flex-end",
    width: "94%",
    "& > *": {
      cursor: "pointer",
    },
  },
  error: {
    color: "#f44336",
    fontSize: "15px",
    textAlign: "center",
    width: "75%",
  },
  highlight: {
    border: "none",
    background: "none",
    padding: "0px",
    "&:active, &:focus": {
      border: "none",
      outline: "none",
      padding: "0px",
    },
  },

  uploadImgContainer: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    // marginTop: 12,
    width: "94%",
  },

  icon: {
    color: "#000",
    cursor: "pointer",
    fontSize: "30px",
    fontWeight: "bold",
    transition: "all .3s ease",
  },
  loadingIconContainer: {
    display: "inline-block",
    animation: "$pulse 1.5s infinite alternate", // Reference the pulse animation
  },

  loadingIcon: {
    width: "30px", // Adjust the initial size of the icon
    height: "30px", // Adjust the initial size of the icon
    transition: "all 0.3s ease-in-out", // Smooth transition for size change
  },

  loadingText: {
    marginLeft: "8px", // Add some spacing between the icon and text
  },

  "@keyframes pulse": {
    to: {
      transform: "scale(1.2)", // Adjust the scale factor for the pulse effect
    },
  },
  savebtn: {
    marginTop: "10px",
    width: "150px",
    height: "45px",
    padding: "11.5px 8px 11.5px 8px",
    borderRadius: "15px",
    border: "1px solid",
    backgroundColor: "#000",
    cursor: "pointer",
    position: "relative",
    display: "flex",
    justifyContent: "center",
    color: "white",
    fontSize: "15px",
    fontWeight: 900,
    fontFamily: "Avenir",
    alignItems: "center",
    "&:disabled": {
      backgroundColor: "#888",
      cursor: "not-allowed",
      color: "#666",
    },
  },
  chatgpticonContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%", // Optional: Set height for full-height centering
  },
  "@media (max-width: 600px)": {
    chatgpticonContainer: {
      height: "50vh", // Adjust the height for smaller screens
    },
  },
  label: {
    marginTop: "5px",
  },
  btnstyle: {
    // width: "200px",
    height: "45px",
    padding: "11.5px 50px 11.5px 50px",
    borderRadius: "15px",
    backgroundColor: "#000000",
    color: "white",
    fontFamily: "Avenir",
    fontSize: "13px",
    fontWeight: 900,
    lineHeight: "22px",
    letterSpacing: "0.429px",
    textAlign: "center",
  },
}));

const ViewPost = ({ post }: IProp) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  // const [channel, setChannel] = useState("Facebook");
  // const [selectedDate, setSelectedDate]: any = useState(
  //   DateTime.fromJSDate(new Date()).toFormat("yyyy-MM-dd'T'HH:mm")
  // );

  const handleClose = () => {
    setOpen(false);
  };

  const format = (timeObject: any): string => {
    const { seconds, nanoseconds } = timeObject;

    // Combine seconds and nanoseconds to get a Date object
    const timestamp = new Date(seconds * 1000 + nanoseconds / 1e6);

    // Format the date as per the desired format
    const options: any = {
      weekday: "short",
      day: "2-digit",
      month: "short",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
    };

    const formattedDate = new Intl.DateTimeFormat("en-US", options).format(
      timestamp
    );

    return formattedDate;
  };

  const createHashtags = (stringsArray) => {
    const hashtags = stringsArray.map((str) => `#${str}`);
    return hashtags.join(" "); // Combine hashtags into a single string with spaces
  };

  const truncateBody = (body, maxLength) => {
    return body.length > maxLength ? `${body.slice(0, maxLength)}...` : body;
  };

  return (
    <>
      <IconButton
        aria-label="view"
        color="primary"
        onClick={() => {
          setOpen(true);
        }}
      >
        <EyeIcon />
      </IconButton>
      <Modal
        className={classes.modal}
        open={open}
        onClose={handleClose}
        aria-labelledby="chatgpt-post-generator-title"
        aria-describedby="chatgpt-post-generator-description"
      >
        <div className={classes.formContainer}>
          <form
            className={classes.form}
            // onSubmit={(e: any) => generateChatGptPost(e)}
          >
            <IconButton className={classes.closeIcon} onClick={handleClose}>
              <CloseIcon />
            </IconButton>
            <Typography
              variant="h6"
              gutterBottom
              style={{
                fontFamily: "Avenir",
                fontSize: "30px",
                fontWeight: 900,
                lineHeight: "41px",
                letterSpacing: "0em",
                textAlign: "left",
                padding: "10px",
              }}
            >
              View post
            </Typography>
            <br />
            <Grid container>
              <Grid xs={2} md={2}>
                <img
                  src={Avatar}
                  style={{
                    borderRadius: "50%",
                  }}
                />
              </Grid>
              <Grid xs={9} md={9}>
                <div> {post.clientName}</div>
                <div
                  style={{
                    fontFamily: "Avenir",
                    fontSize: "15px",
                    color: "#0000008A",
                  }}
                >
                  {" "}
                  {format(post.date)}{" "}
                  <span>
                    {" "}
                    <WorldIcon />
                  </span>
                </div>
              </Grid>
              <Grid xs={1} md={1}>
                <img src={facebook} />
              </Grid>
            </Grid>

            <Grid container style={{ height: "300px" }}>
              <Carousel
                images={post?.image_url || []}
                video={post?.video_url || ""}
                videoHeight="300px"
              />
            </Grid>

            <Grid container style={{}}>
              <h1>{post?.title}</h1>
              <p>{post?.body && truncateBody(post.body, 100)}</p>

              <p>{post?.tags && createHashtags(post.tags)}</p>
            </Grid>
          </form>
        </div>
      </Modal>
    </>
  );
};

export default ViewPost;
