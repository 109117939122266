import React, { FunctionComponent, useState, useEffect } from "react";
import { Box, Avatar, CircularProgress } from "@material-ui/core";
import { UserService } from "services/UserService";
import { ReactComponent as AvatarIcon } from "static/media/avatarIcon.svg";
import FileUploader from "react-firebase-file-uploader";
import { useStyles } from "./styles";
import { IUser } from "interfaces/IUser";
import { useUser } from "UserContext";

type FileUploadType = {
  uid: string;
  avatarUrl: string;
  content?: string;
};

const UploadAvatar: FunctionComponent<FileUploadType> = ({
  uid,
  avatarUrl,
  content = "Image",
}) => {
  const classes = useStyles({});
  const [isUploading, setIsUploading] = useState(false);
  const [progress, setProgress] = useState(1);
  const [avatar, setAvatar] = useState(avatarUrl);
  const [storage, setStorage] = useState() as any;
  const [authUser] = useUser() as Array<IUser>;

  useEffect(() => {
    setAvatar(avatarUrl);
  }, [avatarUrl]);

  const handleUploadStart = () => {
    setIsUploading(true);
  };
  const handleProgress = (progress: number) => setProgress(progress);
  const handleUploadError = (error: boolean) => {
    setIsUploading(false);
    // ("error");
  };
  const User = new UserService(uid);

  const handleUploadSuccess = async (filename: string) => {
    await setAvatar(filename);
    const User = new UserService(uid);
    await User.uploadFile(filename, "images")
      .then((url) => {
        User.updateUser({
          avatarUrl: url,
        })
          .then(() => {
            setProgress(100);
            setIsUploading(false);
          })
          .catch((error) => {
            // (error);
          });
        setAvatar(url);
      })
      .catch((error) => {
        // (error);
      });
  };

  useEffect(() => {
    const storage = User.getStorage("images");
    setStorage(storage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box className={classes.box}>
      {isUploading ? (
        <CircularProgress
          size={30}
          color="inherit"
          variant="determinate"
          value={progress}
        />
      ) : !avatar ? (
        <AvatarIcon />
      ) : (
        <Avatar className={classes.avatar} src={avatar} />
      )}
      {authUser.role !== "viewerAdmin" && (
        <label className={classes.button}>
          Upload {content}
          <FileUploader
            disabled={isUploading}
            name="avatarUrl"
            hidden
            accept="image/*"
            storageRef={storage}
            onUploadStart={handleUploadStart}
            onUploadError={handleUploadError}
            onUploadSuccess={handleUploadSuccess}
            onProgress={handleProgress}
          />
        </label>
      )}
    </Box>
  );
};

export default UploadAvatar;
