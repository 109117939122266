import { firestore, storage, firebase } from "./firebase";
// import firebase from "firebase/app";
import moment from "moment";

export class DB {
  protected db: firebase.firestore.Firestore;

  constructor() {
    this.db = firestore;
  }

  // Get all documents from a collection, with optional ordering
  public async get(
    collectionName: string,
    orderField: string = "createdAt",
    orderDirection: firebase.firestore.OrderByDirection = "desc"
  ): Promise<Array<any>> {
    try {
      const querySnapshot = await this.db
        .collection(collectionName)
        .orderBy(orderField, orderDirection)
        .get();

      return querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
    } catch (error) {
      console.error("Error fetching documents:", error);
      throw error;
    }
  }

  // Get documents by an array of values (main method)
  public async getByArray(
    collectionName: string,
    fieldName: string,
    arrayValues: any[],
    operation: firebase.firestore.WhereFilterOp = "in"
  ): Promise<Array<any>> {
    try {
      const querySnapshot = await this.db
        .collection(collectionName)
        .where(fieldName, operation, arrayValues)
        .get();

      return querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
    } catch (error) {
      console.error("Error fetching by array:", error);
      throw error;
    }
  }

  // Alias for backward compatibility with old "getbyArray" naming
  public async getbyArray(
    collectionName: string,
    fieldName: string,
    arrayValues: any[],
    operation: firebase.firestore.WhereFilterOp = "in"
  ): Promise<Array<any>> {
    return this.getByArray(collectionName, fieldName, arrayValues, operation);
  }

  // Get documents by specific field(s)
  public async getByField(
    collectionName: string,
    fieldName: string,
    value: any,
    fieldName2?: string,
    value2?: any,
    operation: firebase.firestore.WhereFilterOp = "==",
    orderField: string = "createdAt",
    orderDirection: firebase.firestore.OrderByDirection = "desc"
  ): Promise<Array<any>> {
    try {
      let query = this.db
        .collection(collectionName)
        .where(fieldName, operation, value);

      if (fieldName2 && value2) {
        query = query.where(fieldName2, operation, value2);
      }

      const querySnapshot = await query
        .orderBy(orderField, orderDirection)
        .get();

      return querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
    } catch (error) {
      console.error("Error fetching by field:", error);
      throw error;
    }
  }

  // Get documents filtered by a date range
  public async metricsByDate(
    collectionName: string,
    fieldValue: string,
    from: Date,
    to: Date,
    fieldName: string = "username",
    dateField: string = "created_at",
    sortType: firebase.firestore.OrderByDirection = "asc",
    operation: firebase.firestore.WhereFilterOp = "=="
  ): Promise<Array<any>> {
    try {
      const querySnapshot = await this.db
        .collection(collectionName)
        .where(fieldName, operation, fieldValue)
        .where(dateField, ">=", this.adjustForTimezone(from))
        .where(dateField, "<=", this.adjustForTimezone(to))
        .orderBy(dateField, sortType)
        .get();

      return querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
    } catch (error) {
      console.error("Error fetching metrics by date:", error);
      throw error;
    }
  }

  // Get the last updated document in a collection
  public async getLastUpdated(
    collectionName: string
  ): Promise<Date | undefined> {
    try {
      const querySnapshot = await this.db
        .collection(collectionName)
        .orderBy("created_at", "desc")
        .limit(1)
        .get();

      if (!querySnapshot.empty) {
        const lastUpdated = new Date(querySnapshot.docs[0].data().created_at);
        return isNaN(lastUpdated.getTime()) ? undefined : lastUpdated;
      }

      return undefined;
    } catch (error) {
      console.error("Error fetching last updated:", error);
      throw error;
    }
  }

  // Get a single document by its ID
  protected async getDoc(collectionName: string, doc: string): Promise<any> {
    try {
      const docSnapshot = await this.db
        .collection(collectionName)
        .doc(doc)
        .get();

      if (!docSnapshot.exists) {
        throw new Error(`Document ${doc} does not exist in ${collectionName}`);
      }

      return { ...docSnapshot.data(), id: docSnapshot.id };
    } catch (error) {
      console.error("Error fetching document:", collectionName, error);
      console.error("Error fetching collectionName:", collectionName);
      console.error("Error fetching doc:", doc);
      throw error;
    }
  }

  // Set or update a document
  protected async set(
    collectionName: string,
    doc: string,
    data: any
  ): Promise<boolean> {
    try {
      await this.db.collection(collectionName).doc(doc).set(data);
      return true;
    } catch (error) {
      console.error("Error setting document:", error);
      throw error;
    }
  }

  // Set or update a document and return the document ID
  protected async set2(
    collectionName: string,
    doc: string,
    data: any
  ): Promise<string | null> {
    try {
      await this.db.collection(collectionName).doc(doc).set(data);
      return doc;
    } catch (error) {
      console.error("Error setting document:", error);
      return null;
    }
  }

  // Update specific fields in a document
  protected async update(
    collectionName: string,
    documentId: string,
    data: object
  ): Promise<boolean> {
    try {
      await this.db.collection(collectionName).doc(documentId).update(data);
      return true;
    } catch (error) {
      console.error("Error updating document:", error);
      throw error;
    }
  }

  // Delete a document
  protected async delete(
    collectionName: string,
    doc: string
  ): Promise<boolean> {
    try {
      await this.db.collection(collectionName).doc(doc).delete();
      return true;
    } catch (error) {
      console.error("Error deleting document:", error);
      throw error;
    }
  }

  protected async getByActiveUser(
    collectionName: string,
    fieldName: string,
    value: any,
    operation: firebase.firestore.WhereFilterOp = "==",
    orderField2?: string,
    orderField: string = "createdAt",
    orderDirection: firebase.firestore.OrderByDirection = "desc"
  ): Promise<Array<any>> {
    try {
      let query = this.db
        .collection(collectionName)
        .where(fieldName, operation, value);

      if (orderField2) {
        query = query.orderBy(orderField2, orderDirection);
      }

      const querySnapshot = await query
        .orderBy(orderField, orderDirection)
        .get();

      return querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
    } catch (error) {
      console.error("Error fetching active users:", error);
      throw error;
    }
  }

  // Get a reference to a storage file
  protected static getStorage(storageName: string) {
    return storage.ref(storageName);
  }

  // Adjust a date for timezone
  private adjustForTimezone(date: Date): Date {
    return new Date(moment(date).format("YYYY-MM-DD"));
  }
}
