import React, { FunctionComponent, useState, useEffect } from "react";
import { Box, CircularProgress } from "@material-ui/core";
import { UserService } from "services/UserService";
import FileUploader from "react-firebase-file-uploader";

import { useStyles } from "./styles";
import { firebase } from "firebase-tools/firebase";

type FileUploadType = {
  isUploading: boolean;
  setIsUploading: (isUploading: boolean) => void;
  user: any;
  title: string;
  setError: (error: string) => void;
  accept: string;
};

const FileUpload: FunctionComponent<FileUploadType> = ({
  isUploading,
  setIsUploading = () => {},
  user,
  title,
  setError,
  accept,
}) => {
  const classes = useStyles({});
  const [storage, setStorage] = useState() as any;
  const [progress, setProgress] = useState(0);

  const User = new UserService(user.id);

  const handleUploadStart = () => {
    setProgress(1);
    setIsUploading(true);
  };
  const handleProgress = (progress: number) => setProgress(progress);
  const handleUploadError = (error: boolean) => {
    setIsUploading(false);
    // ("error", error);
  };
  const handleUploadSuccess = async (filename: string) => {
    const allowedFiles = accept.split(",");
    const fileType: any = filename.split(".").pop();
    if (!allowedFiles.includes("." + fileType)) {
      setIsUploading(false);
      return setError(`Only ${accept} files allowed`);
    }
    const el: any = document.getElementById("uploader");
    let file = el.files[0];
    file = await file.text();
    await User.uploadFile(filename, "events")
      .then(async (url) => {
        const match: any = RegExp("(.*)?alt.*", "g").exec(url);
        if (user.events?.some((res) => res.includes(match[1]))) {
          const eventUrls = user.events;
          eventUrls.forEach((element, i) => {
            if (element.includes(match[1])) {
              eventUrls[i] = url;
            }
          });
          setIsUploading(false);
          User.updateUser({
            events: eventUrls,
          });
          return setError("Duplicate csv file");
        }
        const app = firebase.app();
        const functions = app.functions("europe-west2");
        const migrateCSV = functions.httpsCallable("events-migrate");
        const res: any = await migrateCSV({
          data: file,
          userEmail: user.email,
        });
        if (res.data.error) {
          el.value = "";
          setIsUploading(false);
          return setError(res.data.error);
        }
        User.updateUser({
          events: firebase.firestore.FieldValue.arrayUnion(url),
        })
          .then(() => {
            setProgress(100);
            setIsUploading(false);
            setError("");
          })
          .catch((error) => {
            setIsUploading(false);
            // (error);
          });
      })
      .catch((error) => {
        setIsUploading(false);
        // (error);
      });
  };

  useEffect(() => {
    const storage = User.getStorage("events");
    setStorage(storage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box className={classes.box}>
      <label className={classes.button}>
        {isUploading ? (
          <CircularProgress
            size={30}
            color="inherit"
            variant="determinate"
            value={progress}
          />
        ) : (
          title
        )}
        <FileUploader
          disabled={isUploading}
          name="fileUrl"
          hidden
          accept={accept}
          storageRef={storage}
          onUploadStart={handleUploadStart}
          onUploadError={handleUploadError}
          onUploadSuccess={handleUploadSuccess}
          onProgress={handleProgress}
          id="uploader"
        />
      </label>
    </Box>
  );
};

export default FileUpload;
